import React, { Component } from 'react';
import CircularProgress from '@material-ui/core/CircularProgress';
import { fetch } from '../restClient';
import MUIDataTable from "mui-datatables";
import Modale from './Modale';

class ChangelogFields extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isLoading: false,
            data: [],
        };
    }

    componentDidMount() {
        this.setState({ isLoading: true });
        fetch('/api/admin/' + this.props.resource + '_history/' + this.props.guid)
            .then(response => response.json)
            .then((data) => {
                this.setState({
                    isLoading: false,
                    data: data,
                });
            })
            .catch((error) => {
                this.setState({ isLoading: false });
            });
    }

    render() {
        const { data, isLoading } = this.state;

        if (isLoading) {
            return <CircularProgress size={40} thickness={2} />;
        }

        const columns = [
            {
                label: "Version",
                name: "version"
            },
            {
                label: "Modified by",
                name: "modified_by"
            },
            {
                label: "Modified at",
                name: "modified_at",
                options: {customBodyRender: (value, tableMeta, updateValue)=>{
                                return new Date(value * 1000).toUTCString();
                             }
                         }
            },
            {
                label: "From",
                name: "old",
                options: {customBodyRender: (value, tableMeta, updateValue)=>{
                                return <a target={'_blank'} rel="noopener noreferrer" href={'/api/admin/' + this.props.resource + '_archived/' + value}>{value}</a>;
                             }
                         }
            },
            {
                label: "To",
                name: "new",
                options: {customBodyRender: (value, tableMeta, updateValue)=>{
                                return <a target={'_blank'} rel="noopener noreferrer" href={'/api/admin/' + this.props.resource + '_archived/' + value}>{value}</a>;
                             }
                         }
            },
            {
                label: "Action",
                name: "action",
                options: {customBodyRender: (value, tableMeta, updateValue)=>{
                                return <Modale display_restore_button={tableMeta.rowData[0] !== data.last_version}  item={tableMeta.rowData} record={this.props.record} resource={this.props.resource} ></Modale>;
                             }
                         }
            }
        ];

        const options = {
          selectableRows: 'none',
        };

        return (
            <div>
                {data && data.changes && <MUIDataTable
                                            title={"CHANGELOG"}
                                            data={data.changes}
                                            columns={columns}
                                            options= {options}
                                             />
                }
            </div>
        );
    }
}

export default ChangelogFields;
