import React from "react";
import { Field } from "react-final-form";
import { TextInput, NumberInput, SelectInput } from "react-admin";
import InputFile from "./InputFile";
import LinkListInput from "./LinkListInput";
import TextEditor from "./TextEditorV2";
import { makeStyles } from "@material-ui/core/styles";


const useStyles = makeStyles({
  quarterWidth: { width: "25%" },
  halfWidth: { width: "50%" },
  twoThirdWidth: { width: "66%" },
  fullWidth: { width: "100%" },
  textAreaHeight: { height: "200px" },
  wrapperInputWidthSpan2Link: { gridColumn: "span 2", minWidth: "min-content" },
  wrapperInputWidthSpan3Link: { gridColumn: "span 3" },
  wrapperInputWidthSpan4Link: { gridColumn: "span 4" },
  wrapperInputWidthSpan5Link: { gridColumn: "span 5" },
  wrapperInputWidthSpan6Link: { gridColumn: "span 6" },
});

const OffersInput = ({
  source,
  optionsSearchable,
  formData,
  showNotification,
  ...props
}) => {
  const classes = useStyles();

  const elProps = {
    ...props,
    initiallyExpanded: (index) => index === 0,
    inputFields: [
      <TextInput
        source={`highlight_label`}
        name={`highlight_label`}
        label="Highlight Label"
        fullWidth
        wrapperinputfieldclasses={classes.wrapperInputWidthSpan6Link}
      />,
      <TextInput
        source={`title`}
        name={`title`}
        fullWidth
        label="Title"
        wrapperinputfieldclasses={classes.wrapperInputWidthSpan6Link}
      />,
      <Field
        multiple={false}
        name={`icon`}
        label="Icon"
        component={InputFile}
        source={`icon`}
        wrapperinputfieldclasses={classes.wrapperInputWidthSpan6Link}
      />,
      <Field
        name={`description`}
        wrapperinputfieldclasses={classes.wrapperInputWidthSpan6Link}
        label="Description"
        editorTitle="Description"
        component={TextEditor}
        count={props.fields.length}
      />,
      <NumberInput
        source={`price`}
        name={`price`}
        label="Price"
        fullWidth
        wrapperinputfieldclasses={classes.wrapperInputWidthSpan2Link}
      />,
      <TextInput
        source={`currency`}
        name={`currency`}
        label="Currency"
        fullWidth
        wrapperinputfieldclasses={classes.wrapperInputWidthSpan2Link}
      />,
      <SelectInput
        source={`recurrence`}
        name={`recurrence`}
        choices={[
          { id: "per day", name: "per day" },
          { id: "per week", name: "per week" },
          { id: "per month", name: "per month" },
          { id: "per year", name: "per year" },
          { id: "Every 2 years", name: "Every 2 years" },
        ]}
        label="Recurrence"
        resettable
        wrapperinputfieldclasses={classes.wrapperInputWidthSpan2Link}
      />,
      <TextInput
        source={`url`}
        name={`url`}
        label="URL"
        fullWidth
        wrapperinputfieldclasses={classes.wrapperInputWidthSpan6Link}
      />,
      <TextInput
        source={`cta_label`}
        name={`cta_label`}
        label=" CTA Label"
        fullWidth
        wrapperinputfieldclasses={classes.wrapperInputWidthSpan6Link}
      />,
    ],
  };

  return (
      
        <LinkListInput
          initiallyExpanded={true}
          expanded={true}
          gridColumnDetails={6}
          showEmptyKeys={[]}
          addLabel={"add offer"}
          removeLabel={"remove offer"}
          label="Offer"
          {...elProps}
        />
      
  
    
  );
};

export default OffersInput;
