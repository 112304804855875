import React from 'react';
import ReactDOM from 'react-dom';
import CssBaseline from '@material-ui/core/CssBaseline';
import AuthConst from './AuthConst';
import { Auth0Provider } from "@auth0/auth0-react";

ReactDOM.render(
    <React.Fragment>
        <CssBaseline />
            <Auth0Provider
            domain= {window.auth_config.domain}
            clientId={window.auth_config.clientID}
            redirectUri={window.location.origin}
            scope= 'openid profile email groups roles read:roles read:users read:role_members offline_access'
            >
                <AuthConst />
            </Auth0Provider>
    </React.Fragment>,
    document.getElementById('root')
);
