import React from "react";
import {
  CreateButton,
  // Datagrid,
  ExportButton,
  SortButton,
  TopToolbar,
} from "react-admin";
import { Box } from "@material-ui/core";

const ListActions = (props) => {
  return (
      <TopToolbar>
        <SortButton fields={["priority"]} />
        <CreateButton />
        <ExportButton />
      </TopToolbar>
  );
};
export default ListActions