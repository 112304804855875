import React from "react";
import {
  SaveButton,
  DeleteButton,
  Toolbar,
  TabbedForm,
  FormTab,
  useRecordContext,
  useResourceContext,
} from "react-admin";
import { makeStyles } from "@material-ui/core/styles";
import FormDataContent from "../../components/FormDataContent";
import GeneralInfosForm from "./GeneralInfosForm";
import LayersManager from "../../components/LayersManager";
import { Field } from "react-final-form";
import OffersForm from "../../components/OffersForm";
import clsx from "clsx";
import CampaignConfigs from "./CampaignConfigs";
import ProvidersLinks from "./ProvidersLinks";
import Tracking from "./Tracking";
import ChangelogFields from "../../components/ChangeLogFields";

export const useStyles = makeStyles({
  long_input: {
    width: "90%",
    marginRight: "10%",
  },
  flexDirectionColumn: {
    display: "flex",
    justifyContent: "flex-start",
    flexDirection:"column"
  },
  flexDirectionRow: {
    display: "flex",
    justifyContent: "flex-start",
    flexDirection:"row"
  },
  group_input: {
    display: "flex",
    justifyContent: "flex-start",
  },
  flexInput:{
    // width:"50%",
    marginRight:"1%"
  },
  text_editor_content: {
    width: "65%",
  },
  input_marge: {
    marginRight: "1%",
  },
  regwall_input: {
    marginLeft: "5%",
  },
});

const ChangeLogTabContent = (props) => {
  const record = useRecordContext(props);
  const resource = useResourceContext(props);
  if (record) {
    return (
      <ChangelogFields guid={record.guid} record={record} resource={resource} />
    );
  } else {
    return null;
  }
};

const CustomToolbar = (props) => {
    const toolbarStyles = makeStyles({
        toolbar: {
          display: "flex",
          justifyContent: "space-between",
        },
      });

    return (<Toolbar {...props} classes={toolbarStyles()}>
      <SaveButton />
      <DeleteButton />
    </Toolbar>)
  }

const EditForm = ({ validateSiteConfig, redirect = "edit", children, origin = "create",...rest }) => {
  const classes = useStyles();

  let wrapperOffersClass = clsx({
    [classes.flexDirectionColumn]: true,
    [classes.group_input]: true,
  });

  return (
    <TabbedForm
      submitOnEnter={false}
      redirect={redirect}
      toolbar={<CustomToolbar />}
      validate={validateSiteConfig}
      {...rest}
    >
      <FormTab label="site configs">
        <GeneralInfosForm />
      </FormTab>
      <FormTab label="Providers and Links">
        <ProvidersLinks />
      </FormTab>
      <FormTab label="Layers manager">
        <LayersManager />
      </FormTab>
      <FormTab label="Tracking">
        <Tracking />
      </FormTab>
      <FormTab label="Campaign configs">
        <CampaignConfigs />
      </FormTab>
      <FormTab label="Offers">
        <FormDataContent dataContentTitle="Offers curated">
          <div className={wrapperOffersClass}>
            <Field name={"offers"} classes={classes} component={OffersForm} />
          </div>
        </FormDataContent>
      </FormTab>
      <FormTab label="Changelogs">
          <ChangeLogTabContent />
        </FormTab>
    </TabbedForm>
  );
};

export default EditForm;
