import React from "react";
import {
  Edit,
  useNotify,
  useRefresh,
  useRedirect,
} from "react-admin";

import EditForm from "./EditForm";

const SiteConfigsTitle = ({ record }) => {
  return <span>Edit {record ? `"${record.name}"` : ""}</span>;
};

const validateSiteConfigEdition = (values) => {
  const errors = {};
    const requiredFields = [/* 'logo', 'logo_small', */'domain'];

    requiredFields.forEach(field => {
        if (!values[field]) {
            errors[field] = `The ${field.replace('_', ' ')} is required`;
        }
    });

    if (values.landing_products) {
    try {
      const json = JSON.parse(values.landing_products);
    } catch (e) {
      errors.landing_products =
        "The format is not valid, please check the example provided";
    }
  }
  if (!values.domain) {
      errors.domain = 'The domain is required';
  }
  if (values.enableHandshakeRedirect === true) {
      if (!values.estore_handshake_endpoint) {
          errors.estore_handshake_endpoint = "The Estore handshake endpoint is required as  the 'enable hanshake redirect' button is checked"
      }
  }
  return errors;
};

const SiteConfigsEdit = (props) => {
  const notify = useNotify();
  const refresh = useRefresh();
  const redirect = useRedirect();

  const onSuccess = ({ data }) => {
    notify(`Changes to Site configs "${data.name}" saved`);
    redirect("/sites-configs");
    refresh();
  };
  const onFailure = (error) => {
    notify(`Could not edit Site configs: ${error.message}`);
    redirect("/sites-configs");
    refresh();
  };

  return (
    <Edit
      title={<SiteConfigsTitle />}
      onSuccess={onSuccess}
      onFailure={onFailure}
      mutationMode="pessimistic"
      {...props}
    >
      <EditForm
        redirect="edit"
        origin={"edit"}
        validateSiteConfig={validateSiteConfigEdition}
      >
      </EditForm>
    </Edit>
  );
};

export default SiteConfigsEdit;
