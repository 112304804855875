import React from 'react';
import {useAuth0} from "@auth0/auth0-react";
import Auth from './Auth';

const AuthConst = () => {
    const {
        isLoading,
        isAuthenticated,
        error,
        user,
        getAccessTokenSilently
    } = useAuth0();
    return <Auth
                auth0Payload={{
                    isLoading,
                    isAuthenticated,
                    error,
                    user,
                    getAccessTokenSilently
                }}
           />;
};

export default AuthConst;
