import React from "react";
import PropTypes from "prop-types";
import FormDataContent from "../../components/FormDataContent";
import { TextInput, BooleanInput, SelectInput, FormDataConsumer } from "react-admin";
import { useStyles as useStylesEditForm } from "./EditForm";
import { useFormState } from "react-final-form";

const ProvidersLinks = ({ field = "" }) => {
  const classes = useStylesEditForm();
  const source = field ? `${field}.` : "";
  const { values: formdata } = useFormState();
  const formdataSource = field ? formdata[field] : formdata;

  return (
    <>
      <FormDataConsumer>
        {({ formData, ...rest }) => {
          if (typeof formData.main_provider === 'undefined' ) {
            formData.main_provider = 'bluebird'
          }
          return (
              <SelectInput source="main_provider"
                           choices={[
                             { id: 'bluebird', name: 'Bluebird' },
                             { id: 'cds', name: 'CDS' },
                             { id: 'chargebee', name: 'ChargeBee' },
                           ]}
                           helperText="Prefered provider in My Account page (Default: Bluebird)"
                           className={classes.input_marge}
              />
          )
        }
        }
      </FormDataConsumer>
      <FormDataContent dataContentTitle="Bluebird options">
        <BooleanInput helperText="Integrate Bluebird provider" className={classes.input_marge} label="Bluebird Provider" source="bluebird_provider" defaultValue={true} />
        <FormDataConsumer>
          {({ formData, ...rest }) => {
            if (formData.bluebird_provider === true) {
              return (
                  <>
                    <TextInput helperText="ex: ELN" className={classes.input_marge} source="journal" label="Journal code" />
                    <TextInput helperText="ex: 92AB" className={classes.input_marge} source="offercode" label="Offer code" />
                    <TextInput helperText="Link for the CTA button" className={classes.long_input} source="bluebird_buy_sub" label="Buy subscription link" />
                    <TextInput helperText="Link 'at' under the CTA button" className={classes.long_input} source="bluebird_buy_sub_at" label="Link under CTA button" />
                    <TextInput helperText="Link for Manage subscription button" className={classes.long_input} source="bluebird_manage_sub" label="Manage subscription link" />
                  </>
              )
            }
          }}
        </FormDataConsumer>
      </FormDataContent>
      <FormDataContent dataContentTitle="CDS options">
        <BooleanInput
          className={classes.flexInput}
          source={`${source}cds_provider`}
          label="CDS provider"
        />
        {formdataSource?.cds_provider && (
          <>
            <TextInput
              helperText="ex: TWE"
              className={classes.input_marge}
              source={`${source}cds_prod_id`}
              label="CDS Prod ID"
            />
            <TextInput
              helperText="Link for the CTA button"
              className={classes.long_input}
              source={`${source}cds_buy_sub`}
              label="Buy subscription link"
            />
            <TextInput
              helperText="Link 'at' under the CTA button"
              className={classes.long_input}
              source={`${source}cds_buy_sub_at`}
              label="Link under CTA button"
            />
            <TextInput
              helperText="Link for Manage subscription button"
              className={classes.long_input}
              source={`${source}cds_manage_sub`}
              label="Manage subscription link"
            />
          </>
        )}
      </FormDataContent>
    <FormDataContent dataContentTitle="ChargeBee">
        <div className={classes.group_input}>
            <BooleanInput helperText="Integrate ChargeBee provider" className={classes.input_marge} label="ChargeBee Provider" source="chargebee_provider" />
            <FormDataConsumer>
                {({ formData, ...rest }) => {
                    if (formData.chargebee_provider === true) {
                        return (
                            <div>
                                <TextInput helperText="" className={classes.long_input} source="cb_plan" label="Default plan" />
                                <TextInput helperText="" className={classes.long_input} source="cb_business_entity" label="Business Entity" />
                            </div>
                        )
                    }
                }}
            </FormDataConsumer>
        </div>
    </FormDataContent>
    </>
  );
};

ProvidersLinks.propTypes = {};

export default ProvidersLinks;
