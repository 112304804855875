import React from 'react';
import { DateInput, AutocompleteInput } from 'react-admin';

const PaywallGrantedInputs = props => {
    const paywallGrantedContent = {
        display: 'flex',
        alignItems: 'center'
    };
    const paywallGrantedInputContent = {
        marginRight: '30px',
    };

    function createChoices () {
        let tab = [];
        for (const [key, value] of Object.entries(window.sites_config)) {
            tab.push({id: window.sites_config[key].site, name: window.sites_config[key].name + ' ' + window.sites_config[key].local  })
        }
        return tab;
    }

    const choicesTab = createChoices();

    return (
        <div style={paywallGrantedContent}>
            <div style={paywallGrantedInputContent} >
                <AutocompleteInput label ="Site" source={props.userMetadataTarget + "-site-to-add"+ props.number} choices={choicesTab} translateChoice={false} />
            </div>
            <div style={paywallGrantedInputContent} >
                <DateInput  label="Grace period"
                            source={"user_metadata." + props.userMetadataTarget + "." + props.userMetadataTarget + "-site-to-add" + props.number}
                />
            </div>

        </div>
    )
}

export default PaywallGrantedInputs;
