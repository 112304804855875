import AceEditor from "react-ace";
import "ace-builds/src-noconflict/ext-language_tools";
import "ace-builds/src-noconflict/mode-css";
import "ace-builds/src-noconflict/mode-javascript";
import "ace-builds/src-noconflict/theme-github";
import "ace-builds/src-noconflict/theme-monokai";
import React, { useCallback, useEffect, useState } from "react";
import { useField } from "react-final-form";

const AceInput = ({ mode = "javascript", source = "", placeholder = "", defaultValue = "" }) => {

  const {
    input: { value:inputValue, onChange:onChangeField },
  } = useField(source,{subscription:{value:true}});
  const [value, setValue] = useState(inputValue || defaultValue)

  const onChange = useCallback(
    (val) => {
      setValue(val);
    })

    useEffect(() => {
    onChangeField(value)
  }, [value])
  

  return (
    <AceEditor
      mode={mode}
      theme="monokai"
      placeholder={placeholder}
      defaultValue={defaultValue}
      onChange={onChange}
      editorProps={{ $blockScrolling: true }}
      name={source}
      showPrintMargin={true}
      showGutter={true}
      highlightActiveLine={true}
      value={value}
      minLines={10}
      maxLines={50}
      height={"500px"}
      width={"100%"}
      setOptions={{
        enableBasicAutocompletion: true,
        enableLiveAutocompletion: true,
        enableSnippets: true,
        showLineNumbers: true,
        useWorker: false,
        tabSize: 2,
      }}
    />
  );
};

export default AceInput;
