import React, { useState } from "react";
import { TextInput } from "react-admin";
import { makeStyles } from "@material-ui/core/styles";
import { blue } from "@material-ui/core/colors";
import LayerManager from "./LayerManager";
import FormDataContent from "./FormDataContent";
import RegwallAuthLayerPreview from "./RegwallAuthLayerPreview";
import { CompactPicker } from "react-color";
import TextEditor from "./TextEditor";
import { useField } from "react-final-form";

const useStyles = makeStyles({
  section_title: {
    fontSize: "1.2rem",
    marginBottom: "0px",
  },
  section_title_underline: {
    width: "40px",
    height: "6px",
    backgroundColor: blue[400],
    marginBottom: "20px",
  },
  layers_manager_content: {
    display: "flex",
    marginBottom: "40px",
  },
  layers_manager_zone: {
    width: "33.3%",
    padding: "0px 20px 20px 20px",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
  },
  color_picker_zone: {
    padding: "0px 20px 20px 20px",
  },
  long_input: {
    width: "65%",
    marginRight: "10%",
  },
  hidden_input: {
    display: "none",
  },
  text_editor_content: {
    width: "65%",
  },
  color_picker_content: {
    paddingTop: "20px",
    display: "flex",
    justifyContent: "center",
  },
  title: {
    textAlign: "center",
    marginBottom: "20px",
    fontSize: "1em",
  },
});

const regwallLogoPositionDefault = {
  verticalUp: {
    rowLeft: true,
    rowCenter: false,
    rowRight: false,
  },
  verticalDown: {
    rowLeft: false,
    rowCenter: false,
    rowRight: false,
  },
};

const regwallButtonPositionDefault = {
  verticalUp: {
    rowLeft: null,
    rowCenter: false,
    rowRight: false,
  },
  verticalCenter: {
    rowLeft: true,
    rowCenter: false,
    rowRight: false,
  },
  verticalDown: {
    rowLeft: false,
    rowCenter: false,
    rowRight: false,
  },
};

const regwallBackgroundColorDefault = "#FFFFFF";
const regwallButtonBackgroundColorDefault = "#808080";

const RegwallAuthLayerManager = (props) => {
  const { source = "" } = props;
  const classes = useStyles();

  const {
    input: {
      value: regwallLogoPosition,
      onChange: onChangeRegwallLogoPosition,
    },
  } = useField(`${source}regwall_logo_position`, {
    defaultValue: regwallLogoPositionDefault,
  });
  const {
    input: {
      value: regwallButtonPosition,
      onChange: onChangeRegwallButtonPosition,
    },
  } = useField(`${source}regwall_button_position`, {
    defaultValue: regwallButtonPositionDefault,
  });
  const {
    input: {
      value: regwallBackgroundColor,
      onChange: onChangeRegwallBackgroundColor,
    },
  } = useField(`${source}regwall_background_color`, {
    defaultValue: regwallBackgroundColorDefault,
  });
  const {
    input: {
      value: regwallButtonBackgroundColor,
      onChange: onChangeRegwallButtonBackgroundColor,
    },
  } = useField(`${source}regwall_button_background_color`, {
    defaultValue: regwallButtonBackgroundColorDefault,
  });
  const {
    input: {
      value: textBeforeButtonRegwall,
      onChange: onChangeTextBeforeButtonRegwall,
    },
  } = useField(`${source}textBeforeButtonRegwall`, { defaultValue: "" });
  const {
    input: {
      value: textAfterButtonRegwall,
      onChange: onChangeTextAfterButtonRegwall,
    },
  } = useField(`${source}textAfterButtonRegwall`, { defaultValue: "" });

  const [regwallLogoPositionPreview, setRegwallLogoPositionPreview] = useState(
    positionForPreview(regwallLogoPosition)
  );
  const [regwallButtonPositionPreview, setRegwallButtonPositionPreview] =
    useState(positionForPreview(regwallButtonPosition));

  function positionForPreview(elementPosition) {
    for (const [vKey, vValue] of Object.entries(elementPosition)) {
      for (const [rKey, rValue] of Object.entries(vValue)) {
        if (rValue) {
          return {
            vertical: vKey,
            row: rKey,
          };
        }
      }
    }
  }

  function handleChangeRegwallBackgroundColor(color) {
    onChangeRegwallBackgroundColor(color.hex);
  }

  function handleChangeRegwallButtonBackgroundColor(color) {
    onChangeRegwallButtonBackgroundColor(color.hex);
  }

  function handleRegwallPosition(element, verticalPosition, rowPosition) {
    let newLogoPosition = { ...regwallLogoPosition };
    let newButtonPosition = { ...regwallButtonPosition };

    if (element === "logo") {
      for (const [vKey, vValue] of Object.entries(newLogoPosition)) {
        for (const [rKey, rValue] of Object.entries(vValue)) {
          if (vKey === verticalPosition) {
            if (rKey === rowPosition) {
              newLogoPosition[vKey][rowPosition] = true;
              newButtonPosition[vKey][rowPosition] = null;
            } else if (newLogoPosition[vKey][rKey] && rKey !== rowPosition) {
              newLogoPosition[vKey][rKey] = false;
              if (newButtonPosition[vKey][rKey] === null) {
                newButtonPosition[vKey][rKey] = false;
              }
            }
          } else {
            if (newLogoPosition[vKey][rKey]) {
              newLogoPosition[vKey][rKey] = false;
            }
            if (newButtonPosition[vKey][rKey] === null) {
              newButtonPosition[vKey][rKey] = false;
            }
          }
        }
      }
    }
    if (element === "button") {
      for (const [vKey, vValue] of Object.entries(newButtonPosition)) {
        for (const [rKey, rValue] of Object.entries(vValue)) {
          if (vKey === verticalPosition) {
            if (rKey === rowPosition) {
              newButtonPosition[vKey][rowPosition] = true;
              if (vKey in newLogoPosition) {
                newLogoPosition[vKey][rowPosition] = null;
              }
            } else if (newButtonPosition[vKey][rKey] && rKey !== rowPosition) {
              newButtonPosition[vKey][rKey] = false;
              if (vKey in newLogoPosition) {
                if (newLogoPosition[vKey][rKey] === null) {
                  newLogoPosition[vKey][rKey] = false;
                }
              }
            }
          } else {
            if (newButtonPosition[vKey][rKey]) {
              newButtonPosition[vKey][rKey] = false;
            }
            if (vKey in newLogoPosition) {
              if (newLogoPosition[vKey][rKey] === null) {
                newLogoPosition[vKey][rKey] = false;
              }
            }
          }
        }
      }
    }

    onChangeRegwallLogoPosition(newLogoPosition);
    setRegwallLogoPositionPreview(positionForPreview(newLogoPosition));

    onChangeRegwallButtonPosition(newButtonPosition);
    setRegwallButtonPositionPreview(positionForPreview(newButtonPosition));
  }

  return (
    <div id="layer_regwall_management">
      <FormDataContent dataContentTitle=" Regwall Layer content">
        <TextInput
          helperText="ex: https://cdn.mos.cms.futurecdn.net/flexiimages/jtld3qrazv1613402249.svg"
          className={classes.long_input}
          label={`Logo Regwall`}
          source={`${source}logo_regwall`}
        />
        <div className={classes.text_editor_content}>
          <TextEditor
            editorTitle="Text before button regwall"
            textContent={textBeforeButtonRegwall}
            handleText={onChangeTextBeforeButtonRegwall}
          />
        </div>
        <TextInput
          helperText="ex: Access now"
          className={classes.long_input}
          label={`Text Button Regwall`}
          source={`${source}button_text_regwall`}
        />
        <div className={classes.text_editor_content}>
          <TextEditor
            editorTitle="Text after button regwall"
            textContent={textAfterButtonRegwall}
            handleText={onChangeTextAfterButtonRegwall}
          />
        </div>
      </FormDataContent>

      <p className={classes.section_title}> Regwall Layer customization</p>
      <div className={classes.section_title_underline}></div>
      <div className={classes.layers_manager_content}>
        <div className={classes.layers_manager_zone}>
          <LayerManager
            layer="main"
            element="logo"
            elementPosition={regwallLogoPosition}
            handlePosition={handleRegwallPosition}
          />
        </div>
        <div className={classes.layers_manager_zone}>
          <LayerManager
            layer="main"
            element="button"
            elementPosition={regwallButtonPosition}
            handlePosition={handleRegwallPosition}
          />
        </div>
        <div className={classes.layers_manager_zone}>
          <div className={classes.color_picker_zone}>
            <p className={classes.title}>Choose background color</p>
            <div className={classes.color_picker_content}>
              <CompactPicker
                color={regwallBackgroundColor}
                onChangeComplete={handleChangeRegwallBackgroundColor}
              />
            </div>
          </div>
          <div className={classes.color_picker_zone}>
            <p className={classes.title}>Choose button background color</p>
            <div className={classes.color_picker_content}>
              <CompactPicker
                color={regwallButtonBackgroundColor}
                onChangeComplete={handleChangeRegwallButtonBackgroundColor}
              />
            </div>
          </div>
        </div>
      </div>
      <TextInput
        className={classes.hidden_input}
        source={`${source}regwall_logo_position`}
        label={`Regwall Logo Position`}
      />
      <TextInput
        className={classes.hidden_input}
        source={`${source}regwall_button_position`}
        label={`Regwall Button Position`}
      />
      <TextInput
        className={classes.hidden_input}
        source={`${source}regwall_background_color`}
        label={`Regwall Background Color`}
      />
      <TextInput
        className={classes.hidden_input}
        source={`${source}regwall_button_background_color`}
        label={`Regwall Button Background Color`}
      />
      <TextInput
        className={classes.hidden_input}
        source={`${source}textBeforeButtonRegwall`}
        label={`Regwall Text Before Button`}
      />
      <TextInput
        className={classes.hidden_input}
        source={`${source}textAfterButtonRegwall`}
        label={`Regwall Text After Button`}
      />
      <p className={classes.section_title}> Regwall Layer preview </p>
      <div className={classes.section_title_underline}></div>
      <RegwallAuthLayerPreview
        logoPositionPreview={regwallLogoPositionPreview}
        buttonPositionPreview={regwallButtonPositionPreview}
        backgroundColor={regwallBackgroundColor}
        buttonBackgroundColor={regwallButtonBackgroundColor}
      />
    </div>
  );
};

export default RegwallAuthLayerManager;
