import React, { useState } from "react";
import PropTypes from "prop-types";
import { Tooltip } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import Typography from "@material-ui/core/Typography";
import HelpIcon from '@mui/icons-material/Help';

const useStyles = makeStyles({
customWidth: {width: '800px'}
});

const RenderTitle = (props) => {
  const { values } = props;
  return (
    <>
      {values.length > 0 &&
        values.map((value, k) => {
          return (
            <React.Fragment key={`tooltip-${k}`}>
              {<Typography>{value}</Typography>}
              <br />
            </React.Fragment>
          );
        })}
    </>
  );
};

const TooltipControlled = ({
  record,
  fieldsToRender = [],
  icon = <HelpIcon/>,
}) => {
  const [values, setValue] = useState(fieldsToRender.reduce((acc,field) => {if (record[field]){ acc.push(record[field])}; return acc},[]));
const classes = useStyles();
  return (
    <>
        {values?.length > 0 ?
        <Tooltip
            leaveDelay={4000}
            interactive
            classes={{tooltip:classes.customWidth}}
            placement="top"
            title={<RenderTitle values={values} />}
        >
            { React.cloneElement(icon)}
        </Tooltip>
        : null
        }
    </>
  );
};

TooltipControlled.propTypes = {
  record: PropTypes.object,
  fieldsToRender: PropTypes.array.isRequired,
  icon: PropTypes.node
};

export default TooltipControlled;
