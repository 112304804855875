import React from 'react';
import AddIcon from '@material-ui/icons/Add';
import RemoveIcon from '@material-ui/icons/Remove';
import { blue, red } from '@material-ui/core/colors';
import PaywallGrantedInputs from './PaywallGrantedInputs';
import ProductGrantedInputs from './ProductGrantedInputs';

class GrantedInputsContent extends React.Component{
    constructor(props){
        super(props);
        this.state = {
          siteNumber: 0,
          children: []
      }
      this.addInputs = this.addInputs.bind(this);
      this.deleteInputs = this.deleteInputs.bind(this);
  }

  addInputs(){
      const number = this.state.siteNumber + 1;
      const children = this.state.children;
      if (this.props.paywallType === 'classic') {
          children.push(<PaywallGrantedInputs  key={number} number={number} userMetadataTarget={this.props.userMetadataTarget} />);
      }else if (this.props.paywallType === 'products') {
          children.push(<ProductGrantedInputs  key={number} number={number} />);
      }


      this.setState({
      siteNumber: number,
      children: children
    });
  }
  deleteInputs(){
      const number = this.state.siteNumber - 1;
      const children = this.state.children;
      const filtered = children.filter(function(value, index, arr){
        return index !== number ? value : null;
      });

      this.setState({
      siteNumber: number,
      children: filtered
    });
  }

  componentDidMount() {
      this.addInputs();
  }

  render() {
      const editZoneTitle = {
              fontSize: '16px',
      };
      const paywallGrantedButtonContent = {
          display: 'flex',
          alignItems: 'center'

      }
      const paywallGrantedButton = {
          borderRadius: '50%',
          backgroundColor: blue[300],
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          width: '30px',
          height: '30px',
          marginBottom: '20px',
          marginLeft: '20px',
          cursor: 'pointer'

      }
      const deletePaywallGrantedButton = {
          borderRadius: '50%',
          backgroundColor: red[300],
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          width: '30px',
          height: '30px',
          marginBottom: '20px',
          marginLeft: '20px',
          cursor: 'pointer'

      }
      const paywallGrantedContentWithButton = {
          display: 'flex',
          alignItems: 'center'
      };

      return (
          <div>
              <p style={editZoneTitle}>Add {this.props.paywallType === 'products'? "Products" : "Sites"}  {this.props.userMetadataTarget === 'paywall_trial'? "trial" : "grace"} period</p>
              {
                  this.state.children.map((input,index)=>{
                      return (
                          <div key={index} style={paywallGrantedContentWithButton}>
                                {input}
                                {
                                    this.state.siteNumber === index + 1 &&
                                    <div style={paywallGrantedButtonContent}>
                                        <div style={paywallGrantedButton} onClick={this.addInputs}>
                                            <AddIcon style={{color: 'white', fontSize: '20px'}}/>
                                        </div>
                                        {index !== 0  &&
                                        <div style={deletePaywallGrantedButton} onClick={this.deleteInputs}>
                                            <RemoveIcon style={{color: 'white', fontSize: '20px'}}/>
                                        </div>}
                                    </div>

                                }
                          </div>
                      )
                  })
              }
          </div>
      )
  }

}

export default GrantedInputsContent;
