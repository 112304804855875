import React from 'react';
import LoadingPage from './LoadingPage';
import ButtonToGoToLogin from './components/ButtonToGoToLogin';
import AuthError from './components/AuthError';
import AuthWithWrongAccessRights from './components/AuthWithWrongAccessRights';
import AdminApp from './components/AdminApp';

const Auth = (props) => {
    const styles = {
        pendingContent: {
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            width: '100vw',
            height: '100vh'
        }
    }
    const {
        isLoading,
        isAuthenticated,
        error,
        user,
        getAccessTokenSilently,
    } = props.auth0Payload;


    if (isLoading) {
        return <LoadingPage/>
    }

    if (error) {
        return <AuthError error={error}/>;
    };

    if (isAuthenticated && (!user.roles.includes("Admin") && !user.roles.includes("SuperAdmin"))) {
        return <AuthWithWrongAccessRights/>
    }
    if (isAuthenticated && (user.roles.includes("Admin") || user.roles.includes("SuperAdmin"))) {
        localStorage.setItem('user', user.email);
        getAccessTokenSilently().then(token => {
            localStorage.setItem('token', token);
        });

        return <AdminApp isAuthenticated= {isAuthenticated} user={user}/>;
    }else {
        return <ButtonToGoToLogin/>
    }
};

export default Auth;
