import * as React from "react";
import {
    Show,
    SimpleShowLayout,
    ImageField,
    ArrayField,
    Datagrid,
    UrlField,
    SingleFieldList,
    ChipField,
    useRecordContext,
    RichTextField, TextInput
} from 'react-admin';
import { makeStyles } from '@material-ui/core/styles';
import { green, red } from '@material-ui/core/colors';
import PropTypes from 'prop-types';
import FormDataContent from '../../components/FormDataContent'

const useStyles = makeStyles({
    data_title: {
        fontSize: '0.8rem',
        color: 'rgba(0, 0, 0, 0.2)',
        marginBottom:'0px'
    },
    datas: {
        display: 'flex',
        justifyContent: 'space-around'
    },
    info_datas: {
        display: 'flex',
        justifyContent: 'flex-start'
    },
    info_content_datas: {
        display: 'flex',
        justifyContent: 'space-around',
        flexDirection: 'column'
    },
    activate: {
        color: green[400]
    },
    deactivated: {
        color: red[400]
    },
    data_img_content: {
        display: 'flex',
        justifyContent: 'flex-start'
    },
    data_logo: {
        marginRight: '50px'
    },
    text_field_datas: {
        marginRight: '100px'
    },
    title_field: {
        color: 'rgba(0, 0, 0, 0.2);',
        fontSize: '0.8rem',
        marginBottom: '0px'
    }
});

const TrackingField = (props) => {
    const classes = useStyles();
    const record = useRecordContext(props);
    if (record && record.tracking) {
        return (
            <div>
                <p className={classes.data_title}>Tracking</p>
                {record.tracking.map((item, index) => (
                    <div key={index}>
                        <p>utm_medium: {item.utm_medium}</p>
                        <p>utm_campaign: {item.utm_campaign}</p>
                        <p>utm_source: {item.utm_source}</p>
                    </div>
                ))}
            </div>
        )
    }else {
        return null
    }
};
const JsConfigField = (props) => {
    const classes = useStyles();
    const record = useRecordContext(props);
    if (record && record.jsConfig) {
        return (
            <div>
                <p className={classes.data_title}>JsConfig</p>
                {record.jsConfig.map((item, index) => (
                    <div key={index}>
                        <p>hubPage: {item.hubPage}</p>
                    </div>
                ))}
            </div>
        )
    }else {
        return null
    }
};
const TextField = (props) => {
    const classes = useStyles();
    const { source, label, justifyContent } = props;
    const record = useRecordContext(props);
    if (record) {
        return (
            <div className={classes.text_field_datas}>
                <p className={classes.data_title}>{label}</p>
                {
                    record.template_menu_header  && source === 'template_menu_header' ||
                    record.template_button  && source === 'template_button' ||
                    record.template_sticky  && source === 'template_sticky' ||
                    record.trailingSlash && source === 'trailingSlash' ?
                    <p className={classes.activate}>Activate</p> :
                    !record.template_menu_header  && source === 'template_menu_header' ||
                    !record.template_button  && source === 'template_button' ||
                    !record.template_sticky  && source === 'template_sticky' ||
                    !record.trailingSlash  && source === 'trailingSlash' ?
                    <p className={classes.deactivated}>Deactivated</p> :
                    source === 'logo' || source === 'logo_small' ?
                    <div className={classes.data_img_content}>
                        <p className={classes.data_logo}>{record[source]}</p>
                        {record[source] !== null ? <img style={{maxWidth: '150px'}}src={record[source]} alt="Logo" /> : null}
                    </div> :
                    record.traffic_exposure  && source === 'traffic_exposure' ?
                    <p className={classes.activate}>Activate</p> :
                    !record.traffic_exposure  && source === 'traffic_exposure' ?
                    <p className={classes.deactivated}>Deactivated</p> :
                    record.traffic_exposure_rate !== undefined && record.traffic_exposure_rate !== null  && source === 'traffic_exposure_rate' ?
                    <p>{record[source]}%</p> :
                    <p>{record[source]}</p>
                }

            </div>

        );
    }else {
        return null
    }

}

TextField.propTypes = {
    label: PropTypes.string,
    record: PropTypes.object,
    source: PropTypes.string.isRequired,
};

const CampaignsShow = (props) => {
    const classes = useStyles();
    return (
        <Show title= "Site configs details" {...props}>
            <SimpleShowLayout>
                <FormDataContent justifyContent="flex-start" dataContentTitle="General info">
                    <div className={classes.info_datas}>
                        <TextField  label="Site" source="site" />
                        <TextField label="Name"source="name" />
                        <TextField label="Local" source="local" />
                        <TextField label="Journal code" source="journal" />
                        <TextField label="Offer code" source="offercode"  />
                        <TextField label="Product ID" source="productID"  />
                    </div>
                    <div className={classes.info_datas}>
                        <TextField label="Hub page with associated product" source="landing_products" />
                    </div>
                    <div className={classes.info_datas}>
                        <TextField label="Magazines Direct Prod Endpoint" source="magazinesDirectProdEndpoint" />
                        <TextField label="magazines Direct Qa Endpoint" source="magazinesDirectQaEndpoint" />
                        <TextField label="Site with trailing slash urls" source="trailingSlash" />
                    </div>
                    <div className={classes.info_datas}>
                        <TrackingField />
                        <JsConfigField />
                    </div>
                </FormDataContent>
                <FormDataContent dataContentTitle="Paywall config">
                    <div className={classes.datas}>
                        <TextField label="Limit of content" source="limitOfContent" />
                        <TextField label="Limit of Free article" source="limitOfFreeArticle" />
                        <TextField label="Wall system"source="wall_system" />
                        <TextField label="Wall system"source="wall_type" />
                        <TextField label="Trigger Metered Message from" source="triggerMessageFrom" />
                    </div>
                </FormDataContent>
                <FormDataContent dataContentTitle="Main layer content">
                    <div className={classes.info_content_datas}>
                        <TextField label="Logo" source="logo" />
                        <div>
                            <p className={classes.title_field}>Text before button</p>
                            <RichTextField source="textBeforeButton" />
                        </div>
                        <TextField label="Button text" source="button_text" />
                        <div>
                            <p className={classes.title_field}>Text After button</p>
                            <RichTextField source="textAfterButton" />
                        </div>
                    </div>
                </FormDataContent>
                <FormDataContent dataContentTitle="Sticky layer content">
                    <div className={classes.info_content_datas}>
                        <TextField label="Small logo"source="logo_small" />
                        <div>
                            <p className={classes.title_field}>Text before button</p>
                            <RichTextField source="textStickyBeforeButton" />
                        </div>
                        <TextField label="Button text" source="sticky_button_text" />

                    </div>
                </FormDataContent>
                <FormDataContent dataContentTitle="Paywall layers">
                    <div className={classes.datas}>
                    <TextField label="Template menu header" source="template_menu_header" />
                    <TextField label="Template sticky" source="template_sticky" />
                    </div>
                </FormDataContent>
                <FormDataContent dataContentTitle="Traffic exposure">
                    <div className={classes.datas}>
                    <TextField label="Traffic exposure" source="traffic_exposure" />
                    <TextField label="Traffic exposure rate" source="traffic_exposure_rate" />
                    </div>
                </FormDataContent>

            </SimpleShowLayout>
        </Show>
    )

};

export default CampaignsShow;
