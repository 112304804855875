import React from "react";
import PropTypes from "prop-types";
import FormDataContent from "../../components/FormDataContent";
import ArrayInputContent from "../../components/ArrayInputContent";
import { TextInput } from "react-admin";
const Tracking = ({field = ""}) => {
    const source = field ? `${field}.` : "";
  return (
    <FormDataContent dataContentTitle="UTM">
      <ArrayInputContent
        arrayInputSource={`${source}tracking`}
        label={`Tracking`}
      >
        <TextInput
          helperText="ex: referral"
          label="utm medium"
          source={`utm_medium`}
        />
        <TextInput
          helperText="ex: HBP-hardlinks"
          label="utm campaign"
          source={`utm_campaign`}
        />
        <TextInput
          helperText="ex: HBP%20Paywall"
          label="utm source"
          source={`utm_source`}
        />
        <TextInput
          disabled
          helperText="This input will be set automatically"
          label="utm content"
          source={`utm_content`}
        />
      </ArrayInputContent>
    </FormDataContent>
  );
};

Tracking.propTypes = {};

export default Tracking;
