import React, { useState } from "react";
import { TextInput } from "react-admin";
import { makeStyles } from "@material-ui/core/styles";
import { blue } from "@material-ui/core/colors";
import LayerManager from "./LayerManager";
import FormDataContent from "./FormDataContent";
import StickyRegwallAuthLayerPreview from "./StickyRegwallAuthLayerPreview";
import { CompactPicker } from "react-color";
import TextEditor from "./TextEditor";
import { useField,useFormState } from "react-final-form";

const useStyles = makeStyles({
  section_title: {
    fontSize: "1.2rem",
    marginBottom: "0px",
  },
  section_title_underline: {
    width: "40px",
    height: "6px",
    backgroundColor: blue[400],
    marginBottom: "20px",
  },
  layers_manager_content: {
    display: "flex",
    marginBottom: "40px",
  },
  layers_manager_zone: {
    width: "33.3%",
    padding: "0px 20px 20px 20px",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
  },
  color_picker_zone: {
    padding: "0px 20px 20px 20px",
  },
  long_input: {
    width: "65%",
    marginRight: "10%",
  },
  hidden_input: {
    display: "none",
  },
  text_editor_content: {
    width: "65%",
  },
  color_picker_content: {
    paddingTop: "20px",
    display: "flex",
    justifyContent: "center",
  },
  title: {
    textAlign: "center",
    marginBottom: "20px",
    fontSize: "1em",
  },
});
const stickyRegwallAuthLogoPositionDefault = {
  verticalUp: {
    rowLeft: false,
    rowCenter: false,
    rowRight: true,
  },
};

const stickyRegwallAuthBackgroundColorDefault = "#FFFFFF";
const stickyRegwallAuthButtonBackgroundColorDefault = "#808080";

const StickyRegwallAuthLayerManager = (props) => {
  const { source = "", field = "" } = props;
  const classes = useStyles();
  const { values: formdata } = useFormState();
  const formdataSource = field ? getSource(formdata,field) : formdata;

  const {
    input: {
      value: stickyRegwallAuthLogoPosition,
      onChange: onChangeStickyRegwallAuthLogoPosition,
    },
  } = useField(`${source}sticky_regwall_auth_logo_position`, {
    defaultValue: stickyRegwallAuthLogoPositionDefault,
  });
  const {
    input: {
      value: stickyRegwallAuthBackgroundColor,
      onChange: onChangeStickyRegwallAuthBackgroundColor,
    },
  } = useField(`${source}sticky_regwall_auth_background_color`, 
  field !== "overrides" && 
  {
    defaultValue: stickyRegwallAuthBackgroundColorDefault,
  });
  const {
    input: {
      value: stickyRegwallAuthButtonBackgroundColor,
      onChange: onChangeStickyRegwallAuthButtonBackgroundColor,
    },
  } = useField(`${source}sticky_regwall_auth_button_background_color`, 
  field !== "overrides" && 
  {
    defaultValue: stickyRegwallAuthButtonBackgroundColorDefault,
  });
  const {
    input: {
      value: textStickyRegwallAuthBeforeButton,
      onChange: onChangeTextStickyRegwallAuthBeforeButton,
    },
  } = useField(`${source}textStickyRegwallAuthBeforeButton`, 
  field !== "overrides" && 
  {
    defaultValue: "",
  });

  const [
    stickyRegwallAuthLogoPositionPreview,
    setStickyRegwallAuthLogoPositionPreview,
  ] = useState(positionForPreview(stickyRegwallAuthLogoPosition));

  function positionForPreview(elementPosition) {
    for (const [vKey, vValue] of Object.entries(elementPosition)) {
      for (const [rKey, rValue] of Object.entries(vValue)) {
        if (rValue) {
          return {
            vertical: vKey,
            row: rKey,
          };
        }
      }
    }
  }

  function handleChangeStickyRegwallAuthBackgroundColor(color) {
    onChangeStickyRegwallAuthBackgroundColor(color.hex);
  }

  function handleChangeStickyRegwallAuthButtonBackgroundColor(color) {
    onChangeStickyRegwallAuthButtonBackgroundColor(color.hex);
  }

  function handleStickyRegwallAuthPosition(
    element,
    verticalPosition,
    rowPosition
  ) {
    let newLogoPosition = { ...stickyRegwallAuthLogoPosition };

    if (element === "logo") {
      for (const [vKey, vValue] of Object.entries(newLogoPosition)) {
        for (const [rKey, rValue] of Object.entries(vValue)) {
          if (vKey === verticalPosition) {
            if (rKey === rowPosition) {
              newLogoPosition[vKey][rowPosition] = true;
            } else if (newLogoPosition[vKey][rKey] && rKey !== rowPosition) {
              newLogoPosition[vKey][rKey] = false;
            }
          } else {
            if (newLogoPosition[vKey][rKey]) {
              newLogoPosition[vKey][rKey] = false;
            }
          }
        }
      }
    }

    onChangeStickyRegwallAuthLogoPosition(newLogoPosition);
    setStickyRegwallAuthLogoPositionPreview(
      positionForPreview(newLogoPosition)
    );
  }
  return (
    <div>
      <FormDataContent dataContentTitle="Sticky Regwall layer content (bottom page)">
        <TextInput
          helperText="ex: https://cdn.mos.cms.futurecdn.net/flexiimages/m7awu8serc1613169967.svg"
          className={classes.long_input}
          label={`Logo Regwall Auth`}
          source={`${source}logo_small_regwall_auth`}
        />
        <div className={classes.text_editor_content}>
          <TextEditor
            editorTitle="Text sticky before button"
            textContent={textStickyRegwallAuthBeforeButton}
            handleText={onChangeTextStickyRegwallAuthBeforeButton}
          />
        </div>
        <TextInput
          helperText="ex: Access now"
          label={`Sticky Regwall Text Auth Button`}
          className={classes.long_input}
          source={`${source}sticky_regwall_auth_button_text`}
        />
      </FormDataContent>
      <p className={classes.section_title}>
        Sticky Regwall Layer customization
      </p>
      <div className={classes.section_title_underline}></div>
      <div className={classes.layers_manager_content}>
        <div className={classes.layers_manager_zone}>
          <LayerManager
            layer="sticky"
            element="logo"
            elementPosition={stickyRegwallAuthLogoPosition}
            handlePosition={handleStickyRegwallAuthPosition}
          />
        </div>
        <div className={classes.layers_manager_zone}>
          <div className={classes.color_picker_zone}>
            <p className={classes.title}>Choose background color</p>
            <div className={classes.color_picker_content}>
              <CompactPicker
                color={stickyRegwallAuthBackgroundColor}
                onChangeComplete={handleChangeStickyRegwallAuthBackgroundColor}
              />
            </div>
          </div>
          <div className={classes.color_picker_zone}>
            <p className={classes.title}>Choose button background color</p>
            <div className={classes.color_picker_content}>
              <CompactPicker
                color={stickyRegwallAuthButtonBackgroundColor}
                onChangeComplete={
                  handleChangeStickyRegwallAuthButtonBackgroundColor
                }
              />
            </div>
          </div>
        </div>
      </div>
      <TextInput
        className={classes.hidden_input}
        source={`${source}sticky_regwall_auth_logo_position`}
        label={`Sticky Regwall Auth Logo Position`}
      />
      <TextInput
        className={classes.hidden_input}
        source={`${source}sticky_regwall_auth_background_color`}
        label={`Sticky Regwall Auth Background Color`}
      />
      <TextInput
        className={classes.hidden_input}
        source={`${source}sticky_regwall_auth_button_background_color`}
        label={`Sticky Regwall Auth Button Background Color`}
      />
      <TextInput
        className={classes.hidden_input}
        source={`${source}textStickyRegwallAuthBeforeButton`}
        label={`Sticky Regwall Auth Text Before Button`}
      />
      <p className={classes.section_title}>Sticky Regwall Layer preview</p>
      <div className={classes.section_title_underline}></div>
      <StickyRegwallAuthLayerPreview
        formdataSource={formdataSource}
        logoPositionPreview={stickyRegwallAuthLogoPositionPreview}
        backgroundColor={stickyRegwallAuthBackgroundColor}
        buttonBackgroundColor={stickyRegwallAuthButtonBackgroundColor}
      />
    </div>
  );
};

export default StickyRegwallAuthLayerManager;
