import * as Campaigns from './entities/Campaigns';
import * as CustomTemplates from './entities/CustomTemplates';
import * as Settings from './entities/Settings';
import * as SiteConfig from './entities/SiteConfig';
import * as User from './entities/User';

const resources = [
    User,
    SiteConfig,
    CustomTemplates,
    Campaigns,
    Settings
];

export default resources;
