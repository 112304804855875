import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import parse from 'html-react-parser';
import { useFormState } from 'react-final-form';

const RegwallAuthLayerPreview = (props) => {
    const { values } = useFormState();

    const useStyles = makeStyles({
        preview_content: {
            padding: '20px',
            border: '1px solid #E8E8E8',
            marginBottom: '50px',
        },
        kiosq_main_layer: {
            display: 'flex',
            justifyContent: 'center'
        },
        kiosq_main_layer_container: {
            padding: '20px',
            width: '602px',
        },
        kiosq_logo_rowLeft: {
            marginBottom: '20px',
            display: 'flex',
            justifyContent: 'flex-start',
        },
        kiosq_logo_rowCenter: {
            marginBottom: '20px',
            display: 'flex',
            justifyContent: 'center',
            order:'2'
        },
        kiosq_logo_rowRight: {
            marginBottom: '20px',
            display: 'flex',
            justifyContent: 'flex-end',
            order:'2'
        },
        kiosq_logo_img: {
            width: '140px',
        },
        kiosq_login_button_rowLeft: {
            fontSize: '13px',
            marginBottom: '25px',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'flex-start',
        },
        kiosq_login_button_rowCenter: {
            fontSize: '13px',
            marginBottom: '25px',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            order:'2'
        },
        kiosq_login_button_rowRight: {
            fontSize: '13px',
            marginBottom: '25px',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'flex-end',
            order:'2'
        },
        kiosq_login_button_button: {
            height: '44px',
            width: '360px',
            textAlign: 'center',
            color: '#fff',
            marginBottom: '10px',
            border: 'none',
            fontWeight: '700',
            textTransform: 'uppercase',
            cursor: 'pointer',
        },
        element_at_same_line_space_around: {
            display: 'flex',
            justifyContent: 'space-around',
        },
        element_at_same_line_space_between: {
            display: 'flex',
            justifyContent: 'space-between',
        },
        kiosq_description: {
            fontSize: '14px',
            lineHeight: '22px',
            marginBottom: '25px',
            '& .custom_h1' : {
                marginBottom: '0.5rem',
                fontSize: '28px',
                lineHeight: '34px',
                marginTop: '0px',
            },
            '& .custom_h2' : {
                fontSize: '18px',
                fontWeight: 'bold',
                lineHeight: '24px',
                marginBottom: '10px',
                marginTop: '0px',
            },
            '& .custom_h3' : {
                fontSize: '16px',
                fontWeight: 'bold',
                lineHeight: '24px',
                marginBottom: '10px',
                marginTop: '0px',
            }
        },
        kiosq_conditions: {
            fontSize: '14px',
            lineHeight: '22px',
            '& ul': {
                listStyleType: 'disc',
                listStylePosition: 'outside',
                marginBottom: '25px',
                marginLeft: '16px',
                padding:'0px',
                '& li': {
                    fontSize: '14px',
                    lineHeight: '20px',
                }
            },
            '& p' : {
                margin: '0px',
            },
        },
    });
    const classes = useStyles();

    return (
            <div className={classes.preview_content}>
                <div className={classes.kiosq_main_layer}>
                    <div className={classes.kiosq_main_layer_container} style={{backgroundColor: props.backgroundColor}}>
                        { values.logo_regwall !== undefined && values.logo_regwall !== null && props.logoPositionPreview.vertical === 'verticalUp' && props.buttonPositionPreview.vertical === 'verticalUp' ?
                          <div className={
                              (props.logoPositionPreview.row === 'rowLeft' &&  props.buttonPositionPreview.row === 'rowCenter')||
                              (props.buttonPositionPreview.row === 'rowLeft' &&  props.logoPositionPreview.row === 'rowCenter') ?
                              classes.element_at_same_line_space_around :
                              classes.element_at_same_line_space_between
                              }
                          >
                              <div className={
                                      props.logoPositionPreview.row === 'rowLeft'?
                                      classes.kiosq_logo_rowLeft :
                                      props.logoPositionPreview.row === 'rowCenter'?
                                      classes.kiosq_logo_rowCenter :
                                      classes.kiosq_logo_rowRight
                                  }
                              >
                                  <img className={classes.kiosq_logo_img} src={ values.logo_regwall } />
                              </div>
                              <div className={
                                      props.buttonPositionPreview.row === 'rowLeft'?
                                      classes.kiosq_login_button_rowLeft :
                                      props.buttonPositionPreview.row === 'rowCenter'?
                                      classes.kiosq_login_button_rowCenter :
                                      classes.kiosq_login_button_rowRight
                                  }
                              >
                                  <div><button className={classes.kiosq_login_button_button} style={{backgroundColor: props.buttonBackgroundColor}}>{values.button_text_regwall }</button></div>
                                  <a className={classes.kiosq_login_link}>Already have an account ? <strong>Sign in here</strong></a>
                              </div>
                          </div> :
                          values.logo_regwall !== undefined && values.logo_regwall !== null && props.logoPositionPreview.vertical === 'verticalUp' && props.buttonPositionPreview.vertical !== 'verticalUp' ?
                          <div className={
                                  props.logoPositionPreview.row === 'rowLeft'?
                                  classes.kiosq_logo_rowLeft :
                                  props.logoPositionPreview.row === 'rowCenter'?
                                  classes.kiosq_logo_rowCenter :
                                  classes.kiosq_logo_rowRight
                              }
                          >
                              <img className={classes.kiosq_logo_img} src={ values.logo_regwall } />
                          </div> :
                          (props.buttonPositionPreview.vertical === 'verticalUp' && props.logoPositionPreview.vertical !== 'verticalUp') ||
                          (props.buttonPositionPreview.vertical === 'verticalUp' && (values.logo_regwall === undefined || values.logo_regwall === null) && props.logoPositionPreview.vertical === 'verticalUp') ?
                          <div className={
                                  props.buttonPositionPreview.row === 'rowLeft'?
                                  classes.kiosq_login_button_rowLeft :
                                  props.buttonPositionPreview.row === 'rowCenter'?
                                  classes.kiosq_login_button_rowCenter :
                                  classes.kiosq_login_button_rowRight
                              }
                          >
                              <div><button className={classes.kiosq_login_button_button} style={{backgroundColor: props.buttonBackgroundColor}}>{values.button_text_regwall }</button></div>
                              <a className={classes.kiosq_login_link}>Already have an account ? <strong>Sign in here</strong></a>
                          </div>:
                          null
                        }

                        <div className={classes.kiosq_description}>
                            { (values.textBeforeButtonRegwall !== undefined && values.textBeforeButtonRegwall !== null) && parse(values.textBeforeButtonRegwall) }
                        </div>
                        { props.buttonPositionPreview.vertical === 'verticalCenter' &&
                            <div className={
                                    props.buttonPositionPreview.row === 'rowLeft'?
                                    classes.kiosq_login_button_rowLeft :
                                    props.buttonPositionPreview.row === 'rowCenter'?
                                    classes.kiosq_login_button_rowCenter :
                                    classes.kiosq_login_button_rowRight
                                }
                            >
                                <div><button className={classes.kiosq_login_button_button} style={{backgroundColor: props.buttonBackgroundColor}}>{values.button_text_regwall }</button></div>
                                <a className={classes.kiosq_login_link}>Already have an account ? <strong>Sign in here</strong></a>
                            </div>
                        }
                        <div className={classes.kiosq_conditions}>
                            {(values.textAfterButtonRegwall !== undefined && values.textAfterButtonRegwall !== null) && parse(values.textAfterButtonRegwall)}
                        </div>
                        { values.logo_regwall !== undefined && values.logo_regwall !== null && props.logoPositionPreview.vertical === 'verticalDown' && props.buttonPositionPreview.vertical === 'verticalDown' ?
                          <div className={
                              (props.logoPositionPreview.row === 'rowLeft' &&  props.buttonPositionPreview.row === 'rowCenter')||
                              (props.buttonPositionPreview.row === 'rowLeft' &&  props.logoPositionPreview.row === 'rowCenter') ?
                              classes.element_at_same_line_space_around :
                              classes.element_at_same_line_space_between
                              }
                          >
                              <div className={
                                      props.logoPositionPreview.row === 'rowLeft'?
                                      classes.kiosq_logo_rowLeft :
                                      props.logoPositionPreview.row === 'rowCenter'?
                                      classes.kiosq_logo_rowCenter :
                                      classes.kiosq_logo_rowRight
                                  }
                              >
                                  <img className={classes.kiosq_logo_img} src={ values.logo_regwall } />
                              </div>
                              <div className={
                                      props.buttonPositionPreview.row === 'rowLeft'?
                                      classes.kiosq_login_button_rowLeft :
                                      props.buttonPositionPreview.row === 'rowCenter'?
                                      classes.kiosq_login_button_rowCenter :
                                      classes.kiosq_login_button_rowRight
                                  }
                              >
                                  <div><button className={classes.kiosq_login_button_button} style={{backgroundColor: props.buttonBackgroundColor}}>{values.button_text_regwall }</button></div>
                                  <a className={classes.kiosq_login_link}>Already have an account ? <strong>Sign in here</strong></a>
                              </div>
                          </div> :
                          values.logo_regwall !== undefined && values.logo_regwall !== null && props.logoPositionPreview.vertical === 'verticalDown' && props.buttonPositionPreview.vertical !== 'verticalDown' ?
                          <div className={
                                  props.logoPositionPreview.row === 'rowLeft'?
                                  classes.kiosq_logo_rowLeft :
                                  props.logoPositionPreview.row === 'rowCenter'?
                                  classes.kiosq_logo_rowCenter :
                                  classes.kiosq_logo_rowRight
                              }
                          >
                              <img className={classes.kiosq_logo_img} src={ values.logo_regwall } />
                          </div> :
                          (props.buttonPositionPreview.vertical === 'verticalDown' && props.logoPositionPreview.vertical !== 'verticalDown') ||
                          (props.buttonPositionPreview.vertical === 'verticalDown' && (values.logo_regwall === undefined || values.logo_regwall === null) && props.logoPositionPreview.vertical === 'verticalDown') ?
                          <div className={
                                  props.buttonPositionPreview.row === 'rowLeft'?
                                  classes.kiosq_login_button_rowLeft :
                                  props.buttonPositionPreview.row === 'rowCenter'?
                                  classes.kiosq_login_button_rowCenter :
                                  classes.kiosq_login_button_rowRight
                              }
                          >
                              <div><button className={classes.kiosq_login_button_button} style={{backgroundColor: props.buttonBackgroundColor}}>{values.button_text_regwall }</button></div>
                              <a className={classes.kiosq_login_link}>Already have an account ? <strong>Sign in here</strong></a>
                          </div>:
                          null
                        }
                    </div>
                </div>
            </div>
    );
};

export default RegwallAuthLayerPreview;
