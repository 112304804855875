import React from "react";
import { Create, useNotify, useRefresh, useRedirect } from "react-admin";
import EditFormSiteConfig from "../SiteConfig/EditForm";
import EditForm from "./EditForm";

const CampaignsCreate = (props) => {
  const notify = useNotify();
  const refresh = useRefresh();
  const redirect = useRedirect();

  const onSuccess = ({ data }) => {
    notify(`New Campaigns "${data.name}" created`);
    redirect("/campaigns");
    refresh();
  };
  const onFailure = (error) => {
    notify(`Could not create Campaigns: ${error.message}`);
    redirect("/campaigns");
    refresh();
  };

  return (
    <Create onSuccess={onSuccess} onFailure={onFailure} {...props}>
      <EditForm redirect="list" origin={"create"} />
    </Create>
  );
};

export default CampaignsCreate;
