import React, { useState } from "react";
import { TextInput } from "react-admin";
import { makeStyles } from "@material-ui/core/styles";
import { blue } from "@material-ui/core/colors";
import LayerManager from "./LayerManager";
import StickyLayerPreview from "./StickyLayerPreview";
import RegwallStickyLayerPreview from "./RegwallStickyLayerPreview";
import { CompactPicker } from "react-color";
import TextEditor from "./TextEditor";
import { useFormState, useField } from "react-final-form";
import { getSource } from "../tools";

const useStyles = makeStyles({
  section_title: {
    fontSize: "1.2rem",
    marginBottom: "0px",
  },
  section_title_underline: {
    width: "40px",
    height: "6px",
    backgroundColor: blue[400],
    marginBottom: "20px",
  },
  layers_manager_content: {
    display: "flex",
    marginBottom: "40px",
  },
  layers_manager_zone: {
    width: "33.3%",
    padding: "0px 20px 20px 20px",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
  },
  color_picker_zone: {
    padding: "0px 20px 20px 20px",
  },
  long_input: {
    width: "65%",
    marginRight: "10%",
  },
  hidden_input: {
    display: "none",
  },
  text_editor_content: {
    width: "65%",
  },
  color_picker_content: {
    paddingTop: "20px",
    display: "flex",
    justifyContent: "center",
  },
  title: {
    textAlign: "center",
    marginBottom: "20px",
    fontSize: "1em",
  },
});
const stickyLogoPositionDefault = {
  verticalUp: {
    rowLeft: false,
    rowCenter: false,
    rowRight: true,
  },
};

const stickyBackgroundColorDefault = "#FFFFFF";
const stickyButtonBackgroundColorDefault = "#808080";

const StickyLayerManager = (props) => {
  const { source = "", field = "" } = props;
  const classes = useStyles();
  const { values: formdata } = useFormState();
  const formdataSource = field ? getSource(formdata,field) : formdata;

  const {
    input: { value: stickyLogoPosition, onChange: onChangeStickyLogoPosition },
  } = useField(`${source}sticky_regwall_auth_logo_position`,
  {
    defaultValue: stickyLogoPositionDefault,
  });
  const {
    input: {
      value: stickyBackgroundColor,
      onChange: onChangeStickyBackgroundColor,
    },
  } = useField(`${source}sticky_regwall_auth_background_color`,
  field !== "overrides" &&
  {
    defaultValue: stickyBackgroundColorDefault,
  });
  const {
    input: {
      value: stickyButtonBackgroundColor,
      onChange: onChangeStickyButtonBackgroundColor,
    },
  } = useField(`${source}sticky_regwall_auth_button_background_color`,
  field !== "overrides" &&
  {
    defaultValue: stickyButtonBackgroundColorDefault,
  });
  const {
    input: {
      value: textStickyBeforeButton,
      onChange: onChangeTextStickyBeforeButton,
    },
  } = useField(`${source}textStickyBeforeButton`,
  field !== "overrides" &&
  {
    defaultValue: "",
  });

  const [stickyLogoPositionPreview, setStickyLogoPositionPreview] = useState(
    positionForPreview(stickyLogoPosition)
  );

  function positionForPreview(elementPosition) {
    for (const [vKey, vValue] of Object.entries(elementPosition)) {
      for (const [rKey, rValue] of Object.entries(vValue)) {
        if (rValue) {
          return {
            vertical: vKey,
            row: rKey,
          };
        }
      }
    }
  }

  function handleChangeStickyBackgroundColor(color) {
    onChangeStickyBackgroundColor(color.hex);
  }

  function handleChangeStickyButtonBackgroundColor(color) {
    onChangeStickyButtonBackgroundColor(color.hex);
  }

  function handleStickyPosition(element, verticalPosition, rowPosition) {
    let newLogoPosition = { ...stickyLogoPosition };

    if (element === "logo") {
      for (const [vKey, vValue] of Object.entries(newLogoPosition)) {
        for (const [rKey, rValue] of Object.entries(vValue)) {
          if (vKey === verticalPosition) {
            if (rKey === rowPosition) {
              newLogoPosition[vKey][rowPosition] = true;
            } else if (newLogoPosition[vKey][rKey] && rKey !== rowPosition) {
              newLogoPosition[vKey][rKey] = false;
            }
          } else {
            if (newLogoPosition[vKey][rKey]) {
              newLogoPosition[vKey][rKey] = false;
            }
          }
        }
      }
    }

    onChangeStickyLogoPosition(newLogoPosition);
    setStickyLogoPositionPreview(positionForPreview(newLogoPosition));
  }
  return (
    <div>
      <p className={classes.section_title}>
        Sticky layer content (bottom page)
      </p>
      <div className={classes.text_editor_content}>
        <TextEditor
          editorTitle="Text sticky before button"
          textContent={textStickyBeforeButton}
          handleText={onChangeTextStickyBeforeButton}
        />
      </div>
      {(formdataSource?.wall_type !== "regwall") ? (
        <TextInput
          helperText="ex: Access now"
          className={classes.long_input}
          source={`${source}sticky_button_text`}
        />
      ) : null}

      <p className={classes.section_title}>Sticky Layer customization</p>
      <div className={classes.layers_manager_content}>
        <div className={classes.layers_manager_zone}>
          <LayerManager
            layer="sticky"
            element="logo"
            elementPosition={stickyLogoPosition}
            handlePosition={handleStickyPosition}
          />
        </div>
        <div className={classes.layers_manager_zone}>
          <div className={classes.color_picker_zone}>
            <p className={classes.title}>Choose background color</p>
            <div className={classes.color_picker_content}>
              <CompactPicker
                color={stickyBackgroundColor}
                onChangeComplete={handleChangeStickyBackgroundColor}
              />
            </div>
          </div>
          <div className={classes.color_picker_zone}>
            <p className={classes.title}>Choose button background color</p>
            <div className={classes.color_picker_content}>
              <CompactPicker
                color={stickyButtonBackgroundColor}
                onChangeComplete={handleChangeStickyButtonBackgroundColor}
              />
            </div>
          </div>
        </div>
      </div>

      <div>
        <TextInput
          className={classes.hidden_input}
          source={`${source}sticky_logo_position`}
        />
        <TextInput
          className={classes.hidden_input}
          source={`${source}sticky_background_color`}
        />
        <TextInput
          className={classes.hidden_input}
          source={`${source}sticky_button_background_color`}
        />
        <TextInput
          className={classes.hidden_input}
          source={`${source}textStickyBeforeButton`}
        />
      </div>

      <p className={classes.section_title}>Sticky Layer preview</p>
      {formdataSource?.wall_type !== "regwall" ? (
        <StickyLayerPreview
          formdataSource={formdataSource}
          logoPositionPreview={stickyLogoPositionPreview}
          backgroundColor={stickyBackgroundColor}
          buttonBackgroundColor={stickyButtonBackgroundColor}
        />
      ) : (
        <RegwallStickyLayerPreview
          formdataSource={formdataSource}
          logoPositionPreview={stickyLogoPositionPreview}
          backgroundColor={stickyBackgroundColor}
          buttonBackgroundColor={stickyButtonBackgroundColor}
        />
      )}
    </div>
  );
};

export default StickyLayerManager;
