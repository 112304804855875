import React from "react";
import { useGetList } from "react-admin";
import PropTypes from "prop-types";

const useSettings = (key = "") => {

  var settingsList = [];
  const { data, total } = useGetList(
    "settings",
    { page: 1, perPage: 10 },
    { field: "last_modified", order: "DESC" },
    { setting: key }
    );

    if (total === 0) {
      return settingsList;
    }
    if(key){
      settingsList = Object.values(data)
    }
  return settingsList[0];
};

useSettings.propTypes = {
  key: PropTypes.string.isRequired,
};

export default useSettings;
