import { useState, useCallback } from 'react';
import { defaultOperators } from "react-querybuilder";

export const defaultOp = defaultOperators.map((op) => {
  return { id: op.name, name: op.label };
});

export const defaultOpWithCustom = [...defaultOp,{id:"existOne",name:"exist one"},{id:"notExistOne",name:"not exist one"},{ id: "existOneBy", name: "exist one by" },
{ id: "notexistOneBy", name: "not exist one by" }];//add some custom operator who will format the cel in specific situation on QueryBuilder

/** this hook is providing some filtered operators by input type defined in the setting */
const useOperatorsQB = () => {
  const [operators, setOperators] = useState(defaultOpWithCustom || []);

  const handleChangeOperators = useCallback((e) => {
    switch (e.target?.value) {
      case "multiselect":
        var operatorsList = [
          { id: "in", name: "in" },
          { id: "notIn", name: "not in" },
        ];
        setOperators(operatorsList);
        break;
      case "date":
        var operatorsList = [
          { id: "between", name: "between" },
          { id: "notBetween", name: "not between" },
          { id: "=", name: "=" },
          { id: ">", name: ">" },
          { id: "<", name: "<" },
          { id: "<=", name: "<=" },
          { id: ">=", name: ">=" },
        ];
        setOperators(operatorsList);
        break;
      case "number":
        var operatorsList = [
          { id: "=", name: "=" },
          { id: ">", name: ">" },
          { id: "<", name: "<" },
          { id: "<=", name: "<=" },
          { id: ">=", name: ">=" },
        ];
        setOperators(operatorsList);
        break;
      case "checkbox":
        var operatorsList = [{ id: "=", name: "=" }];
        setOperators(operatorsList);
        break;
      default:
        setOperators(defaultOpWithCustom);
        break;
    }
  }, [defaultOpWithCustom]);

  return {
    operators,
    handleChangeOperators
  };
};

export default useOperatorsQB;
