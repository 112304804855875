import React from "react";
import PropTypes from "prop-types";
import { Tooltip } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import Typography from "@material-ui/core/Typography";
import HelpIcon from '@mui/icons-material/Help';

const useStyles = makeStyles({
customWidth: {width: '800px'}
});

const RenderTitle = (props) => {
  const { fieldsToRender, record } = props;
  const values = fieldsToRender.reduce((acc,{label,field}) => {
    if (record[field])
      { acc.push({label,value:record[field]})}; return acc}
    ,[])
  return (
    <>
      {values.length > 0 &&
        values.map((value, k) => {
          return (
            <React.Fragment key={`tooltip-${k}`}>
              {<Typography>{value.label}: {value.value}</Typography>}
              <br />
            </React.Fragment>
          );
        })}
    </>
  );
};

const TooltipControlled = ({
  record,
  fieldsToRender = [],
  icon = <HelpIcon/>,
}) => {
  
const classes = useStyles();
  return (
    <>
        {fieldsToRender?.length > 0 ?
        <Tooltip
            leaveDelay={4000}
            interactive
            classes={{tooltip:classes.customWidth}}
            placement="top"
            title={<RenderTitle record={record} fieldsToRender={fieldsToRender} />}
        >
            { React.cloneElement(icon)}
        </Tooltip>
        : null
        }
    </>
  );
};

TooltipControlled.propTypes = {
  record: PropTypes.object,
  fieldsToRender: PropTypes.array.isRequired,
  icon: PropTypes.node
};

export default TooltipControlled;
