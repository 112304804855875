import React from 'react';
import { TextInput, SelectInput, Filter } from 'react-admin';
import { createChoices } from "../User/Filter";
const siteChoicesTab = createChoices('sites');

const ListFilter = props => (
    <Filter {...props}>
        <TextInput source="name" label="Search in name" alwaysOn resettable/>
        <TextInput source="id" resettable label="Search in id (guid)"/>
        <SelectInput
            fullWidth={true}
            alwaysOn
            resettable
            style={{minWidth: '250px'}}
            source="site_config_overrided"
            label="Search in site"
            choices={siteChoicesTab}
        />
    </Filter>
);

export default /* withStyles(styles) */(ListFilter);
