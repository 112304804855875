import React from 'react';
import { DateInput, AutocompleteInput, TextInput, FormDataConsumer } from 'react-admin';

const ProductGrantedInputs = props => {
    const paywallGrantedContent = {
        display: 'flex',
        alignItems: 'center'
    };
    const paywallGrantedInputContent = {
        marginRight: '30px',
    };

    function createChoices () {
        let journalTab = [];
        let productIDTab = [];
        let results= [];
        for (const [key, value] of Object.entries(window.sites_config)) {
            let journalOccurence = 0;

            //Check if journal already exist (Can happen when several site configs are set on one site on severals region)
            for (var i = 0; i < journalTab.length; i++) {
                if (journalTab[i].id === window.sites_config[key].journal ) {
                    journalOccurence += 1;
                }
            }

            //No push journal in tab if it's already exist or undefined
            if (journalOccurence < 1 && window.sites_config[key].journal != undefined) {
                journalTab.push({id: window.sites_config[key].journal, name: window.sites_config[key].journal })
            }

            let productIDOccurence = 0;

            //Check if productID already exist because we can have several productID per journal e.g: default
            for (var i = 0; i < productIDTab.length; i++) {
                if (productIDTab[i].id === window.sites_config[key].productID ) {
                    productIDOccurence += 1;
                }
            }

            //No push offer Code in tab if it's already exist or undefined
            if (productIDOccurence < 1  && window.sites_config[key].productID != undefined) {
                productIDTab.push({id: window.sites_config[key].productID, name: window.sites_config[key].productID })
            }
        }
        results['journal'] = journalTab;
        results['productID'] = productIDTab;

        return results;
    }

    let ChoicesTab = createChoices();

    return (
        <div style={paywallGrantedContent}>
            <div style={paywallGrantedInputContent} >
                <AutocompleteInput label ="Journal" source={"journal_to_add" + props.number} choices={ChoicesTab['journal']} translateChoice={false} />
            </div>
            <FormDataConsumer>
                {
                    ({ formData, ...rest }) =>{
                    let journalSource = "journal_to_add" + props.number;
                    if (formData[journalSource] === 'default' ) {
                        return (
                            <div style={paywallGrantedInputContent} >
                                <AutocompleteInput label="Product ID" source={"offer_code_to_add" + props.number} choices={ChoicesTab['productID']} translateChoice={false} />
                            </div>
                        )
                    }else {
                        return (
                            <div style={paywallGrantedInputContent} >
                                <TextInput label ="Offer Code" source={"offer_code_to_add" + props.number} />
                            </div>
                        )
                    }}
                }
            </FormDataConsumer>

            <div style={paywallGrantedInputContent} >
                <DateInput  label="Grace period"
                            source={"user_metadata.products_granted.journal_to_add"+ props.number + ".offer_code_to_add" + props.number}
                />
            </div>
        </div>
    )
}

export default ProductGrantedInputs;
