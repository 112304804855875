import * as React from "react";
import {
    ImageField,
} from 'react-admin';
import { makeStyles } from '@material-ui/core/styles';
import { green, red, blue } from '@material-ui/core/colors';
import { ContactsOutlined } from "@material-ui/icons";
import { Card } from "@material-ui/core";

const useStyles = makeStyles({
    card_title: {
        fontSize: '20px',
        marginBottom: '0px'
    },
    card_title_underline: {
        width: '40px',
        height:'6px',
        backgroundColor: blue[400],
        marginBottom: '20px'
    },
    cds_subcard_content: {
        display: 'flex',
        flexWrap: 'wrap',
    },
    cds_subcard: {
        backgroundColor: '#F8F8F8',
        padding:'16px',
        margin: '0px 10px 20px 10px'
    },
    data_title: {
        fontSize: '12px',
        color: 'rgba(0, 0, 0, 0.54)',
    },
    cds_data: {
        marginLeft: '15px',
        fontSize: '12px',
        color: 'black'
    },
    data_active: {
        fontSize: '12px',
        marginLeft: '10px',
        color: green[500]
    },
    data_inactive: {
        fontSize: '12px',
        marginLeft: '10px',
        color: red[300]
    },
});


const CdsEntitlements = ({ ...props }) => {
    const classes = useStyles();

    return (typeof props.record.cdsEntitlements !== 'undefined' && props.record.cdsEntitlements.length != 0 &&
        <div>
            <p className={classes.card_title}>Cds details</p>
            <div className={classes.card_title_underline}></div>
            {props.record.cdsEntitlements.map(cdsInfos => {
                return (typeof cdsInfos.customer !== 'undefined' &&
                    <div  className={classes.cds_subcard_content}>
                        <Card className={classes.cds_subcard}>
                            <p className={classes.data_title} >
                                <b>Cds customer info:</b>
                            </p>
                            <p className={classes.data_title} >
                                Service status:
                                <span className={cdsInfos.customer.serviceStatusDescription == 'Active' ? classes.data_active : classes.data_inactive }>{cdsInfos.customer.serviceStatusDescription ?? ''}</span>
                            </p>
                            <p className={classes.data_title} >
                                Start issue cover date:
                                <span className={classes.cds_data}>{cdsInfos.customer.startIssueCoverDate ?? ''}</span>
                            </p>
                            <p className={classes.data_title} >
                                Expire issue cover date:
                                <span className={classes.cds_data}>{cdsInfos.customer.expireIssueCoverDate ?? ''}</span>
                            </p>
                        </Card>
                        {cdsInfos.orders.map((siteSubscription, key) =>
                            <Card className={classes.cds_subcard} key={'subsription' + key}>
                                <p className={classes.data_title} >
                                    <b>Order number:</b>
                                    <span className={classes.cds_data}>{siteSubscription.orderNumber ?? ''}</span>
                                </p>
                                <p className={classes.data_title} >
                                    Site:
                                    <span className={classes.cds_data}>{siteSubscription.prodId ?? ''}</span>
                                </p>
                                <p className={classes.data_title} >
                                    Product name:
                                    <span className={classes.cds_data}>{siteSubscription.productName ?? ''}</span>
                                </p>
                                <p className={classes.data_title} >
                                    Subscription start cover date:
                                    <span className={classes.cds_data}>{siteSubscription.subscriptionStartCoverDate ?? ''}</span>
                                </p>
                                <p className={classes.data_title} >
                                    status description:
                                    <span className={classes.cds_data}>{siteSubscription.statusDescription ?? ''}</span>
                                </p>
                                <p className={classes.data_title} >
                                    Offer value:
                                    <span className={classes.cds_data}>{siteSubscription.orderValue ?? ''}</span>
                                </p>
                            </Card>
                        )}
                    </div>
                )
            })}
        </div>
    )
}


export default CdsEntitlements;
