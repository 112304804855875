import React from 'react';
import {Datagrid, TextField, EditButton, DeleteButton, ShowButton, SelectArrayInput, CloneButton} from 'react-admin';
import CustomDateField from '../../components/CustomDateField';
import { List, Filter, TextInput } from 'react-admin';

const Filters = (props) => {
    return (
        <Filter {...props}>
            <TextInput source="headline" />
            <SelectArrayInput source="tag" choices={[
                { id: 'offer', name: 'Offer' }
            ]} />
        </Filter>
    );
};

const CustomTemplateList = (props) => {
    const { classes, resource } = props;
    return (
        <List filters={<Filters />} sort={{ field: 'last_modified', order: 'DESC' }} {...props}>
            <Datagrid>
                <TextField source="headline" />
                <CustomDateField source="last_modified" label="Last modified" />
                <CustomDateField source="created_at" />
                <EditButton />
                <CloneButton/>
                <ShowButton />
                 <DeleteButton />
            </Datagrid>
        </List>
    );
};

export default CustomTemplateList;