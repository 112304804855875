import * as React from 'react';
import { forwardRef } from 'react';
import { useAuth0 } from "@auth0/auth0-react";
import MenuItem from '@material-ui/core/MenuItem';
import ExitIcon from '@material-ui/icons/PowerSettingsNew';

const LogoutButton = forwardRef((props, ref) => {
    const { logout } = useAuth0();
    const handleClick = () => logout({ returnTo: window.location.origin });
    return (
        <MenuItem
            onClick={handleClick}
            ref={ref}
        >
            <ExitIcon /> <span style={{fontSize:'15px', marginLeft:'10px'}}>Logout</span>
        </MenuItem>
    );
});

export default LogoutButton;
