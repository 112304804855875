const authProvider =(isAuthenticated,roles) => ({
    // authentication
    checkAuth: () => {
        if (isAuthenticated) {
            return Promise.resolve();
        }
        return Promise.reject();
    },
    checkError: (error) => {
        const status = error.status;
        if (status === 401 || status === 403) {
            localStorage.removeItem('token');
            return Promise.reject();
        }
        // other error code (404, 500, etc): no need to log out
        return Promise.resolve();
    },
    getPermissions: () => {
        if (isAuthenticated) {
            return Promise.resolve(roles);
        }
        Promise.reject()
    },
    logout: () => {
        localStorage.removeItem('user');
        localStorage.removeItem('token');
        return Promise.resolve();
    },
})

export default authProvider;
