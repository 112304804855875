import React from "react";
import { Datagrid, DatagridBody } from "react-admin";
import TableCell from "@material-ui/core/TableCell";
import TableRow from "@material-ui/core/TableRow";
import Checkbox from "@material-ui/core/Checkbox";
import Tooltip from "@material-ui/core/Tooltip";

export const activeColor = `#0f5132`;
export const activeBGColor = `#d1e7dd`;
export const inactiveColor = `#842029`;
export const inactiveBGColor = `#f8d7da`;
export const temporaryColor = `#664d03`;
export const temporaryBGColor = `#fff3cd`;
export const scheduledColor = `#084298`;
export const scheduledBGColor = `#cfe2ff`; 

export const rowStyle = (record) => {
  const rowColor = {
    background: activeBGColor,
    color: activeColor,
  };

  if (record.is_published && record.is_modified) {
    rowColor.background = temporaryBGColor;
    rowColor.color = temporaryColor;
  } else if (!record.is_published) {
    rowColor.background = inactiveBGColor;
    rowColor.color = inactiveColor;
  }

  return { ...rowColor };
};

const CustomDataGridRow = ({
  record,
  resource,
  id,
  onToggleItem,
  children,
  selected,
  basePath,
  rowStyleProps,
  enableTooltip = false,
  tooltipMessage = ""
}) => {
  const rowStyles = rowStyleProps ? rowStyleProps(record) : rowStyle(record);
  return (
    <TableRow key={id} style={rowStyles}>
      {/* first column: selection checkbox */}
      <>
        {enableTooltip ? (
          <Tooltip
            title={tooltipMessage}
            placement="top"
            disableHoverListener={!tooltipMessage}
            disableTouchListener
            disableFocusListener
          >
            <TableCell
              padding="none"
              style={{ borderBottom: "1px solid #c6c6c6" }}
            >
              <Checkbox checked={selected} onClick={() => onToggleItem(id)} />
            </TableCell>
          </Tooltip>
        ) : (
          <TableCell
            padding="none"
            style={{ borderBottom: "1px solid #c6c6c6" }}
          >
            <Checkbox checked={selected} onClick={() => onToggleItem(id)} />
          </TableCell>
        )}
      </>

      {/* data columns based on children */}
      {children.map((field, k) => {
        if (field) {
          return (
            <>
              {enableTooltip ? (
                <Tooltip
                  title={tooltipMessage}
                  placement="top"
                  disableHoverListener={!tooltipMessage}
                  disableTouchListener
                  disableFocusListener
                  key={`tooltip-${k}`}
                >
                  <TableCell
                    key={`${id}-${field.props.source}`}
                    style={{ borderBottom: "1px solid #c6c6c6" }}
                  >
                    {React.cloneElement(field, {
                      record,
                      basePath,
                      resource,
                    })}
                  </TableCell>
                </Tooltip>
              ) : (
                <TableCell
                  key={`${id}-${field.props.source}`}
                  style={{ borderBottom: "1px solid #c6c6c6" }}
                >
                  {React.cloneElement(field, {
                    record,
                    basePath,
                    resource,
                  })}
                </TableCell>
              )}
            </>
          );
        }
        return <></>;
      })}
    </TableRow>
  );
};

const CustomDataGridBody = ({basePath,rowStyleProps,...props}) => (
  <DatagridBody
    {...props}
    row={<CustomDataGridRow rowStyleProps={rowStyleProps} />}
  />
);
const CustomDataGrid = ({basePath,rowStyleProps,...props}) => (
  <Datagrid
    {...props}
    body={<CustomDataGridBody rowStyleProps={rowStyleProps} />}
  />
);

export default CustomDataGrid;
