import React,  { useEffect } from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import AppBar from "@material-ui/core/AppBar";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Box from "@material-ui/core/Box";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`scrollable-auto-tabpanel-${index}`}
      aria-labelledby={`scrollable-auto-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          {children}
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `scrollable-auto-tab-${index}`,
    "aria-controls": `scrollable-auto-tabpanel-${index}`,
  };
}

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    width: "100%",
    backgroundColor: theme.palette.background.paper,
  },
}));

export default function ScrollableTabsButtonAuto({ children, initPositionTabs = 0, switchTab = 0 }) {
  const classes = useStyles();
  const [posTabs, setPosTabs] = React.useState(initPositionTabs);

  const handleChangePosTabs = (event, newValue) => {
    setPosTabs(newValue);
  };

  useEffect(()=>{setPosTabs(switchTab)},[switchTab])

  return (
    <div className={classes.root}>
      <AppBar position="static" color="default">
        <Tabs
          value={posTabs}
          onChange={handleChangePosTabs}
          indicatorColor="primary"
          textColor="primary"
          variant="scrollable"
          scrollButtons="auto"
          aria-label="scrollable auto tabs example"
        >
          {(children?.map(({props : {disabled = false, labelTab}}, index) => (
              <Tab disabled={disabled} label={labelTab || `Item ${index}`} key={`${index}`} {...a11yProps(index)} />
            ))) || <Tab disabled={children.props.disabled} label={`${children.props.labelTab || `Item 0`}`} {...a11yProps(0)} />}
        </Tabs>
      </AppBar>
      {(children?.map((child, index) => (
          <TabPanel value={posTabs} key={`${index}`} index={index}>
            {child}
          </TabPanel>
        ))) || (
        <TabPanel value={posTabs} index={posTabs}>
          {children}
        </TabPanel>
      )}
    </div>
  );
}
