import React from 'react';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles({
    paywall_granted_content: {
        backgroundColor: '#D3D3D3',
        borderRadius: '20px',
        margin: '10px 0px 10px 0px',
        paddingTop: '10px',
        paddingBottom: '10px',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center'
    },
    paywall_granted: {
        display: 'block'
    }
});

const PaywallGrantedSiteField = ({ record }) => {
    const classes = useStyles();
    if (record !== undefined && record.user_metadata !== undefined) {
        let sites = record.user_metadata.paywall_granted !== undefined ? Object.keys(record.user_metadata.paywall_granted) : [];
        return (
            <div>
                {sites.map((value, index) => {
                    return (
                        <div key={index} className={classes.paywall_granted_content}>
                            <span key={index} className={classes.paywall_granted}>{value + " : " + record.user_metadata.paywall_granted[value]} </span>
                        </div>

                    )
                })}
            </div>

        );
    }else {
        return "---";
    }
}

export default PaywallGrantedSiteField;
