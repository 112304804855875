import React, { useEffect, useRef } from "react";
import { useEditor, EditorContent } from "@tiptap/react";
import StarterKit from "@tiptap/starter-kit";
import TextStyle from "@tiptap/extension-text-style";
import { CustomClass } from "./CustomExtensionTiptap";
import { makeStyles } from "@material-ui/core/styles";
import FormatBoldOutlinedIcon from "@material-ui/icons/FormatBoldOutlined";
import FormatItalicOutlinedIcon from "@material-ui/icons/FormatItalicOutlined";
import StrikethroughSOutlinedIcon from "@material-ui/icons/StrikethroughSOutlined";
import FormatListBulletedOutlinedIcon from "@material-ui/icons/FormatListBulletedOutlined";
import FormatListNumberedOutlinedIcon from "@material-ui/icons/FormatListNumberedOutlined";
import UndoOutlinedIcon from "@material-ui/icons/UndoOutlined";
import RedoOutlinedIcon from "@material-ui/icons/RedoOutlined";
import { useInput } from "react-admin";

const useStyles = makeStyles({
  menu_bar: {
    display: "flex",
    alignItems: "center",
    fontSize: "20px",
    border: "none",
    padding: "1px 6px",
  },
  is_active: {
    backgroundColor: "#43A5F5",
    color: "white",
    fontSize: "20px",
    border: "none",
    padding: "1px 6px",
    height: "30.5px",
    display: "inline-flex",
    alignItems: "center",
  },
  is_not_active: {
    backgroundColor: "white",
    color: "#0D0D0D",
    fontSize: "20px",
    border: "none",
    padding: "1px 6px",
    height: "30.5px",
    display: "inline-flex",
    alignItems: "center",
    "&:hover": {
      backgroundColor: "#43A5F5",
      color: "white",
      cursor: "pointer",
    },
  },
  block_icons: {
    padding: "0px 10px",
  },
  icons: {
    fontWeight: "bold",
  },
  icons_content: {
    height: "30.5px",
  },
  custom_icon: {
    fontWeight: "bold",
    height: "30.5px",
    marginTop: "1.5px",
  },
  block_heading: {
    padding: "0px 10px",
    display: "flex",
  },
  main_editor_content: {
    padding: "20px 0px",
  },
  editor_title: {
    color: "#757575",
    padding: "0px",
    fontSize: "12px",
  },
  editor_content: {
    backgroundColor: "#F5F5F5",
    borderBottom: "1px solid black",
  },
});

const MenuBar = ({ editor }) => {
  const classes = useStyles();

  if (!editor) {
    return null;
  }

  return (
    <div className={classes.menu_bar}>
      <div className={classes.block_icons}>
        <button
          onClick={() => {
            editor.chain().focus().toggleBold().run();
            console.log(editor.isActive("bold"));
          }}
          className={
            editor.isActive("bold") ? classes.is_active : classes.is_not_active
          }
          type="button"
        >
          <FormatBoldOutlinedIcon className={classes.icons} />
        </button>
        <button
          onClick={() => editor.chain().focus().toggleItalic().run()}
          className={
            editor.isActive("italic")
              ? classes.is_active
              : classes.is_not_active
          }
          type="button"
        >
          <FormatItalicOutlinedIcon className={classes.icons} />
        </button>
        <button
          onClick={() => editor.chain().focus().toggleStrike().run()}
          className={
            editor.isActive("strike")
              ? classes.is_active
              : classes.is_not_active
          }
          type="button"
        >
          <StrikethroughSOutlinedIcon className={classes.icons} />
        </button>
      </div>

      <div className={classes.block_heading}>
        <button
          onClick={() => editor.chain().focus().setClass("custom_h1").run()}
          className={
            editor.isActive("textStyle", { class: "custom_h1" })
              ? classes.is_active
              : classes.is_not_active
          }
          type="button"
        >
          <div className={classes.icons_content}>
            <div className={classes.custom_icon}>h1</div>
          </div>
        </button>
        <button
          onClick={() => editor.chain().focus().setClass("custom_h2").run()}
          className={
            editor.isActive("textStyle", { class: "custom_h2" })
              ? classes.is_active
              : classes.is_not_active
          }
          type="button"
        >
          <div className={classes.icons_content}>
            <div className={classes.custom_icon}>h2</div>
          </div>
        </button>
        <button
          onClick={() => editor.chain().focus().setClass("custom_h3").run()}
          className={
            editor.isActive("textStyle", { class: "custom_h3" })
              ? classes.is_active
              : classes.is_not_active
          }
          type="button"
        >
          <div className={classes.icons_content}>
            <div className={classes.custom_icon}>h3</div>
          </div>
        </button>
        <button
          onClick={() => editor.chain().focus().setClass("custom_h4").run()}
          className={
            editor.isActive("textStyle", { class: "custom_h4" })
              ? classes.is_active
              : classes.is_not_active
          }
          type="button"
        >
          <div className={classes.icons_content}>
            <div className={classes.custom_icon}>h4</div>
          </div>
        </button>
      </div>
      <div className={classes.block_icons}>
        <button
          onClick={() => editor.chain().focus().toggleOrderedList().run()}
          className={
            editor.isActive("orderedList")
              ? classes.is_active
              : classes.is_not_active
          }
          type="button"
        >
          <FormatListNumberedOutlinedIcon className={classes.icons} />
        </button>
        <button
          onClick={() => editor.chain().focus().toggleBulletList().run()}
          className={
            editor.isActive("bulletList")
              ? classes.is_active
              : classes.is_not_active
          }
          type="button"
        >
          <FormatListBulletedOutlinedIcon className={classes.icons} />
        </button>
      </div>
      <div className={classes.block_icons}>
        <button
          onClick={() => editor.chain().focus().undo().run()}
          className={classes.is_not_active}
          type="button"
        >
          <UndoOutlinedIcon className={classes.icons} />
        </button>
        <button
          onClick={() => editor.chain().focus().redo().run()}
          className={classes.is_not_active}
          type="button"
        >
          <RedoOutlinedIcon className={classes.icons} />
        </button>
      </div>
    </div>
  );
};

const TextEditorV2 = ({
  input,
  source,
  count,
  compiledHtml,
  editorTitle,
  ...restProps
}) => {
  const classes = useStyles();
  const {
    input: { name, value, onChange, ...rest },
  } = useInput({ source: input.name || source });

  const editorHook = useEditor(
    {
      extensions: [StarterKit, TextStyle, CustomClass],
      content: input.value,
      // triggered on every change
      onUpdate: ({ editor }) => {
        const html = editor.getHTML();
        onChange(html);
      },
    },
    [count]
  );

  return (
    <div className={classes.main_editor_content}>
      <p className={classes.editor_title}>{editorTitle}</p>
      <MenuBar editor={editorHook} />
      <EditorContent className={classes.editor_content} editor={editorHook} />
    </div>
  );
};

export default TextEditorV2;
