import React, { useState } from "react";
import { TextInput } from "react-admin";
import { makeStyles } from "@material-ui/core/styles";
import { blue } from "@material-ui/core/colors";
import LayerManager from "./LayerManager";
import MainLayerPreview from "./MainLayerPreview";
import RegwallLayerPreview from "./RegwallLayerPreview";
import { CompactPicker } from "react-color";
import TextEditor from "./TextEditor";
import { useField, useFormState } from "react-final-form";
import { getSource } from "../tools";

const useStyles = makeStyles({
  section_title: {
    fontSize: "1.2rem",
    marginBottom: "0px",
  },
  section_title_underline: {
    width: "40px",
    height: "6px",
    backgroundColor: blue[400],
    marginBottom: "20px",
  },
  layers_manager_content: {
    display: "flex",
    marginBottom: "40px",
  },
  layers_manager_zone: {
    width: "33.3%",
    padding: "0px 20px 20px 20px",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
  },
  color_picker_zone: {
    padding: "0px 20px 20px 20px",
  },
  long_input: {
    width: "65%",
    marginRight: "10%",
  },
  hidden_input: {
    display: "none",
  },
  text_editor_content: {
    width: "65%",
  },
  color_picker_content: {
    paddingTop: "20px",
    display: "flex",
    justifyContent: "center",
  },
  title: {
    textAlign: "center",
    marginBottom: "20px",
    fontSize: "1em",
  },
});
const mainLogoPositionDefault = {
  verticalUp: {
    rowLeft: true,
    rowCenter: false,
    rowRight: false,
  },
  verticalDown: {
    rowLeft: false,
    rowCenter: false,
    rowRight: false,
  },
};

const mainButtonPositionDefault = {
  verticalUp: {
    rowLeft: null,
    rowCenter: false,
    rowRight: false,
  },
  verticalCenter: {
    rowLeft: true,
    rowCenter: false,
    rowRight: false,
  },
  verticalDown: {
    rowLeft: false,
    rowCenter: false,
    rowRight: false,
  },
};
const mainBackgroundColorDefault = "#FFFFFF";
const mainButtonBackgroundColorDefault = "#808080";

const MainLayerManager = (props) => {
  const classes = useStyles();
  const { source = "", field = "" } = props;
  const { values: formdata } = useFormState();
  const formdataSource = field ? getSource(formdata,field) : formdata;

  const {
    input: { value: mainLogoPosition, onChange: onChangeMainLogoPosition },
  } = useField(
    `${source}main_logo_position`,
    {
      defaultValue: mainLogoPositionDefault,
    }
  );
  const {
    input: { value: mainButtonPosition, onChange: onChangeMainButtonPosition },
  } = useField(
    `${source}main_button_position`,
    {
      defaultValue: mainButtonPositionDefault,
    }
  );
  const {
    input: {
      value: mainBackgroundColor,
      onChange: onChangeMainBackgroundColor,
    },
  } = useField(
    `${source}main_background_color`,
    field !== "overrides" && 
    {
      defaultValue: mainBackgroundColorDefault,
    }
  );
  const {
    input: {
      value: mainButtonBackgroundColor,
      onChange: onChangeMainButtonBackgroundColor,
    },
  } = useField(
    `${source}main_button_background_color`,
    field !== "overrides" && 
    {
      defaultValue: mainButtonBackgroundColorDefault,
    }
  );
  const {
    input: { value: textBeforeButton, onChange: onChangeTextBeforeButton },
  } = useField(
    `${source}textBeforeButton`,
    field !== "overrides" && {
      defaultValue: "",
    }
  );
  const {
    input: { value: textAfterButton, onChange: onChangeTextAfterButton },
  } = useField(
    `${source}textAfterButton`,
    field !== "overrides" && {
      defaultValue: "",
    }
  );

  const [mainLogoPositionPreview, setMainLogoPositionPreview] = useState(
    positionForPreview(mainLogoPosition)
  );
  const [mainButtonPositionPreview, setMainButtonPositionPreview] = useState(
    positionForPreview(mainButtonPosition)
  );

  function positionForPreview(elementPosition) {
    for (const [vKey, vValue] of Object.entries(elementPosition)) {
      for (const [rKey, rValue] of Object.entries(vValue)) {
        if (rValue) {
          return {
            vertical: vKey,
            row: rKey,
          };
        }
      }
    }
  }

  function handleChangeMainBackgroundColor(color) {
    onChangeMainBackgroundColor(color.hex);
  }
  function handleChangeMainButtonBackgroundColor(color) {
    onChangeMainButtonBackgroundColor(color.hex);
  }

  function handleMainPosition(element, verticalPosition, rowPosition) {
    let newLogoPosition = { ...mainLogoPosition };
    let newButtonPosition = { ...mainButtonPosition };

    if (element === "logo") {
      for (const [vKey, vValue] of Object.entries(newLogoPosition)) {
        for (const [rKey, rValue] of Object.entries(vValue)) {
          if (vKey === verticalPosition) {
            if (rKey === rowPosition) {
              newLogoPosition[vKey][rowPosition] = true;
              newButtonPosition[vKey][rowPosition] = null;
            } else if (newLogoPosition[vKey][rKey] && rKey !== rowPosition) {
              newLogoPosition[vKey][rKey] = false;
              if (newButtonPosition[vKey][rKey] === null) {
                newButtonPosition[vKey][rKey] = false;
              }
            }
          } else {
            if (newLogoPosition[vKey][rKey]) {
              newLogoPosition[vKey][rKey] = false;
            }
            if (newButtonPosition[vKey][rKey] === null) {
              newButtonPosition[vKey][rKey] = false;
            }
          }
        }
      }
    }
    if (element === "button") {
      for (const [vKey, vValue] of Object.entries(newButtonPosition)) {
        for (const [rKey, rValue] of Object.entries(vValue)) {
          if (vKey === verticalPosition) {
            if (rKey === rowPosition) {
              newButtonPosition[vKey][rowPosition] = true;
              if (vKey in newLogoPosition) {
                newLogoPosition[vKey][rowPosition] = null;
              }
            } else if (newButtonPosition[vKey][rKey] && rKey !== rowPosition) {
              newButtonPosition[vKey][rKey] = false;
              if (vKey in newLogoPosition) {
                if (newLogoPosition[vKey][rKey] === null) {
                  newLogoPosition[vKey][rKey] = false;
                }
              }
            }
          } else {
            if (newButtonPosition[vKey][rKey]) {
              newButtonPosition[vKey][rKey] = false;
            }
            if (vKey in newLogoPosition) {
              if (newLogoPosition[vKey][rKey] === null) {
                newLogoPosition[vKey][rKey] = false;
              }
            }
          }
        }
      }
    }

    onChangeMainLogoPosition(newLogoPosition);
    setMainLogoPositionPreview(positionForPreview(newLogoPosition));

    onChangeMainButtonPosition(newButtonPosition);
    setMainButtonPositionPreview(positionForPreview(newButtonPosition));
  }

  return (
    <div>
      <div className={classes.text_editor_content}>
        <TextEditor
          editorTitle="Text before button"
          textContent={textBeforeButton}
          handleText={onChangeTextBeforeButton}
        />
      </div>

      { formdataSource?.wall_type !== "regwall" ? (
        <TextInput
          helperText="ex: Access now"
          className={classes.long_input}
          source={`${source}button_text`}
        />
      ) : null}

      <div className={classes.text_editor_content}>
        <TextEditor
          editorTitle="Text after button"
          textContent={textAfterButton}
          handleText={onChangeTextAfterButton}
        />
      </div>
      <p className={classes.section_title}>Main Layer customization</p>

      { formdataSource?.wall_type !== "regwall" ? (
        <div className={classes.layers_manager_content}>
          <div className={classes.layers_manager_zone}>
            <LayerManager
              layer="main"
              element="logo"
              elementPosition={mainLogoPosition}
              handlePosition={handleMainPosition}
            />
          </div>
          <div className={classes.layers_manager_zone}>
            <LayerManager
              layer="main"
              element="button"
              elementPosition={mainButtonPosition}
              handlePosition={handleMainPosition}
            />
          </div>
          <div className={classes.layers_manager_zone}>
            <div className={classes.color_picker_zone}>
              <p className={classes.title}>Choose background color</p>
              <div className={classes.color_picker_content}>
                <CompactPicker
                  color={mainBackgroundColor}
                  onChangeComplete={handleChangeMainBackgroundColor}
                />
              </div>
            </div>
            <div className={classes.color_picker_zone}>
              <p className={classes.title}>Choose button background color</p>
              <div className={classes.color_picker_content}>
                <CompactPicker
                  color={mainButtonBackgroundColor}
                  onChangeComplete={handleChangeMainButtonBackgroundColor}
                />
              </div>
            </div>
          </div>
        </div>
      ) : (
        <div className={classes.layers_manager_content}>
          <div className={classes.layers_manager_zone}>
            <LayerManager
              layer="main"
              element="logo"
              elementPosition={mainLogoPosition}
              handlePosition={handleMainPosition}
            />
          </div>
          <div className={classes.layers_manager_zone}>
            <div className={classes.color_picker_zone}>
              <p className={classes.title}>Choose background color</p>
              <div className={classes.color_picker_content}>
                <CompactPicker
                  color={mainBackgroundColor}
                  onChangeComplete={handleChangeMainBackgroundColor}
                />
              </div>
            </div>
          </div>
        </div>
      )}

      <div>
        <TextInput
          className={classes.hidden_input}
          source={`${source}main_logo_position`}
        />
        <TextInput
          className={classes.hidden_input}
          source={`${source}main_button_position`}
        />
        <TextInput
          className={classes.hidden_input}
          source={`${source}main_background_color`}
        />
        <TextInput
          className={classes.hidden_input}
          source={`${source}main_button_background_color`}
        />
        <TextInput
          className={classes.hidden_input}
          source={`${source}textBeforeButton`}
        />
        <TextInput
          className={classes.hidden_input}
          source={`${source}textAfterButton`}
        />
      </div>

      <p className={classes.section_title}>Main Layer preview</p>

      { formdataSource?.wall_type !== "regwall" ? (
        <MainLayerPreview
          formdataSource={formdataSource}
          logoPositionPreview={mainLogoPositionPreview}
          buttonPositionPreview={mainButtonPositionPreview}
          backgroundColor={mainBackgroundColor}
          buttonBackgroundColor={mainButtonBackgroundColor}
        />
      ) : (
        <RegwallLayerPreview
          formdataSource={formdataSource}
          logoPositionPreview={mainLogoPositionPreview}
          buttonPositionPreview={mainButtonPositionPreview}
          backgroundColor={mainBackgroundColor}
          buttonBackgroundColor={mainButtonBackgroundColor}
        />
      )}
    </div>
  );
};

export default MainLayerManager;
