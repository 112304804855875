import React, { useState } from 'react';
import {
  DateInput,
  useRecordContext
} from 'react-admin';
import { makeStyles } from '@material-ui/core/styles';
import DeleteOutlineIcon from '@material-ui/icons/DeleteOutline';
import {red } from '@material-ui/core/colors';


const styles = {
    paywall_granted_input: {
        marginRight: '30px',
    },
    edit_zone: {
        display: 'flex',
        alignItems: 'center',
    },
    edit_zone_title: {
        fontSize: '16px',
    },
    deletePaywallGrantedButton: {
        borderRadius: '50%',
        backgroundColor: red[300],
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        width: '30px',
        height: '30px',
        marginLeft: '20px',
        marginBottom: '20px',
        cursor: 'pointer'
    }
};

const ProductsGrantedEdit = (props)=>{
    const useStyles = makeStyles(styles);
    const classes = useStyles();
    const record = useRecordContext(props);

    let products = record.user_metadata !== undefined && record.user_metadata.products_granted !== undefined ? Object.entries(record.user_metadata.products_granted) : []
    const [productsArray, setProductsArray] = useState(products);
    const deleteGracePeriod = (journal, offerCodeValue) => {
        const filteredProductsArray = productsArray.filter(function(product){
            if (offerCodeValue in product[1]) {
                delete product[1][offerCodeValue];
            }
          return Object.keys(product[1]).length !== 0;
      });
      setProductsArray(filteredProductsArray);
      delete record.user_metadata.products_granted[journal][offerCodeValue];
    }

    if (record.user_metadata !== undefined && record.user_metadata.products_granted !== undefined) {
        return (
            <div >
                {   productsArray.length !== 0 &&
                    <p className={classes.edit_zone_title}>Edit products grace period</p>
                }
                {
                    productsArray.map((product, productsIndex) => {
                        let offerCodes = record.user_metadata.products_granted[product[0]] !== undefined ? Object.keys(record.user_metadata.products_granted[product[0]]) : [];
                        return (
                            <div key={productsIndex}>
                                <p >Journal code: {product[0]}</p>
                                {
                                    offerCodes.map((offerCodeValue, offerCodeIndex) => {
                                        let dynamicDateSource = "user_metadata.products_granted." + product[0] + "." + offerCodeValue;
                                        return (
                                            <div key={offerCodeIndex}>
                                                {
                                                    offerCodeValue in productsArray[productsIndex][1] &&
                                                    <div  className={classes.edit_zone}>
                                                        <DateInput  label={offerCodeValue}

                                                                    source={dynamicDateSource}
                                                                    className= {classes.paywall_granted_input}
                                                        />
                                                        <div className={classes.deletePaywallGrantedButton}
                                                             onClick={()=> {deleteGracePeriod(product[0],offerCodeValue)}}
                                                        >
                                                            <DeleteOutlineIcon style={{color: 'white', fontSize: '20px'}}/>
                                                        </div>
                                                    </div>
                                                }
                                            </div>

                                        )
                                    })
                                }
                            </div>
                        )
                    })
                }
            </div>

        );
    }else {
        return null;
    }
}

export default ProductsGrantedEdit
