import { TextField as MUITextField } from "@material-ui/core";
import CircularProgress from "@material-ui/core/CircularProgress";
import React, { useCallback, useEffect, useState } from "react";
import { Labeled, showNotification as showNotificationAction } from "react-admin";
import { connect } from "react-redux";
import { useFetch } from "../async";
import useDebounce from "../hooks/useDebounce";

const TextFieldControlled = ({
  label = "",
  record,
  resource = "",
  defaultValue = "",
  updateDataResource = "",
  debounceDelay = 2000,
  showNotification,
  basePath,
  ...props
}) => {
  const [valueInput, setValue] = useState(
    defaultValue || record[updateDataResource] || ""
  );
  const [isLoading, setIsLoading] = useState(false); //when key down
  const [isInit, setIsInit] = useState(true); //when key down
  const debouncedInput = useDebounce(valueInput, debounceDelay);

  const handleChange = useCallback((e) => {
    setValue(e.target.value);
  });
  const updateDocument = useCallback(
    () => {
      setIsLoading(true);

      useFetch(
        `/api/admin/${resource}/${record.id}`,
        {
          method: "put",
          body: JSON.stringify({ ...record, [updateDataResource]: valueInput }),
          resource,
        },
        showNotification
      )
        .then((res) => {
          setIsLoading(false);
        })
        .catch((error) => {
          console.error(error);
          setIsLoading(false);
        });
    },
    [valueInput],
  )
  
  useEffect(() => {
    let isCancelled = false;
    if (!isCancelled && updateDataResource && !isInit) {
      updateDocument()
    }

    return () => (isCancelled = true);
  }, [debouncedInput]);

  useEffect(() => {
    setIsInit(false);
  },[]);
  return (
    <>
      {isLoading ? (
        <CircularProgress
          // className={classes.circularProgress}
          size={30}
          thickness={2}
        />
      ) : (
        <Labeled label={label}>
          <MUITextField value={valueInput} onChange={handleChange} {...props} />
        </Labeled>
      )}
    </>
  );
};

TextFieldControlled.propTypes = {};

export default connect(null, { showNotification: showNotificationAction })(
  TextFieldControlled
);
