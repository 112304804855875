import React from 'react';

const AuthWithWrongAccessRights = () => {
    const styles = {
        pendingContent: {
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            width: '100vw',
            height: '100vh'
        }
    }
    return (
        <div style={styles.pendingContent}>
            <div>You do not appear to have the required access rights...</div>
        </div>
    );
};

export default AuthWithWrongAccessRights;
