import React from "react";
import {
  Create,
  useNotify,
  useRefresh,
  useRedirect,
} from "react-admin";
import EditForm from "./EditForm";

const SiteConfigsCreate = (props) => {
  const notify = useNotify();
  const refresh = useRefresh();
  const redirect = useRedirect();

  const onSuccess = ({ data }) => {
    notify(`New Site configs "${data.name}" created`,"success");
    redirect("/sites-configs");
    refresh();
  };
  const onFailure = (error) => {
    notify(`Could not create Site configs: ${error.message}`,"error");
    redirect("/sites-configs");
    refresh();
  };

  const validateSiteConfigCreation = (values) => {
    const errors = {};
    const requiredFields = ['site', /* 'logo', 'logo_small', */ 'domain'];

    requiredFields.forEach(field => {
        if (!values[field]) {
            errors[field] = `The ${field.replace('_', ' ')} is required`;
        }
    });

    if (values.landing_products) {
      try {
        const json = JSON.parse(values.landing_products);
      } catch (e) {
        errors.landing_products =
          "The format is not valid, please check the example provided";
      }
    }
    if (values.enableHandshakeRedirect === true) {
        if (!values.estore_handshake_endpoint) {
            errors.estore_handshake_endpoint = "The Estore handshake endpoint is required as  the 'enable hanshake redirect' button is checked"
        }
    }
    return errors
};
    return (
    <Create onSuccess={onSuccess} onFailure={onFailure} {...props}>
      <EditForm
        redirect="list"
        validateSiteConfig={validateSiteConfigCreation}
        origin={"create"}
      ></EditForm>
    </Create>
  );
};

export default SiteConfigsCreate;
