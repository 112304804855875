import React from 'react';
import {
  downloadCSV,
} from 'react-admin';
import jsonExport from 'jsonexport/dist';

const UserExporter = users => {
    const usersForExport = users.map(user => {
        const { created_at,
                email_verified,
                identities,
                bluebird_entitlements,
                name,
                nickname,
                picture,
                updated_at,
                user_id,
                user_metadata,
                last_login,
                last_ip,
                logins_count,
                id,
                last_password_reset,
                ...userForExport
               } = user; // omit
        return userForExport;
    });
    jsonExport(usersForExport, {
        // headers: ['id', 'title', 'author_name', 'body'] // order fields in the export
    }, (err, csv) => {
        downloadCSV(csv, 'kiosq-users'); // download as 'users.csv` file
    });
};

export default UserExporter;
