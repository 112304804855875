import React, { useEffect, useRef } from 'react';
import { useAuth0 } from "@auth0/auth0-react";
import CircularProgress from '@material-ui/core/CircularProgress';


const ButtonToGoToLogin = () => {
    const styles = {
        pendingContent: {
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            width: '100vw',
            height: '100vh'
        },
        loadingContent: {
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            width: '100vw',
            height: '100vh'
        },
        loadingText: {
            fontSize: '20px'
        },
        progress: {
            margin: '50px',
            color: 'red'
        }
    }
    const {
        isAuthenticated,
        loginWithRedirect,
    } = useAuth0();
    const myRefname= useRef();

    // Similar to componentDidMount and componentDidUpdate:
    useEffect(() => {
        if (!isAuthenticated) {
            myRefname.current.click();
        }
    });
    return(
        <div style={styles.pendingContent}>
            <button style={{visibility:'hidden'}}ref={myRefname} onClick={loginWithRedirect}>Log in</button>
            <div style={styles.loadingContent}>
                <p style={styles.loadingText}>Redirect to login...</p>

                <div>
                    <CircularProgress style={styles.progress} />
                </div>
            </div>
        </div>
    )
};

export default ButtonToGoToLogin;
