import React from 'react';
import {
  SimpleFormIterator,
  ArrayInput
} from 'react-admin';
import { useFormState } from 'react-final-form';

const ArrayInputContent = (props) =>{
    function isDisableAdd(values){
        if (values !== undefined) {
            return values.length >= 1
        }else {
            return false
        }
    }
    const { values } = useFormState()
    const disableAddSource = props.isDisableAdd ?? isDisableAdd(values[props.arrayInputSource]);
    return (
        <div className="array-input-wrapper">
            <ArrayInput source={props.arrayInputSource} label={props.label}>
                <SimpleFormIterator classes={{form:props?.classes?.SimpleFormIterator}} disableAdd={disableAddSource}>
                    {props.children}
                </SimpleFormIterator>
            </ArrayInput>
        </div>
    )
}

export default ArrayInputContent;
