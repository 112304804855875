import { Dialog, Drawer } from "@material-ui/core";
import LowPriority from "@material-ui/icons/LowPriority";
import React, { Fragment, useCallback, useEffect, useState } from "react";
import {
    CreateButton,
    Datagrid,
    EditButton,
    List,
    TextField,
    TopToolbar,
} from "react-admin";
import { Route } from "react-router";
import IconButton from "../../components/IconButton";
import SettingsCreate from "./Create";
import SettingsEdit from "./Edit";
import ListFilter from "./ListFilter";
// import CopyToProd from "../../components/CopyToProd";
import { makeStyles } from "@material-ui/core/styles";
import { connect } from "react-redux";
import ChangelogField from "../../components/ChangelogField";
import DeleteButtonWithConfirmation from "../../components/DeleteButtonWithConfirmation";

const styles = {
  drawerContent: {
    width: 1600,
  },
  paper: {
    textAlign: "center",
  },
};

const useStyles = makeStyles(styles);

const SettingListActions = ({
  filters,
  resource,
  showFilter,
  displayedFilters,
  filterValues,
  basePath,
}) => (
  <TopToolbar>
    {filters &&
      React.cloneElement(filters, {
        resource,
        showFilter,
        displayedFilters,
        filterValues,
        context: "button",
      })}
    <CreateButton basePath={basePath} />
  </TopToolbar>
);

const ChangeLogButton = ({ record, onClick }) => (
  <IconButton
    icon={(iconClasses) => <LowPriority className={iconClasses.leftIcon} />}
    onClick={() => onClick(record)}
    label={"Changelogs"}
    color="primary"
  >
    ChangeLog
  </IconButton>
);

const SettingList = ({
  history,
  location: { pathname },
  settingsList = [],
  resource,
  dispatch,
  ...rest
}) => {
  const classes = useStyles();
  const [currentRecord, setCurrentRecord] = useState(null);

  const handleClose = useCallback(() => {
    history.push("/settings");
  }, []);
  const setRecordRoute = useCallback(
    (record) => {
      setCurrentRecord(record);
    },
    []
  );

    const handleGetLog = useCallback((record) => {
        history.replace({
        pathname: `/settings/log/${record.id}`,
        state: { record: record },
      });
    setRecordRoute(record);
  }, []);

  useEffect(() => {
    // current record doesn't exist in route if reloading location pathname
    if (settingsList.length > 0) {
      const record = settingsList.filter(
        (setting) => setting.guid === pathname.split("/").splice(-1)[0]
      )[0];
      record && setRecordRoute(record);
    }
  }, [settingsList]);

  return (
    <Fragment>
      <List
        perPage={100}
        resource={resource}
        filters={<ListFilter />}
        sort={{ field: "name", order: "ASC" }}
        actions={<SettingListActions />}
        {...rest}
      >
        <Datagrid>
          <TextField source="guid" />
          <TextField source="setting" />
          <EditButton />
          <DeleteButtonWithConfirmation />
          <ChangeLogButton source="guid" onClick={handleGetLog} />
        </Datagrid>
      </List>
      <Route path="/settings/create">
        {({ match }) => (
          <Dialog
            maxWidth="md"
            fullWidth={true}
            open={!!match}
            onClose={handleClose}
            aria-labelledby="form-dialog-title"
          >
            <SettingsCreate
              resource={resource}
              onCancel={handleClose}
              className={classes.paper}
              {...rest}
            />
          </Dialog>
        )}
      </Route>
      <Route path="/settings/log/:id">
        {({ match }) => {
          const isMatch =
            (match && match.params && match.params.id !== "create") || false;
          return (
            <Dialog
              maxWidth={false}
              fullWidth={true}
              open={isMatch}
              onClose={handleClose}
              aria-labelledby="form-dialog-title"
            >
              <ChangelogField
                guid={isMatch ? match.params.id : null}
                record={currentRecord}
                resource={resource}
                onCancel={handleClose}
                {...rest}
              />
            </Dialog>
          );
        }}
      </Route>
      <Route path="/settings/:id">
        {({ match }) => {
          const isMatch =
            (match &&
              match.params &&
              match.params.id !== "create" &&
              match.isExact) ||
            false;

          return (
            <Drawer open={isMatch} onClose={handleClose}>
              {isMatch ? (
                <SettingsEdit
                  resource={resource}
                  className={classes.drawerContent}
                  id={isMatch ? match.params.id : null}
                  onCancel={handleClose}
                  {...rest}
                />
              ) : (
                <div className={classes.drawerContent} />
              )}
            </Drawer>
          );
        }}
      </Route>
    </Fragment>
  );
};

/* class SettingList extends Component {
    constructor(props) {
        super(props);
        this.handleClose = this.handleClose.bind(this);
        this.handleGetLog = this.handleGetLog.bind(this);
        this.setRecordRoute = this.setRecordRoute.bind(this);
        this.state = { currentRecord: null };
    }

    handleClose = () => {
        this.props.history.push("/settings");
    };

    setRecordRoute = (record) => {
        const {
            history: { replace },
        } = this.props;
        replace({ pathname: `/settings/log/${record.id}`, state: { record: record } });
        this.setState({ currentRecord: record });
    };

    handleGetLog = (record) => {
        this.setRecordRoute(record);
    };

    componentDidUpdate(prevProps) {
        const {
            location: { pathname },
            settingsList = [],
        } = this.props;
        // current record doesn't exist in route if reloading location pathname
        if (settingsList.length > 0 && settingsList.length !== prevProps.settingsList.length) {
            const record = settingsList.filter((setting) => setting.id === pathname.split("/").splice(-1)[0])[0];
            record && this.setRecordRoute(record);
        }
    }

    render() {
        const { resource, classes, settingsList, dispatch, ...rest } = this.props;
        return (
            <Fragment>
                <List
                    perPage={100}
                    resource={resource}
                    filters={<ListFilter />}
                    sort={{ field: "name", order: "ASC" }}
                    actions={<SettingListActions />}
                    {...rest}
                >
                    <Datagrid>
                        <TextField source="guid" />
                        <TextField source="kiosq-setting" />
                        <EditButton />
                        <DeleteButtonWithConfirmation />
                        <ChangeLogButton source="guid" onClick={this.handleGetLog} />
                    </Datagrid>
                </List>
                <Route path="/settings/create">
                    {({ match }) => (
                        <Dialog
                            maxWidth="md"
                            fullWidth={true}
                            open={!!match}
                            onClose={this.handleClose}
                            aria-labelledby="form-dialog-title"
                        >
                            <SettingsCreate
                                resource={resource}
                                onCancel={this.handleClose}
                                className={classes.paper}
                                {...rest}
                            />
                        </Dialog>
                    )}
                </Route>
                <Route path="/settings/log/:id">
                    {({ match }) => {
                        const isMatch = (match && match.params && match.params.id !== "create") || false;
                        return (
                            <Dialog
                                maxWidth={false}
                                fullWidth={true}
                                open={isMatch}
                                onClose={this.handleClose}
                                aria-labelledby="form-dialog-title"
                            >
                                <ChangelogField
                                    guid={isMatch ? match.params.id : null}
                                    record={this.state.currentRecord}
                                    resource={resource}
                                    onCancel={this.handleClose}
                                    {...rest}
                                />
                            </Dialog>
                        );
                    }}
                </Route>
                <Route path="/settings/:id">
                    {({ match }) => {
                        const isMatch =
                            (match && match.params && match.params.id !== "create" && match.isExact) || false;

                        return (
                            <Drawer open={isMatch} onClose={this.handleClose}>
                                {isMatch ? (
                                    <SettingsEdit
                                        resource={resource}
                                        className={classes.drawerContent}
                                        id={isMatch ? match.params.id : null}
                                        onCancel={this.handleClose}
                                        {...rest}
                                    />
                                ) : (
                                    <div className={classes.drawerContent} />
                                )}
                            </Drawer>
                        );
                    }}
                </Route>
            </Fragment>
        );
    }
} */

const mapStateToProps = (state) => {
  const data = state.admin.resources.settings.data;
  const mystate = Object.keys(data).reduce((arr, key) => {
    arr.push(data[key]);
    return arr;
  }, []);
  return {
    settingsList: mystate,
  };
};

export default connect(mapStateToProps)(SettingList);
