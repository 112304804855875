import React, { Component, useEffect } from "react";
import { Field } from "react-final-form";
import {
  TextInput,
  NumberInput,
  Labeled,
  SelectInput,
  FormDataConsumer,
} from "react-admin";
import { getSource, orderByKey } from "../tools";
import AddIcon from "@material-ui/icons/Add";
import IconButton from "./IconButton";
import InputFile from "./InputFile";
import LinkListCard from "./Card/Card";
import Grid from "./Grid";

export const negativeNumber = (value) =>
  value && value < 0 ? "This number must be >= 0" : undefined;
export const number = (value) =>
  value && !Number.isInteger(value) ? "Must be a number" : undefined;
export const minValue = (min) => (value) =>
  value && value < min ? `Must be at least ${min}` : undefined;
export const minValue0 = minValue(0);

const LinkListInput = ({ source, label, additionnalFields, ...rest }) => {
  const { fields, addLabel, hideAddButton } = rest;

  return (
    <FormDataConsumer>
      {({ formData }) => (
        <>
          {fields?.value?.length > 0 &&
            fields
              .map((itemSource, index) => {
                return (
                  <LinkListCard
                    key={`link-list-${itemSource}`}
                    index={index}
                    name={itemSource}
                    source={itemSource}
                    labelPrefix={label + " " + (index + 1)}
                    type={label}
                    {...rest}
                  >
                    {additionnalFields &&
                      additionnalFields.map((component) => component)}
                  </LinkListCard>
                );
              })
              .sort((a, b) => {
                const sourceA = getSource(formData, a.props.source);
                const sourceB = getSource(formData, b.props.source);
                return orderByKey(sourceA, sourceB, "position");
              })}
          {hideAddButton === false && (
            <IconButton
              color="primary"
              icon={(classes) => <AddIcon className={classes.leftIcon} />}
              onClick={() => fields.push({})}
            >
              {addLabel}
            </IconButton>
          )}
        </>
      )}
    </FormDataConsumer>
  );
};

LinkListInput.defaultProps = {
  label: (item) => (item?.label ? item.label : "Link list"),
  getTitle: (item) => (item?.title ? item.title : ""),
  showEmptyKeys: ["title", "position", "url"],
  addLabel: (item) => (item?.addLabel ? item.addLabel : "Add link"),
  removeLabel: (item) => (item?.removeLabel ? item.removeLabel : "Remove link"),
  initiallyExpanded: (record) => !record.title && !record.url,
  extendedTitle: null,
  hideAddButton: false,
  inputFields: [
    <TextInput source="title" label="Title" resettable />,
    <NumberInput
      source="position"
      label="Position"
      validate={[number, minValue0]}
      warn={negativeNumber}
    />,
    <TextInput source="url" label="URL" resettable />,
    <TextInput source="description" label="Description" resettable />,
    <SelectInput
      source="target"
      label="Target"
      defaultValue={"_self"}
      choices={[
        { id: "_blank", name: "Blank" },
        { id: "_self", name: "Self (default)" },
        { id: "_parent", name: "Parent" },
        { id: "_top", name: "Top" },
        { id: "framename", name: "Framename" },
      ]}
      resettable
    />,
    <Field multiple={false} name="icon" label="Icon" component={InputFile} />,
  ],
};

export default LinkListInput;
