import * as _ from "lodash";
import { regex } from "react-admin";
import { activeBGColor, activeColor, inactiveBGColor, inactiveColor, scheduledBGColor, scheduledColor } from "./components/CustomDataGrid";

RegExp.escape = function (string) {
  return string.toString().replace(/[-/\\^$*+?.()|[\]{}]/g, "\\$&");
};

function capitalizeFirstLetter(string) {
  return string.charAt(0).toUpperCase() + string.slice(1);
}

// ex:
// arrayToComplete=[{id:"google",name:"http://www.google.com"}]
// arrayToFilter=[{id:"google",name:"http://www.google123.com"},{id:"facebook",name:"http://www.facebook.com"},{id:"twitter",name:"http://www.twitter.com"}]
// id="id"
// =>[{id:"google",name:"http://www.google.com"},{id:"facebook",name:"http://www.facebook.com"},{id:"twitter",name:"http://www.twitter.com"}]
const ConcatTwoArrayofObjectbyKey = (
  arrayToComplete = [],
  arrayToFilter = [],
  keyToFilter
) => {
  if (arrayToFilter && arrayToFilter.length > 0) {
    const arrayFiltered = arrayToComplete.reduce((acc, itemFromDb) => {
      return acc.filter((itemPreload) => {
        return (
          itemPreload[keyToFilter].toLowerCase() !==
          itemFromDb[keyToFilter].toLowerCase()
        );
      });
    }, arrayToFilter);
    arrayFiltered.map((item) => {
      return arrayToComplete.push(item);
    });
    return arrayToComplete;
  }
  return arrayToComplete;
};
/** fillFieldArrayWithCompareRefs */
const fillArrayFieldIfMissingRefs = (fields, refs, formData) => {
  const compareKeys = fields.map((field) => {
    const source = getSource(formData, field) ? getSource(formData, field) : {};
    return source.key;
  });
  if (refs && Array.isArray(refs)) {
    refs.forEach((ref) => {
      if (!compareKeys.includes(ref.key)) {
        fields.push(ref);
      }
    });
  }
};
/** getSource */
const _isIndexTab = (value) => {
  let regexIndexArr = /\[([0-9].*)\]$/;
  let match = value.match(regexIndexArr);
  return match;
};

const diffArraySetting = (arrayToFilter, arrayToCompare, key = "id") => {
  return arrayToFilter.filter(
    (i) => !arrayToCompare.some((o) => i.id === o[key])
  );
};

const diffArraySettingDefaultVal = (arrayToFilter, arrayToCompare) => {
  return arrayToCompare.map((item) => {
    let defaultVal = arrayToFilter.find((el) => el.id === item.id);
    if (!item["items"] && defaultVal && defaultVal["items"]) {
      defaultVal.items = [defaultVal["items"]];
      return defaultVal;
    }
    return item;
  });
};

const getSource = (record = {}, source = "") => {
  return source.split(".").reduce((acc, value) => {
    const reg = _isIndexTab(value);
    if (acc) {
      if (acc[value]) {
        return acc[value];
      }

      if (reg) {
        let accKey = value.replace(reg[0], "");
        let accSubKey = parseInt(reg[1], 10);

        if (reg && acc[accKey] && acc[accKey][accSubKey]) {
          //if value is index of array and value exist in record
          return acc[accKey][accSubKey];
        }
      }
      return undefined;
    }
    return undefined;
  }, record);
};

/** order ASC by */
const orderByKey = (a, b, k1, k2) => {
  // k1 key to sort and k2 is parent key of k1
  // ex: sort position in the same area
  if (a && b && k1) {
    //ex: don't sort by position if area is not the same
    if (k2 && a[k2] !== b[k2]) {
      return 0;
    }
    // 100000 : push null value in the bottom of array (need to order 0 value)
    var o1 = typeof a[k1] !== "undefined" && a[k1] !== null ? a[k1] : 100000;
    var o2 = typeof b[k1] !== "undefined" && b[k1] !== null ? b[k1] : 100000;

    if (o1 < o2) return -1;
    if (o1 > o2) return 1;

    return 0;
  }
  return 0;
};

function lastKeyOfSource(words) {
  var n = words.split(".");
  return n[n.length - 1];
}

const isValidJson = (str) => {
  str = typeof str !== "string" ? JSON.stringify(str) : str;

  try {
    str = JSON.parse(str);
  } catch (e) {
    return false;
  }

  if (typeof str === "object" && str !== null) {
    return true;
  }

  return false;
};

/****** Start Validation *******/
const required = (value) => (value ? undefined : "Required");
const negativeNumber = (value) =>
  value && value < 0 ? "This number must be >= 0" : undefined;
const number = (value) =>
  value && !Number.isInteger(value) ? "Must be a number" : undefined;
const minValue = (min) => (value) =>
  value && value < min ? `Must be at least ${min}` : undefined;
const maxValue = (max) => (value) =>
  value && value > max ? `Must be lower or equal ${max}` : undefined;
const minValue0 = minValue(0);
const maxValue100 = maxValue(100);
const isListNumberWithCommaSeparator = regex(
  /^(\d+)((,\d+)*)(,\d)*$/,
  "Must be a valid integer list separated by comma"
);
const RAValidateUrlFormat = regex(
  /^(?:http(s)?:\/\/)?[\w.-]+(?:\.[\w.-]+)+[\w\-._~:/?#[\]@!$&'()*+,;=.]+|^\/.*$/,
  "Must be a valid URL format"
); //absolute URL https://  or relative URL /*
const RAValidateCodeHttp = regex(/\b[0-9]{3}\b/, "Must be a valid Http Code");
const isInvalidCodeHttp = (value) => {
  if (Array.isArray(value)) {
    const isValidArray = value.map((i) => {
      return /^\d{3}$/.test(i);
    });
    return isValidArray.includes(false);
  } else {
    return value ? !/^\d{3}$/.test(value) : false;
  }
};
const RACheckIfJsonIsValid = (str) => {
  if (str && /^[[|{].*[}|\]]$/.test(str)) {
    return isValidJson(str) ? undefined : "Invalid Json!";
  }
  return false;
};

const checkIfSvgHasTitle = (element) => {
  if (typeof element === "object") {
    let values = element && Object.values(element)[0];

    if (
      values &&
      typeof values !== "undefined" &&
      values.hasOwnProperty("mimeType") &&
      values.mimeType.indexOf("svg")
    ) {
      if (
        values.hasOwnProperty("fileContent") &&
        values.fileContent.indexOf("<title") > -1
      ) {
        return false;
      } else {
        return "SVG logo needs a title tag";
      }
    }
  }
  //return false if no issues are found
  return false;
};

/****** End Validation *******/

const isDuplicateKeyInArr = (arr = [], key = "id") => {
  const valuesKey = arr.map((item) => item[key]);
  return valuesKey.some((item, id) => valuesKey.indexOf(item) !== id);
};

const findDuplicateKeyInArrayObj = (arr = [], key = "id") => {
  return arr.reduce((acc, value, index) => {
    const duplicates = arr.filter(
      (o, i) => o[key] === value[key] && index !== i
    );
    duplicates.length > 0 && (acc[index] = { [key]: `Duplicated ${key}` });
    return acc;
  }, []);
};

function setAttributes(el, attrs) {
  for (var key in attrs) {
    el.setAttribute(key, attrs[key]);
  }
}

function flattenObject(object, path, separator = ".") {
  return Object.keys(object).reduce((acc, key) => {
    const value = object[key];
    const newPath = Array.isArray(object)
      ? `${path ? path : ""}[${key}]`
      : [path, key].filter(Boolean).join(separator);
    const isObject = [
      typeof value === "object",
      value !== null,
      !(value instanceof Date),
      !(value instanceof RegExp),
      !(Array.isArray(value) && value.length === 0),
    ].every(Boolean);

    return isObject
      ? { ...acc, ...flattenObject(value, newPath, separator) }
      : { ...acc, [newPath]: value };
  }, {});
}

Object.filter = (obj, predicate) =>
  Object.keys(obj)
    .filter((key) => predicate(obj[key]))
    .reduce((res, key) => Object.assign(res, { [key]: obj[key] }), {});

/** deep compare Array of Objects */
var isArrayEqual = function (x, y) {
  return _(x).xorWith(y, _.isEqual).isEmpty();
};

var isObject = (o) => {
  if (typeof o === "object" && !Array.isArray(o) && o !== null) {
    return true;
  }
  return false;
};

var updateDeepValue = (rec, path, v) => {
  return _.update(rec, path, v);
};

function convertToCamelCase(inputString) {
  return inputString
    .replace(/[-_]+(.)/g, function (match, firstChar) {
      return firstChar ? " " + firstChar.toUpperCase() : "";
    })
    .replace(/^./, function (firstChar) {
      return firstChar.toUpperCase();
    });
}

const getStatusDate = (startDateTime, endDateTime) => {
  const currentDate = new Date().getTime();

  // active
  if ((currentDate >= startDateTime && currentDate <= endDateTime)||(currentDate >= startDateTime && endDateTime === null)) {
    return {code:1,label:'Active',style:{color:activeColor,backgroundColor:activeBGColor}};
  }
  // outdated
  else if (currentDate > endDateTime) {
    return {code:0,label:'Inactive',style:{color:inactiveColor,backgroundColor:inactiveBGColor}};
  }
  // scheduled
  else if (currentDate < startDateTime) {
    return {code:2,label:'Scheduled',style:{color:scheduledColor,backgroundColor:scheduledBGColor}};
  }
};

export {
  ConcatTwoArrayofObjectbyKey,
  RACheckIfJsonIsValid,
  RAValidateCodeHttp,
  RAValidateUrlFormat,
  capitalizeFirstLetter,
  checkIfSvgHasTitle,
  convertToCamelCase,
  diffArraySetting,
  diffArraySettingDefaultVal,
  fillArrayFieldIfMissingRefs,
  findDuplicateKeyInArrayObj,
  flattenObject,
  getSource,
  isArrayEqual,
  isDuplicateKeyInArr,
  isInvalidCodeHttp,
  isListNumberWithCommaSeparator,
  isObject,
  isValidJson,
  lastKeyOfSource,
  maxValue,
  maxValue100,
  minValue,
  minValue0,
  negativeNumber,
  number,
  orderByKey,
  required,
  setAttributes,
  updateDeepValue,
  getStatusDate
};
