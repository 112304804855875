import React, { useEffect } from "react";
import LoadingPage from "./LoadingPage";
import ButtonToGoToLogin from "./components/ButtonToGoToLogin";
import AuthError from "./components/AuthError";
import AuthWithWrongAccessRights from "./components/AuthWithWrongAccessRights";
import AdminApp from "./components/AdminApp";


const Auth = (props) => {
  const styles = {
    pendingContent: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      width: "100vw",
      height: "100vh",
    },
  };

  const { isLoading, isAuthenticated, error, user, getAccessTokenSilently } =
    props.auth0Payload;

  useEffect(() => {
    localStorage.setItem("user", user?.email);

    getAccessTokenSilently().then((token) => {
      localStorage.setItem("token", token);
    });
  },[user, getAccessTokenSilently]);

  return (
    <>
      {isLoading && <LoadingPage />}
      {error && <AuthError error={error} />}
      {isAuthenticated &&
        !user.roles.includes("Admin") &&
        !user.roles.includes("SuperAdmin") && <AuthWithWrongAccessRights />}
      {isAuthenticated &&
      (user.roles.includes("Admin") || user.roles.includes("SuperAdmin")) ? (
        <AdminApp isAuthenticated={isAuthenticated} user={user} />
      ) : !isLoading && !error && !isAuthenticated ? (
        <ButtonToGoToLogin />
      ) : <></>}
    </>
  );
};

export default Auth;
