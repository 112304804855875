import { makeStyles } from "@material-ui/core/styles";
import React, { useEffect, useState } from "react";
import {
  EditButton,
  Labeled,
  List,
  TextField,
  useAuthProvider,
  FunctionField,
} from "react-admin";
import TextFieldControlled from "../../components/TextFieldControlled";
import TootltipControlled from "../../components/TooltipControlled";
import ListFilters from "./ListFilters";
import CustomDataGrid, {
  inactiveBGColor,
  inactiveColor,
  activeBGColor,
  activeColor,
  scheduledBGColor,
  scheduledColor,
} from "../../components/CustomDataGrid";
import ListActions from "./ListActions";
import { getDatesTimeFromRulesQuery } from "../../components/QueryBuilder";
import { getStatusDate, buildSourceKey } from "../../tools";

export const getStatusColor = (status) => {
  switch (status) {
    case 1:
      return { background: activeBGColor, color: activeColor }
      break;
    case 2:
      return { background: scheduledBGColor, color: scheduledColor }
      break;
    default:
      return { background: inactiveBGColor, color: inactiveColor };
  }
};

export const rowStyleCampaign = (record) => {
  let rowColor = {};

  const { startDateTime, endDateTime } = getDatesTimeFromRulesQuery(
    record?.campaign_query_builder?.query_object?.rules
  );
  const status = getStatusDate(startDateTime, endDateTime)
  rowColor = status.style;

  return rowColor;
};

const useStyles = makeStyles({
  logo_content: {
    "& img": {
      maxWidth: "100px",
    },
  },
});

const CustomLabel = () => (
  <span>
    Name (
    <span
      style={{
        color: inactiveColor,
        backgroundColor: inactiveBGColor,
        padding: "2px 5px",
      }}
    >
      Inactive
    </span>{" "}
    /{" "}
    <span
      style={{
        color: activeColor,
        backgroundColor: activeBGColor,
        padding: "2px 5px",
      }}
    >
      Active
    </span>{" "}
    /{" "}
    <span
      style={{
        color: scheduledColor,
        backgroundColor: scheduledBGColor,
        padding: "2px 5px",
      }}
    >
      Schedule
    </span>
    )
  </span>
);
const CampaignsList = (props) => {
  const classes = useStyles();
  const authProvider = useAuthProvider();
  const [permissions, setPermissions] = useState();
  useEffect(async () => {
    authProvider
      .getPermissions()
      .then((permissions) => setPermissions(permissions));
  }, []);
  return (
    <List
      perPage={25}
      filters={<ListFilters />}
      // filterDefaultValues={{ site_config_overrided: "fourfourtwo_en-gb" }}
      actions={<ListActions />}
      sort={{ field: "priority", order: "DESC" }}
      {...props}
    >
      <CustomDataGrid rowStyleProps={rowStyleCampaign}>
        <FunctionField
          source="name"
          label={<CustomLabel />}
          render={(record) => (
            <TextField
              record={record}
              source="name"
              style={{ color: rowStyleCampaign(record).color }}
              label={<CustomLabel />}
            />
          )}
        />
        <FunctionField
          source="site_config_overrided"
          render={(record) => (
            <TextField
              record={record}
              source="site_config_overrided"
              label="Site"
              style={{ color: rowStyleCampaign(record).color }}
            />
          )}
        />
        <TextFieldControlled
          label="Segment Score Priority"
          defaultValue={0}
          helperText={"Higher first"}
          name="priority"
          updateDataResource={`priority`}
          isValueTypeNumber
        />
        <FunctionField
          label="Start Date"
          render={(record) => <TextField
            record={record}
            source={buildSourceKey(record,'campaign_query_builder.query_object.rules','campaign_start_date')}
          />
          }
        />
        <FunctionField
          label="End Date"
          style={{width:'100%'}}
          render={(record) => <TextField
            record={record}
            source={buildSourceKey(record,'campaign_query_builder.query_object.rules','campaign_end_date')}
          />
          }
        />
        <TextField
          source="campaign_query_builder.query_format"
          label={`Query`}
        />
        <EditButton />
        {permissions && permissions.includes("SuperAdmin") && (
          <Labeled label={"Pythia Ids"}>
            <TootltipControlled
              fieldsToRender={[
                { field: "site_config_overrided", label: "Profile ID" },
                { field: "guid", label: "Rule ID" },
              ]}
            />
          </Labeled>
        )}
      </CustomDataGrid>
    </List>
  );
};

export default CampaignsList;
