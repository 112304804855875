import React, { useState } from 'react';
import {
  DateInput,
  useRecordContext
} from 'react-admin';
import { makeStyles } from '@material-ui/core/styles';
import DeleteOutlineIcon from '@material-ui/icons/DeleteOutline';
import {red } from '@material-ui/core/colors';
import { useForm } from 'react-final-form';


const styles = {
    userMetadataTarget_input: {
        marginRight: '30px',
    },
    edit_zone: {
        display: 'flex',
        alignItems: 'center',
    },
    edit_zone_title: {
        fontSize: '16px',
    },
    deletePaywallGrantedButton: {
        borderRadius: '50%',
        backgroundColor: red[300],
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        width: '30px',
        height: '30px',
        marginLeft: '20px',
        marginBottom: '20px',
        cursor: 'pointer'
    }
};

const PaywallEdit = (props)=>{
    const useStyles = makeStyles(styles);
    const classes = useStyles();
    const record = useRecordContext(props)
    const { change } = useForm();

    let sites = record.user_metadata !== undefined && record.user_metadata[props.userMetadataTarget] !== undefined ? Object.keys(record.user_metadata[props.userMetadataTarget]) : []

    const [sitesArray, setSitesArray] = useState(sites);
    const deleteGracePeriod = (value) => {
        const filteredSitesArray = sitesArray.filter(function(site){
          return site !== value;
        });
        setSitesArray(filteredSitesArray);
        delete record.user_metadata[props.userMetadataTarget][value];
        change('user_metadata.' + props.userMetadataTarget , record.user_metadata[props.userMetadataTarget]);
    }

    if (record.user_metadata !== undefined && record.user_metadata[props.userMetadataTarget] !== undefined) {
        return (
            <div >
                {   sitesArray.length !== 0 &&
                    <p className={classes.edit_zone_title}>Edit sites {props.userMetadataTarget === 'paywall_trial'? "trial" : "grace"} period</p>
                }
                {
                    sitesArray.map((value, index) => {
                        let dynamicDateSource = "user_metadata." + props.userMetadataTarget + "." + value;
                        return (
                            <div key={index}>
                                {sitesArray[index] === value && <div  className={classes.edit_zone}>
                                    <DateInput  label={value}

                                                source={dynamicDateSource}
                                                className= {classes.userMetadataTarget_input}
                                    />
                                <div className={classes.deletePaywallGrantedButton}
                                     onClick={()=> {deleteGracePeriod(value)}}
                                >
                                        <DeleteOutlineIcon style={{color: 'white', fontSize: '20px'}}/>
                                    </div>
                                </div>}
                            </div>

                        )
                    })
                }
            </div>

        );
    }else {
        return null;
    }
}

export default PaywallEdit
