import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { BooleanInput, DateTimeInput } from "react-admin";
import { useField } from "react-final-form";

const useStyles = makeStyles({
  scheduled_maintenance_content: {
    display: "flex",
  },
  scheduled_maintenance_input: {
    marginRight: "10%",
  },
});

const ScheduledMaintenanceForm = ({ field = "", source = "" }) => {
  const classes = useStyles();
  const {
    input: { value: isScheduledMaintenance },
  } = useField(`${source}scheduled_maintenance`);

  return (
    <div className={classes.scheduled_maintenance_content}>
      <BooleanInput
        helperText="To activate scheduled maintenance"
        label="Scheduled maintenance"
        source={`${source}scheduled_maintenance`}
        className={classes.scheduled_maintenance_input}
      />
      <DateTimeInput
        label="Start date"
        source={`${source}scheduled_maintenance_start_date`}
        className={classes.scheduled_maintenance_input}
        disabled={!isScheduledMaintenance}
        {...(field !== "overrides" && {defaultValue:null})}
      />
      <DateTimeInput
        label="End date"
        source={`${source}scheduled_maintenance_end_date`}
        disabled={!isScheduledMaintenance}
        {...(field !== "overrides" && {defaultValue:null})}
      />
    </div>
  );
};

export default ScheduledMaintenanceForm;
