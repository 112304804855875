import React from 'react';
import {Edit} from 'react-admin';
import EditForm from './EditForm';

const CustomTemplateEdit = props => (
    <Edit
           {...props}
    >
        <EditForm />
    </Edit>
);

export default CustomTemplateEdit;
