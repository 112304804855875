import React from 'react';

const AuthError = (props) => {
    const styles = {
        pendingContent: {
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            width: '100vw',
            height: '100vh'
        }
    }
    return (
        <div style={styles.pendingContent}>
            <div>Oops... {props.error.message}</div>
        </div>
    );
};

export default AuthError;
