import Icon from '@material-ui/icons/Person';
import List from './List';
import Edit from './Edit';
import Show from './Show';

const name = 'users';

export {
    List,
    Edit,
    Show,
    name,
    Icon,
};
