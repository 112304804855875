import Button from "@material-ui/core/Button";
import clsx from "clsx";
import React, { useCallback, useEffect, useState } from "react";
import {
  AutocompleteInput,
  DateInput,
  NumberInput,
  ReferenceInput,
  TextInput,
  TextField,
  Labeled,
} from "react-admin";
import { useField, useFormState } from "react-final-form";
import Grid from "../../components/Grid";
import { useStyles as EditFormStyles } from "./EditForm";
import useCampaignsOf from "../../hooks/useCampaigns";
import { updateQuery } from "../../components/QueryBuilder";
export const required = (value) => (value ? undefined : "Required");
const negativeNumber = (value) =>
  value && value < 0 ? "This number must be >= 0" : undefined;
const number = (value) =>
  value && !Number.isInteger(value) ? "Must be a number" : undefined;
const minValue = (min) => (value) =>
  value && value < min ? `Must be at least ${min}` : undefined;
const minValue0 = minValue(0);

const optionRenderer = (choice) => `${choice?.name} (${choice?.guid})`;

const limitRenderSuggestions = (val) => {
  return val.trim().length > 0;
};

const GeneralInfosCampaign = ({
  permissions,
  origin,
  setSiteConfigOverridedID,
}) => {
  const {
    values: { site_config_overrided, campaign_query_builder },
  } = useFormState();

  const {
    input: { value: queryValue, onChange: changeQuery },
  } = useField(`campaign_query_builder.query_object`);

  const {
    input: { value: querySegmentScoreValue },
  } = useField(`campaign_query_builder.query_segment_score`, {
    initialValue: 1,
  });

  const {
    input: { value : siteConfigGuid},
  } = useField(`site_config_overrided`);

  const {
    input: { value : currentCampaignGuid},
  } = useField(`guid`);

  const { listCampaigns, isNotUniquePriority = "" } = useCampaignsOf(siteConfigGuid,currentCampaignGuid);

  const [query, setQuery] = useState(queryValue);
  const classes = EditFormStyles();

  const handleChangeStartDate = (e) => {
    let newQuery = updateQuery(
      campaign_query_builder?.query_object,
      e.target.value,
      "campaign_start_date",
      ">="
    );
    setQuery(newQuery);
  };

  const handleChangeEndDate = (e) => {
    let newQuery = updateQuery(
      campaign_query_builder?.query_object,
      e.target.value,
      "campaign_end_date",
      "<="
    );
    setQuery(newQuery);
  };

  let wrapperCampaignGlobalConfigClass = clsx({
    [classes.group_column]: true,
  });

  useEffect(() => {
    changeQuery(query);
  }, [query,changeQuery]);

  return (
    <div className={wrapperCampaignGlobalConfigClass}>
      {origin === "edit" && (
        <>
          {permissions && permissions.includes("SuperAdmin") && (
            <Grid columns={2}>
              <Labeled label={"Profile ID (Pythia)"}>
                <TextField source={`site_config_overrided`} fullWidth />
              </Labeled>
              <Labeled label={"Rule ID (Pythia)"}>
                <TextField source={`guid`} fullWidth />
              </Labeled>
            </Grid>
          )}
          <TextInput
            className={classes.input_marge}
            source={`id`}
            label="Campaign ID"
            disabled
          />
        </>
      )}
      <Grid columns={2}>
        <TextInput
          helperText="ex: New Event campaign"
          source={`name`}
          label="Campaign Name"
          validate={[required]}
          fullWidth
          resettable
        />
        <NumberInput
          helperText="Priority by segment score"
          source={`priority`}
          format={(val)=> parseInt(val,10)}
          defaultValue={parseInt(querySegmentScoreValue,10)}
          label="Segment Score priority"
          validate={[required, number, minValue0]}
          warn={[negativeNumber]}
        />
      </Grid>
      <TextInput
        source={`description`}
        label="Campaign Description"
        fullWidth
        resettable
      />
      <Grid columns={2}>
        <ReferenceInput
          resource={""}
          label="Site Config Overrided"
          source={`site_config_overrided`}
          perPage={100}
          reference="sites-configs"
          sort={{ field: "guid", order: "ASC" }}
          filterToQuery={(text) => ({ name: text })}
        >
          <AutocompleteInput
            onSelect={(record) => {
              setSiteConfigOverridedID(record?.site);
            }}
            helperText="Select an existing site config to override"
            options={{
              fullWidth: true,
            }}
            validate={required}
            shouldRenderSuggestions={limitRenderSuggestions}
            optionText={optionRenderer}
            optionValue="guid"
            clearAlwaysVisible
          ></AutocompleteInput>
        </ReferenceInput>
        <Button
          variant="contained"
          color={"primary"}
          href={`#/sites-configs/${site_config_overrided}`}
          target="_blank"
          className={classes.button}
          disabled={!site_config_overrided}
        >
          Edit Site Config
        </Button>
      </Grid>
      <Grid columns={2}>
        <DateInput
          label="Start date"
          source={`campaign_start_date`}
          onChange={handleChangeStartDate}
          validate={required}
        />
        <DateInput
          label="End date"
          source={`campaign_end_date`}
          onChange={handleChangeEndDate}
          validate={required}
        />
      </Grid>
    </div>
  );
};

export default GeneralInfosCampaign;
