import { Extension } from '@tiptap/react';

/**
 * FontSize - Custom Extension
 * editor.commands.setFontSize(e.target.value) :set the font-size.
 */


 export const CustomClass = Extension.create({
    name: 'customClass',
    addOptions() {
        return {
            types: ['textStyle'],
        };
    },
    addGlobalAttributes() {
        const hOneStyle = 'font-size:28px;font-weight:bold;margin-top:0px';
        const hTwoStyle = 'font-size:18px;font-weight:bold;margin-bottom:10px;line-height:24px';
        const hThreeStyle = 'font-size:16px;font-weight:bold;margin-bottom:10px;line-height:24px';
        const hFourStyle = 'font-size:100%;font-weight:bold';
        return [
            {
                types: this.options.types,
                attributes: {
                    class: {
                        default: null,
                        // parseHTML: element => element.style.fontSize.replace(/['"]+/g, ''),
                        renderHTML: attributes => {
                            if (!attributes) {
                                return {};
                            }
                            return {
                                class: `${attributes.class}`,
                                style: attributes.class === 'custom_h1' ? hOneStyle :
                                        attributes.class === 'custom_h2' ? hTwoStyle :
                                        attributes.class === 'custom_h3' ? hThreeStyle :
                                        attributes.class === 'custom_h4' ? hFourStyle : ''
                            };
                        },
                    },
                },
            },
        ];
    },
    addCommands() {
        return {
            setClass: myClass => ({ chain }) => {
                return chain()
                    .toggleMark('textStyle', { class: myClass })
                    .run();
            },
            unsetClass: () => ({ chain }) => {
                return chain()
                    .toggleMark('textStyle', { class: null })
                    .removeEmptyTextStyle()
                    .run();
            },
        };
    },
});
