import React from 'react';
import { Create } from "react-admin";
import EditForm from './EditForm';

const SettingsCreate = ({ classes,onCancel,...props }) => (
    <Create title=" " {...props}>
        <EditForm permissions={props.permissions} onCancel={onCancel}  />
    </Create>
);

export default SettingsCreate;
