import React from "react";
import {
  TextInput,
  BooleanInput,
  AutocompleteArrayInput,
  ReferenceArrayInput,
  SelectInput,
  NumberInput,
} from "react-admin";
import TrafficExposureForm from "../../components/TrafficExposureForm";
import FormDataContent from "../../components/FormDataContent";
import ScheduledMaintenanceForm from "../../components/ScheduledMaintenanceForm";
import { useStyles as useStylesEditForm } from "./EditForm";
import { useFormState } from "react-final-form";
import RichTexteditor from "../../components/RichTextEditor";
import ArrayInputContent from "../../components/ArrayInputContent";
import clsx from "clsx";

const GeneralInfosForm = ({ field = "" }) => {
  const source = field ? `${field}.` : "";
  const { values: formdata } = useFormState();
  const formdataSource = field ? formdata[field] : formdata;
  const classes = useStylesEditForm();
  /* let simpleFormClass = clsx({
    [classes.flexDirectionRowEvenly]: true,
  });
  let simpleFormInputClass = clsx({
    [classes.simpleFormInputRow]: true,
    [classes.paddingRight10px]: true,
  }); */

  return (
    <>
      <FormDataContent dataContentTitle={"General infos"}>
        <>
          <div className={classes.flexDirectionRow}>
            <TextInput
              helperText="ex: cyclingnews_en-us"
              disabled={origin === "edit" || false}
              className={classes.flexInput}
              source={`${source}site`}
              label="Site"
            />
            <TextInput
              helperText="ex: Homebuilding & Renovating"
              className={classes.flexInput}
              source={`${source}name`}
              label="Name"
            />
            <SelectInput
              source="local"
              choices={[
                { id: "us", name: "US" },
                { id: "gb", name: "GB" },
                { id: "za", name: "ZA" },
                { id: "au", name: "AU" },
                { id: "ca", name: "CA" },
                { id: "nz", name: "NZ" },
                { id: "in", name: "IN" },
                { id: "my", name: "MY" },
                { id: "ae", name: "AE" },
                { id: "bh", name: "BH" },
                { id: "kw", name: "KW" },
                { id: "om", name: "OM" },
                { id: "qa", name: "QA" },
                { id: "sa", name: "SA" },
                { id: "dk", name: "DK" },
                { id: "fi", name: "FI" },
                { id: "no", name: "NO" },
                { id: "se", name: "SE" },
                { id: "it", name: "IT" },
                { id: "be", name: "BE" },
                { id: "de", name: "DE" },
                { id: "fr", name: "FR" },
                { id: "es", name: "ES" },
                { id: "eg", name: "EG" },
              ]}
              label="Locale"
              className={classes.input_marge}
            />
          </div>
          <TextInput helperText="ex: https://cdn.mos.cms.futurecdn.net/flexiimages/jtld3qrazv1613402249.svg" className={classes.long_input} source={`${source}logo`} required={true} />
          <TextInput helperText="ex: https://cdn.mos.cms.futurecdn.net/flexiimages/m7awu8serc1613169967.svg" className={classes.long_input} source={`${source}logo_small`} required={true}/>
          <div className={classes.group_input}>
            <TextInput
              helperText="ex: https://www.cyclingnews.com"
              className={classes.long_input}
              label="Domain"
              source={`${source}domain`}
            />
          </div>
          <TextInput
            helperText='To put a Kiosq button in discover brand landing page. ex: {"/decanter":"E001000", "/golf-monthly":"E003000"}'
            className={classes.long_input}
            label="Landing products"
            source={`${source}landing_products`}
          />
          <BooleanInput
            helperText="Does this site use trailing slash"
            label="Trailing slash"
            source={`${source}trailingSlash`}
          />
        </>
      </FormDataContent>
      <FormDataContent dataContentTitle={"Handshake redirection infos"}>
        <>
            <div>
                <p>
                    This configuration allows you to define a dedicated endpoint to redirect users based on specific
                    scenarios:
                </p>
                <ul>
                    <li>It is used for the <strong>‘Pay or Consent’</strong> feature, ensuring users are redirected to
                        make a choice before accessing content.
                    </li>
                    <li>For the <strong>‘Pay or Consent’</strong> feature, the forced redirection option must be
                        hardcoded into the login button to ensure smooth functionality.
                    </li>
                    <li>It can also apply universally, redirecting users who attempt to log in from an article page
                        without no active subscription.
                    </li>
                </ul>
            </div>
            <TextInput
                helperText="ex: https://subscribe.homebuilding.co.uk/jwt/paywall.php"
                className={classes.long_input}
                label="Estore handshake endpoint"
                source={`${source}estore_handshake_endpoint`}
            />
            <TextInput
                helperText="Only used if the buy estore is Arcade"
                className={classes.input_marge}
                source={`${source}productID`}
                label="productID"
            />
            <BooleanInput
                helperText="Currently available for MD and Arcade only. It will redirect the user to the provider page after login if no valid subscription is found"
              label="Enable Estore handshake redirection after login on article pages"
              source={`${source}enableHandshakeRedirect`}
          />
        </>
      </FormDataContent>
      <FormDataContent dataContentTitle={"Paywall config"}>
        <>
          <div className={classes.flexDirectionRow}>
            <SelectInput
              className={classes.flexInput}
              source={`${source}wall_system`}
              label="Wall system"
              choices={[
                { id: "classic", name: "Classic" },
                { id: "metered", name: "Metered" },
              ]}
              helperText="Default value: 'Classic'"
              {...(field !== "overrides" && { defaultValue: "classic" })}
            />
            <SelectInput
              className={classes.flexInput}
              source={`${source}wall_type`}
              choices={[
                { id: "paywall", name: "Paywall" },
                { id: "regwall", name: "Regwall" },
                {
                  id: "regwallAuthentication",
                  name: "Regwall + Authentication",
                },
                ...(formdataSource?.wall_system === "metered"
                  ? [{ id: "regwallAuthPaywall", name: "Regwall + Paywall" }]
                  : []),
              ]}
              helperText="Default value: 'Paywall'"
              label="Wall type"
              // className={classes.regwall_input}
              {...(field !== "overrides" && { defaultValue: "paywall" })}
            />
          </div>
          <div className={classes.flexDirectionRow}>
            <NumberInput
              helperText="default: 96[px] (Number of pixel from the top of the article body to see before showing the content barrier on desktop)"
              className={classes.flexInput}
              label="Limit of content Desktop"
              source={`${source}limitOfContent`}
            />
            <NumberInput
              helperText="default: 96[px] (Number of pixel from the top of the article body to see before showing the content barrier on mobile)"
              className={classes.flexInput}
              label="Limit of content Mobile"
              source={`${source}limitOfContentMobile`}
            />
          </div>
          {formdataSource?.wall_system === "metered" && (
            <div className={classes.flexDirectionRow}>
              <NumberInput
                helperText="default: 3 (Number of articles that can be viewed before the paywall appears)"
                className={classes.flexInput}
                label="Limit of free articles"
                source={`${source}limitOfFreeArticle`}
              />
              <NumberInput
                helperText="default: 0 (Trigger metered message from 'X' articles seen) Tap '-1' to deactivate the message"
                className={classes.flexInput}
                label="Trigger metered message from"
                source={`${source}triggerMessageFrom`}
              />
            </div>
          )}
          <div className={classes.flexDirectionColumn}>
            {formdataSource?.wall_type === "regwallAuthPaywall" && (
              <NumberInput
                helperText="default: 3 (Number of free articles added after user Authentication)"
                className={classes.flexInput}
                label="Limit of free articles added after login"
                source={`${source}limitOfFreeArticleAfterLogin`}
              />
            )}
          </div>
        </>
      </FormDataContent>
        <FormDataContent dataContentTitle={"Vanilla/Connect config"}>

            <div>
                <p>
                    Enable dynamically the Vanilla/Connect configuration for a site (Authentication feature) and update styling for Sign-in/Sign-up button
                </p>
            </div>
            <ArrayInputContent
                arrayInputSource={`connect_config`}
                label={`Connect Config`}
            >

                <BooleanInput
                    helperText="To enable this connect config"
                    label="Enable"
                    source={`enable`}
                />
                <BooleanInput
                    helperText="To use specific CSS on Signin Button and Sign in dropdown menu"
                    label="Inline CSS"
                    source={`inlineCss`}
                />
                {formdataSource?.connect_config?.length > 0 && formdataSource?.connect_config[0]?.inlineCss && (
                    <TextInput
                        multiline
                        minRows="10"
                        className={classes.long_input}
                        source={`${source}signinButtonSpecificCss`}
                        label="Sign in button specific CSS "
                    />
                )}

                <TextInput
                    helperText="ex: addEvent"
                    label="Auth menu type"
                    source={`authMenuType`}
                />
                <TextInput
                    helperText="ex: /auth_callback. Note: Need a slash at the end for trailing slash sites"
                    label="Callback URL"
                    source={`callbackUrl`}
                />
                <TextInput
                    helperText="ex: auth-in-nav-header"
                    label="Auth Menu Insert Target"
                    source={`authMenuInsertTarget`}
                />
                <ArrayInputContent
                    arrayInputSource={`connect_config[0].auth0LockConfig`}
                    label={`Auth0 login page config`}
                >
                    <TextInput
                        helperText="An SVG Logo for the header part"
                        label="Logo url"
                        source={`logo_url`}
                        className={classes.long_input}
                    />
                    <TextInput
                        helperText="A PNG Logo for the email reveive when user subscribe"
                        label="Logo email"
                        source={`logo_email`}
                        className={classes.long_input}
                    />
                    <TextInput
                        helperText="ex: #371B51. For the button background"
                        label="Color primary"
                        source={`color_primary`}
                    />
                    <TextInput
                        helperText="ex: .auth0-lock-header-welcome {margin: 15px 0 5px;} "
                        label="Extra CSS"
                        source={`extra_css`}
                        className={classes.long_input}
                    />
                    <TextInput
                        helperText="ex: Cyclingnews"
                        label="Site name"
                        source={`site_name`}
                    />
                    <TextInput
                        helperText="Check box text on Signup screen"
                        label="Signup newsletter text"
                        source={`sign_up_newsletter_text`}
                        className={classes.long_input}
                    />
                    <TextInput
                        helperText="Check box text on Signup screen"
                        label="Contact other brand text"
                        source={`contact_other_brand_text`}
                        className={classes.long_input}
                    />
                    <TextInput
                        helperText="ex: XCN-X"
                        label="Newsletter code"
                        source={`newsletter_code`}
                    />
                </ArrayInputContent>
            </ArrayInputContent>
        </FormDataContent>
        <FormDataContent dataContentTitle={"Traffic Exposure"}>
            <TrafficExposureForm field={field} source={source}/>
        </FormDataContent>
        <FormDataContent dataContentTitle={"Schedulded maintenance"}>
            <ScheduledMaintenanceForm field={field} source={source}/>
        </FormDataContent>
        <FormDataContent dataContentTitle={"Grant on sites"}>
            <ReferenceArrayInput
                label="Sites"
                source={`${source}grant_on_sites`}
                reference="sites-configs"
            >
                <AutocompleteArrayInput optionText="site"/>
            </ReferenceArrayInput>
        </FormDataContent>
        <FormDataContent dataContentTitle={"My account"}>
            <TextInput helperText="" className={classes.long_input} source="product_cover" label="Product Cover"/>
            <TextInput helperText="" className={classes.long_input} source="title_cover" label="Title Cover"/>
            <RichTexteditor source={'description_cover'} label={'Description cover'} />
        <div className={classes.flexDirectionRow}>
          <TextInput
            helperText="ex: https://www.facebook.com/cyclingnews/"
            className={classes.flexInput}
            source={`${source}facebook_link`}
            label="Facebook link"
          />
          <TextInput
            helperText="ex: https://twitter.com/Cyclingnewsfeed"
            className={classes.flexInput}
            source={`${source}twitter_link`}
            label="Twitter link"
          />
          <TextInput
            helperText="ex: https://www.instagram.com/cyclingnews_feed/"
            className={classes.flexInput}
            source={`${source}instagram_link`}
            label="Instagram link"
          />
          <TextInput
            helperText="ex: https://www.youtube.com/c/cyclingnewsmag"
            className={classes.flexInput}
            source={`${source}youtube_link`}
            label="Youtube link"
          />
        </div>
        <BooleanInput
          helperText="Display a carousel of magazines on My Account page"
          label="Enable Recirculation block"
          source={`${source}recirculation_block`}
        />
        <BooleanInput
          helperText="Display the account linking input"
          label="Enable the account/subscription linking"
          source={`${source}link_subscription`}
        />
        <BooleanInput
          helperText="Dispaly the widget 'Best subscription offer for this website'"
          label="Enable subscription offer for current website"
          source={`${source}current_website_subscription_offer`}
        />
      </FormDataContent>
    </>
  );
};

export default GeneralInfosForm;
