import React, { Component, Fragment } from "react";
import { withStyles } from "@material-ui/core/styles";
import {
  Select,
  InputLabel,
  Button,
} from "@material-ui/core";

const styles = (theme) => ({
    boxWrapper: {
      position: "absolute",
      padding: 15,
      border: "1px solid #333",
      backgroundColor: "#FFF",
      zIndex: 1,
      marginLeft: -200,
      width: 350,
    },
    input: {
      marginTop: 5,
      marginBottom: 15,
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
    },
    box: {
      display: "block",
      '& > *': {
        width: "100%",
      },
    },
    clone_parameters: {
        marginTop:"10px",
    },
    button: {
      width: "50%",
      backgroundColor: "#4051B6",
      display: "block",
      margin: "auto",
      "&:hover": {
        backgroundColor: "#4051B6",
        opacity: 0.8,
      },
      "&:disabled": {
        backgroundColor: "grey",
        opacity: 0.8,
      },
      color: "#fff",
    },
  });

class CloneSiteConfig extends Component {
    constructor(props) {
        super(props);
        this.state = {
          newGuidValue: "",
          showInput:this.props.showInput || false,
          newGuidValueIsValid: false,
        };

        this.handleChange = this.handleChange.bind(this);
      }

      handleChange = (e) => {
        const guidFormatRegex = /^(\w{2,})(\_\w{2}\-\w{2})$/;

        this.setState({
          showInput: true,
          newGuidValue: e.target.value,
          newGuidValueIsValid: e.target.value.search(guidFormatRegex) === 0
        });

      };

      render() {
        const {
            showInput,
            newGuidValue,
            newGuidValueIsValid,
          } = this.state;

          const {record,classes,cbHandleClone} = this.props;

          return (
            <Fragment>
            {showInput && <div
                id={`${record.id}-clone-site-config`}
                className={classes.boxWrapper}
              >
                <div className={classes.input}>
                  <InputLabel htmlFor={`${record.id}-clone-site-config-site-param`}>
                    New site config Id
                  </InputLabel>

                  <input type="text"
                         name="inputSite"
                         value={newGuidValue}
                         placeholder={`e.g: ${record.id}`}
                         onChange={this.handleChange}
                         className={classes.clone_parameters}
                  />

                </div>
                <Button
                  onClick={()=>cbHandleClone(record.guid,newGuidValue)}
                  className={classes.button}
                  disabled={!newGuidValueIsValid}
                >
                  Let's clone
                </Button>
              </div>
            }
            </Fragment>
          );
  }
}

export default withStyles(styles)(CloneSiteConfig);
