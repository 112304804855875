import React from 'react';
import {
  PasswordInput,
  regex
} from 'react-admin';
import { makeStyles } from '@material-ui/core/styles';
import { useFormState } from 'react-final-form';



const styles = {
    password_input: {
        marginRight: '30px',
    },
    edit_zone_title: {
        fontSize: '16px',
    },
};

const useStyles = makeStyles(styles);





const PasswordsInputs = () => {
    const { values } = useFormState();
    const classes = useStyles();
    const validateSecurity = regex(/^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.{8,})/, 'The password must contain at least 1 uppercase letter, 1 lowercase letter, 1 number and 8 characters');
    const validatePasswordAreTheSame = (value, allValues) => {
        if (allValues.new_password !== allValues.confirm_password) {
            return 'Passwords are not the same';
        }
        return undefined;
    };
    return (
        <div>
            <p className={classes.edit_zone_title}>Change password</p>
            <PasswordInput className= {classes.password_input} label='New password' source="new_password" validate={validateSecurity} />
            <PasswordInput label='Confirm password' source="confirm_password" validate={validatePasswordAreTheSame} />
        </div>

    );
};

export default PasswordsInputs;
