import { blue } from '@material-ui/core/colors';
import { makeStyles } from '@material-ui/core/styles';
import React, { useRef, useEffect } from 'react';
import Typography from '@material-ui/core/Typography';

const useStyles = makeStyles({
    section_title: {
        fontSize: '1.2rem',
        marginBottom: '0px'
    },
    section_title_underline: {
        width: '40px',
        height:'6px',
        backgroundColor: blue[400],
        marginBottom: '20px'

    },
    info_content: {
        padding: '20px',
        border: '1px solid #E8E8E8',
        marginBottom:'50px'
    }
});

const FormDataContent = (props)=>{
    const classes = useStyles();
    const myRef = useRef(null);

    useEffect(() => {
        if (myRef && location.href.includes(`${props.dataContentTitle.toLowerCase().replace(' ','-')}`)) {
            myRef.current.scrollIntoView({
                behavior: 'smooth',
                block: 'start',
                inline: 'center',
            })
        }
    }, [])

    return (
        <>
            <Typography 
                ref={myRef} 
                id={`${props.dataContentTitle.toLowerCase().replaceAll(' ','-')}`} 
                className={classes.section_title}
                variant="h6"
                component="h2"
            >
                {props.dataContentTitle}
            </Typography>
            <div className={classes.section_title_underline}></div>
            <div className={classes.info_content}>
                {props.children}
            </div>
        </>
    );
};

export default FormDataContent;
