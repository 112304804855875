import React, { Component } from "react";
import IconButton from "../IconButton";
// import ContentCopy from "@material-ui/icons/ContentCopy";
import FileCopy from '@material-ui/icons/FileCopyOutlined'
import CircularProgress from "@material-ui/core/CircularProgress";
import { withStyles } from "@material-ui/core/styles";
import { showNotification as showNotificationAction } from "react-admin";
import { connect } from "react-redux";
import { useFetch } from "../../async";
import { ClickAwayListener } from "@material-ui/core";

const styles = (theme) => ({
  block: {
    display: "inline-block",
    verticalAlign: "middle",
    width: "100%",
    height: "100%",
    position: "absolute",
  },
  progress: {
    verticalAlign: "middle",
  },
});

class CloneButton extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: false,
      showInput: false,
    };

    this.cbHandleClone = this.cbHandleClone.bind(this);
    this.handleClickAway = this.handleClickAway.bind(this);
  }

  handleClickAway = (e) => {
    if (!e.target.classList.contains("clone_parameters")) {
      this.setState({ showInput: false });
    }
  };

  cbHandleClone = (from, to) => {
    console.log('from to',from, to);
    const { resource, record, showNotification } = this.props;
    console.log('cbHandleClone resource',resource);
    let resourceApi = `${resource}_clone`;
    this.setState({ isLoading: true });

    useFetch(
      `/api/admin/${resourceApi}/${from}/${to}`,
      {
        method: "post",
        body: JSON.stringify(record),
        resourceApi,
      },
      showNotification
    ).then((res) => {
      this.setState({ isLoading: false });
      if (res && res.status === 200) {
        return setTimeout(window.location.href = `/#/${resource}/${to}`, 500);
      }
    });
    this.setState({ showInput: false });
  };

  render() {
    const { isLoading, showInput } = this.state;

    const { classes, record, children } = this.props;

    return (
      <div id={`${record.id}-clone-feature`}>
        <div
          id={`${record.id}-clone-button`}
          onClick={(prevState) => this.setState({ showInput: !prevState.showInput })}
        >
          <IconButton
            color="primary"
            icon={(iconClasses) => (
              <FileCopy className={iconClasses.leftIcon} />
            )}
          >
            Clone
          </IconButton>
          {isLoading && (
            <CircularProgress
              className={classes.progress}
              size={20}
              thickness={2}
            />
          )}
        </div>

        {showInput ? (
          <ClickAwayListener onClickAway={this.handleClickAway}>
            {React.cloneElement(children, {
              cbHandleClone: this.cbHandleClone,
              record,
              showInput,
            })}
          </ClickAwayListener>
        ) : (
          <div />
        )}
      </div>
    );
  }
}

export default connect(null, { showNotification: showNotificationAction })(
  withStyles(styles)(CloneButton)
);
