import React, { useState, useCallback } from "react";
import Accordion from "@material-ui/core/Accordion";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import Grid from "./Grid";
import { Typography } from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";

const styles = (theme) => ({
  root: {
    width: "100%",
    marginBottom: 8,
    marginTop: 16,
  },
  detailsRoot: {
    display: "block",
  },
  expansionPanelSummary: {
    border: "1px #fff solid",
  },
  expansionPanelSummaryTitle: {
    color: "#333",
    fontSize: "16px",
    fontWeight: 700,
  },
  icon: {
    verticalAlign: "middle",
    marginRight: 5,
    marginTop: "-3px",
  },
  missing: {
    fontStyle: "italic",
    color: "#f39c12",
    fontSize: 12,
  },
  quarterWidth: { width: "25%" },
  halfWidth: { width: "50%" },
  fullWidth: { width: "100%" },
  wrapperInputWidthSpan2Link: { gridColumn: "span 2" },
  wrapperInputWidthSpan3Link: { gridColumn: "span 3" },
  wrapperInputWidthSpan4Link: { gridColumn: "span 4" },
  wrapperInputWidthSpan5Link: { gridColumn: "span 5" },
  wrapperInputWidthSpan6Link: { gridColumn: "span 6" },
});

const AccordionComponent = ({
  defaultExpanded = false,
  expanded = false,
  title = "",
  children,
  classes,
  classNameAccordion,
  gridColumnsTitle = 1,
}) => {

  const [isExpanded, setIsExpanded] = useState(expanded || false);
  const handleExpand = useCallback(() => {
    setIsExpanded((currentExpanded) => !currentExpanded);
  }, []);

  return (
    <Accordion 
      className={classNameAccordion}
      defaultExpanded={defaultExpanded}
      expanded={expanded ? expanded : isExpanded}
    >
      <AccordionSummary
        onClick={handleExpand}
        expandIcon={expanded !== true && <ExpandMoreIcon />}
        className={classes.expansionPanelSummary}
      >
        <Grid columns={gridColumnsTitle}>
          <Typography className={classes.expansionPanelSummaryTitle}>
            {title}
          </Typography>
        </Grid>
      </AccordionSummary>
      <AccordionDetails
        classes={{ root: classes.detailsRoot }}
      >
        {children}
      </AccordionDetails>
    </Accordion>
  );
};

export default withStyles(styles)(AccordionComponent);
