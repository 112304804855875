import { withStyles } from "@material-ui/core";
import Button from "@material-ui/core/Button";
import CircularProgress from "@material-ui/core/CircularProgress";
import React, { Component, Fragment } from "react";
import {
  DateInput,
  FormDataConsumer,
  NumberInput,
  SaveButton,
  SelectInput,
  SimpleForm,
  TextInput,
  Toolbar,
  BooleanInput,
} from "react-admin";
import { handleUpdate } from "../../Actions/CrudActions";
import ListAceInput from "../../components/ListAceInput";
// import CommonDynamicFieldsComponents from '../../components/CommonDynamicFieldsComponents';

const styles = () => ({
  inputStyle: {
    marginRight: "15px",
  },
});

//https://github.com/marmelab/react-admin/pull/4544/commits/c7c263ac42e159ee35b815a62a0f4c8a6ef41f66
//Sanitize props button to avoid js errors
const SanitizedToolBarButtons = ({
  invalid,
  pristine,
  mutationMode,
  validating,
  submitOnEnter,
  ...rest
}) => (
  <Fragment>
    <SaveButton
      record={rest.record}
      redirect="list"
      resource={rest.resource}
      onClick={handleUpdate(rest)}
      {...rest}
    />
    <Button onClick={rest.onCancel}>Cancel</Button>
  </Fragment>
);

const EditToolbar = (props) => (
  <Toolbar {...props}>
    <SanitizedToolBarButtons {...props} />
  </Toolbar>
);

const filteredParams = (settings, filter) =>
  settings.reduce(
    (acc, setting) =>
      setting.id === filter ? [...acc, ...setting.parameters] : acc,
    []
  );

export const renderInput = (
  { type, defaultValues, ...props },
  key,
  classes,
  formData
) => {
  switch (type) {
    case "textinput":
      return (
        <TextInput
          key={key}
          className={classes.inputStyle}
          resettable
          {...props}
        ></TextInput>
      );
    case "dateinput":
      return (
        <DateInput
          key={key}
          className={classes.inputStyle}
          {...props}
        ></DateInput>
      );
    case "selectinput":
      return (
        <SelectInput
          key={key}
          className={classes.inputStyle}
          resettable
          {...props}
        ></SelectInput>
      );
    case "numberinput":
      return (
        <NumberInput
          key={key}
          className={classes.inputStyle}
          {...props}
        ></NumberInput>
      );
    case "listaceinput":
      return (
        <ListAceInput key={key} className={classes.inputStyle} {...props} />
      );
    default:
      return (
        <TextInput
          key={key}
          className={classes.inputStyle}
          resettable
          {...props}
        ></TextInput>
      );
  }
};

const renderParamsSetting = (settings, setting, classes, formData) => {
  return filteredParams(settings, setting).map((param, key) =>
    renderInput(param, key, classes, formData)
  );
};

class EditForm extends Component {
  constructor(props) {
    super(props);

    this.state = {
      listOptionsState: window.list_settings || [],
      isLoading: false,
    };
  }

  render() {
    const { record, onCancel, classes, ...rest } = this.props;
    const { listOptionsState, isLoading } = this.state;
    return (
      <SimpleForm
        submitOnEnter={false}
        redirect="list"
        record={record}
        toolbar={<EditToolbar record={record} onCancel={onCancel} />}
        {...rest}
      >
        {isLoading ? (
          <CircularProgress size={40} thickness={2} />
        ) : (
          <SelectInput source="setting" choices={listOptionsState} resettable />
        )}
        <FormDataConsumer>
          {({ formData }) =>
            formData.setting &&
            renderParamsSetting(
              window.list_settings,
              formData.setting,
              classes,
              formData
            )
          }
        </FormDataConsumer>
      </SimpleForm>
    );
  }
}
export default withStyles(styles)(EditForm);
