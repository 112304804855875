import React, { useState, useEffect, useCallback } from "react";
import {
    AutocompleteInput,
    ReferenceInput,
    useQueryWithStore,
    FormDataConsumer,
    SelectInput
} from "react-admin";
import {makeStyles} from "@material-ui/core/styles";
import PreviewArea from "./PreviewArea";
import {Field, useFormState} from "react-final-form";
import { FieldArray } from "react-final-form-arrays";
import useTemplateEngine from "../hooks/useTemplateEngine";
import Grid from "./Grid";
import Button from "@material-ui/core/Button";
import { getSource } from "../tools";
import { useFieldArray } from "react-final-form-arrays";
import cssGeneric from '!!file-loader?name=static/[name].[ext]!../../AppPaywall/src/views/main/paywall.css';

const useStyles = makeStyles({
    text_editor_content: {
        width: '65%'
    },
    input_data: {
        width: '320px',
        height: '30px',
        padding: '0px 20px',
        margin: '30px 8px 0',
        boxSizing: 'border-box'
    },
    input_data_value: {
        width: '500px',
        height: '40px',
        padding: '0px 20px',
        margin: '30px 8px 0',
        boxSizing: 'border-box'
    },
    button_remove: {
        color: 'red',
        width: '150px',
        marginLeft: '20px'
    },
    custom_template_select: {
      width: '100%'
    },
    button_add: {
        marginTop: '40px',
        marginBottom: '40px'
    },
    button_select_custom_template: {
        height: '40px',
        marginTop: '10px'
    },
    width_grid_autocomplete_input: {
        width:'600px'
    }
});

const Layer = ({
    source,
    properties_suffix= ``,
    disabled = false,
    fillPlaceholderFields = false,
    widthAutoCompleteInput
}) => {
    const classes = useStyles();
    const {
        fields:
            {   push: pushFieldArray,
                value: valueFieldArray = [],
                remove
            }
        } = useFieldArray(`${source}${properties_suffix}`)

    const { values: formdata } = useFormState();
    const [cssContent, setCssContent] = useState("");
        // Fetch CSS content when the component mounts
        useEffect(() => {
            const fetchCssContent = async () => {
                try {
                    const response = await fetch(cssGeneric);
                    const text = await response.text();
                    setCssContent(text); // Store the CSS content as a string
                } catch (error) {
                    console.error("Failed to fetch CSS:", error);
                }
            };
    
            fetchCssContent();
        }, []);

    //Get html render from offers
    const {
        data: OfferdataQuery,
    } = useQueryWithStore({
        type: "getOne",
        resource: "custom-templates",
        payload: { id: getSource(formdata,`${source}_offer_custom_template`) }
    });

    const OffersRender = useTemplateEngine(
        `${OfferdataQuery?.css_template || ``} ${OfferdataQuery?.template || ``}`,
        {offers_items: formdata?.offers || [], offers_name: formdata.offers_name || ''}
    );

    const {
        data: dataQuery,
    } = useQueryWithStore({
        type: "getOne",
        resource: "custom-templates",
        payload: { id: getSource(formdata,`${source}_custom_template`) },
    });
    
    const htmlTemplate = `${dataQuery?.css_template || `<style>${cssContent||``}</style>`} ${dataQuery?.template || ``}`;
    const match = [...htmlTemplate.matchAll('{{([a-zA-Z]+)}}|{{defaultValue ([a-zA-Z]+) \'.*\'}}')];
    const placeholder = match.map((e) => {
        let value = e['1'] ?? e['2'];
        if (value === 'offers') {
            return;
        }

        return {id: value, name: value};
    });

    var data = {'offers': OffersRender};
    const handleChange = (formData, fields, index, source) => {
        fields.map((field) => {
            data[getSource(formData,source)[index]['property']] = getSource(formData,source)[index]['value']
        });
    };

    const cleanFieldArray = (fieldArray) => {
        if(fieldArray.length > 0 && fillPlaceholderFields){
            fieldArray.forEach((field,index) => {
                remove(index)
            });
        }
    }
    const handleOnStateChange = useCallback((e)=>{
        cleanFieldArray(valueFieldArray)
    },[valueFieldArray,fillPlaceholderFields])

    //fill placeholder with properties if requested by component
    useEffect(()=>{
        if(fillPlaceholderFields && valueFieldArray.length === 0 && placeholder.length > 0){
            placeholder.forEach((placehold)=>{
                pushFieldArray({property:placehold?.name,value:''})
            })
        }
        // () =>{placeholder.forEach((pl,indexPl)=>{remove(indexPl)})}
    },[fillPlaceholderFields, placeholder.length, valueFieldArray.length])

    return (
        <div>
            <FormDataConsumer>
                {({formData, ...rest}) =>
                    <div>
                        <Grid columns={2} width={classes.width_grid_autocomplete_input}>
                                <ReferenceInput
                                    alwaysOn
                                    source={`${source}_custom_template`}
                                    label="Custom template"
                                    reference="custom-templates"
                                    perPage={1000}
                                    resource={""}
                                    filter={{tag: "layer",limit:1000}}
                                    className={widthAutoCompleteInput||classes.custom_template_select}
                                    // filterToQuery={text => ({ name: text })}
                                    sort={{ field: "headline", order: "ASC" }}
                                >
                                    <AutocompleteInput
                                        alwaysOn
                                        resettable
                                        onStateChange={handleOnStateChange}
                                        // shouldRenderSuggestions={(val) => { return val.trim().length > 0 }}
                                        disabled={disabled}
                                        helperText="Select an existing custom templates to override"
                                        options={{
                                          fullWidth: true,
                                        }}
                                        optionText={(choice) => `${choice?.headline} (${choice?.guid})`}
                                    />
                                </ReferenceInput>
                            {getSource(formdata,`${source}_custom_template`) &&
                                <Button variant="contained" color={'primary'}
                                        className={classes.button_select_custom_template}
                                        target="_blank"
                                        href={'#/custom-templates/' + getSource(formdata,`${source}_custom_template`)}
                                        disabled={disabled}
                                >
                                    Edit Custom template
                                </Button>
                            }
                        </Grid>
                        {getSource(formdata,`${source}_custom_template`) &&
                            <div>
                                <FieldArray mylabel="Data" name={`${source}${properties_suffix}`}>
                                    {({fields}) => (
                                        <div>
                                            {fields.map((name, index) => (
                                                <div key={`${name}-${index}`}>
                                                        {placeholder.length > 0 &&
                                                            <SelectInput className={classes.input_data}
                                                                         name={`${name}.property`}
                                                                         source={`${name}.property`}
                                                                         choices={placeholder}
                                                                         onBlur={handleChange(formData, fields, index, `${source}${properties_suffix}`)}
                                                                         label={'PlaceHolder'}
                                                                         disabled={disabled}
                                                            />
                                                        }
                                                        <Field className={classes.input_data_value} 
                                                               name={`${name}.value`}
                                                               component="input"
                                                               disabled={disabled}
                                                        />
                                                        <Button variant="outlined"
                                                                color={'inherit'}
                                                                className={classes.button_remove}
                                                                onClick={() => fields.remove(index)}
                                                                disabled={disabled}
                                                        >
                                                            Remove
                                                        </Button>
                                                </div>
                                            ))}

                                            <Button variant="contained"
                                                    color={'primary'}
                                                    className={classes.button_add}
                                                    onClick={() => fields.push({property: '', value: ''})}
                                                    disabled={disabled}
                                            >
                                                Add new property/value
                                            </Button>
                                        </div>
                                    )}
                                </FieldArray>
                                <Field
                                name={"preview_area"}
                                height="100%"
                                width="100%"
                                component={PreviewArea}
                                template={htmlTemplate}
                                previewData={data}
                                parseDataFromJson={false}
                                injectValue={true}
                                disabled={disabled}
                                />
                            </div>
                        }
                    </div>
                }
            </FormDataConsumer>
        </div>
    )
};

export default Layer;