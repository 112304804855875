import React from 'react';
import {
    TextField, SimpleShowLayout, Show
} from 'react-admin';

const CustomTemplateShow = props => (
    <Show title="Tier" {...props}>
        <SimpleShowLayout>
            <TextField source="headline" />
        </SimpleShowLayout>
    </Show>
);

export default CustomTemplateShow;
