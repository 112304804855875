import React from 'react';
import { SaveButton, Toolbar } from 'react-admin';

const EditToolbar = ({ classes, ...props }) => (
    <Toolbar style={{ width: '100%' }} {...props}>
        <SaveButton
            redirect="edit"
            submitOnEnter
        />
    </Toolbar>
);


export default EditToolbar;
