import React, { useRef, useEffect} from 'react';
import AceEditor from 'react-ace';
import ace from 'ace-builds';
import "ace-builds/src-min-noconflict/mode-html";
import "ace-builds/src-min-noconflict/mode-css";
import "ace-builds/src-min-noconflict/mode-javascript";
import "ace-builds/src-min-noconflict/theme-monokai";
import "ace-builds/src-min-noconflict/ext-language_tools";
import "ace-builds/src-min-noconflict/ext-spellcheck";
import "ace-builds/src-min-noconflict/snippets/html";
import "ace-builds/src-min-noconflict/snippets/css";
import "ace-builds/src-min-noconflict/snippets/javascript";
import { withStyles } from '@material-ui/core/styles';
import {useInput, useRedirect} from "react-admin";

const styles = {
    aceList : {
        "& .ace_editor.ace_autocomplete": {
            width:"70%",
            padding:"5px 0",
            overflowY:"hidden",
            fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
            border: "0",
            "& .ace_line": {cursor: "pointer"},
            "& .ace_marker-layer": {
                "& .ace_active-line":{backgroundColor: "white"},
                "& .ace_line-hover":{backgroundColor: "#e8e8e8", border:"0", cursor: "pointer"},
            }
        },
    }
};

const AceEditorInput = props => {
    const {
        input: { name, value, onChange, ...rest }
    } = useInput({source: props.source});
    const aceEditorRef = useRef();
    
    // Set base path for Ace Editor
    useEffect(() => {
        ace.config.set('basePath', '/node_modules/ace-builds/src-min-noconflict');
    }, []);

    const { labl, row, minLines = 1, mode = "html", placeholder = "", disabled = false } = props;
    const height = row ? row : "500px";

    return (
        <div>
            <p style={{margin: "10px 0", color: 'grey', 'fontFamily': 'Arial', fontWeight: 'normal', fontSize: '12px'}}>{labl}</p>
            <AceEditor
                mode={mode}
                style={{
                    padding: 5,
                    borderBottom: "1px solid black",
                }}
                ref={aceEditorRef}
                placeholder={placeholder}
                theme="monokai"
                name={props.source}
                showPrintMargin={true}
                showGutter={true}
                highlightActiveLine={true}
                value={value}
                minLines={minLines}
                maxLines={50}
                onChange={onChange}
                height={height}
                width={"100%"}
                readOnly={disabled}
                setOptions={{
                    enableBasicAutocompletion: true,
                    enableLiveAutocompletion: true,
                    enableSnippets: true,
                    showLineNumbers: true,
                    useWorker: false,
                    tabSize: 2,
                }} />
        </div>
    );
}

export default withStyles(styles)(AceEditorInput);




