import React, { useState, useCallback, useEffect } from "react";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { QueryBuilderMaterial } from "@react-querybuilder/material";
import {
  formatQuery,
  QueryBuilder,
  defaultValidator,
  defaultRuleProcessorCEL,
  add, remove, update
} from "react-querybuilder";
import { QueryBuilderDnD } from "@react-querybuilder/dnd";
import * as ReactDnD from "react-dnd";
import * as ReactDndHtml5Backend from "react-dnd-html5-backend";
import Typography from "@material-ui/core/Typography";
import "./querybuilder.scss";
import PropTypes from "prop-types";
import { useField, useFormState } from "react-final-form";
import { makeStyles } from "@material-ui/core/styles";
import { Tooltip } from "@material-ui/core";
import HelpIcon from "@mui/icons-material/Help";

const useStyles = makeStyles({
  info_content: {
    padding: "20px",
    border: "1px solid #E8E8E8",
    margin: "10px 0",
  },
});

const muiTheme = createTheme();
const defaultQuery = {
  combinator: "and",
  rules: [],
};


export const getDatesTimeFromRulesQuery = (rules = []) => 
  rules.reduce((acc, rule) => {
    const { field, value } = rule;
    const dateTime = new Date(value).getTime();

    if (field === "campaign_start_date") {
      acc.startDateTime = dateTime;
    } else if (field === "campaign_end_date") {
      acc.endDateTime = dateTime;
    }

    return acc;
  }, { startDateTime: 0, endDateTime: 0 });

//drive query outside querybuilder component
export const updateQuery = (
  query = { rules: [] },
  input = "",
  field = "",
  operator = "",
  segment_score = 0,
) => {
  const index = query["rules"]?.map((e) => e.field).indexOf(field);

  //remove value if existing and empty input
  if (index > -1 && input === "") {
    return remove(query, [index]);
  }
  //add value if not existing
  if (index === -1 && input !== "") {
    return add(
      query,
      {
        field: field,
        operator: operator,
        valuesource: "value",
        value: input,
        segment_score: segment_score,
      },
      []
    );
  }
  //change value if existing
  if (index > -1 && input !== "") {
    return update(query, "value", input, [index]);
  }
};

//list intersecting options available and query options consumed
const listQueryScoreSegment = (options = [], rules = []) => {
  return options.filter((option) =>
  rules.some((rule) => option.name === rule.field)
  );
};
// Additionnal computing of score segment list
const computeQueryScoreSegment = (options = [], rules = []) => {
  return listQueryScoreSegment(options,rules).reduce((acc, option) => {
    return acc + (option.segment_score || 0);
  }, 0);
};

const QueryBuilderComponent = ({
  name,
  options = [],
  formatQueryOutput = "cel",
  showCloneButtons = true,
  showNotToggle = true,
  addRuleToNewGroups = true,
  parseNumbers = true,
  isDisplayingResult = true,
  isDisplayingScore = true,
  ...rest
}) => {
  const classes = useStyles();

  const {
    input: { value: queryValue, onChange: onChangeQuery },
  } = useField(`${name}.query_object`);

  const {
    input: { value: formatValue, onChange: onChangeFormat },
  } = useField(`${name}.query_format`);

  const {
    input: {
      value: querySegmentScoreValue,
      onChange: changeQuerySegmentScoreValue,
    },
  } = useField(`${name}.query_segment_score`, { initialValue: 1 });

  /* const {
    input: {
      value: segmentScoreValue,
      onChange: changeSegmentScoreValue,
    },
  } = useField(`priority`, { initialValue: querySegmentScoreValue }); */

  const [query, setQuery] = useState(queryValue || defaultQuery);

  //update query when queryValue change from outside component
  useEffect(() => {
    setQuery(queryValue);
  }, [queryValue]);

  useEffect(() => {
    changeQuerySegmentScoreValue(
      computeQueryScoreSegment(options,queryValue?.rules)
    );
  });

  //ex: "speedreads,russia" => "["speedreads","russia"]"
  const transformStrValuesToStringArray = (val,separator = ",") => JSON.stringify((val && typeof(val=== "string")) ? val.split(separator):[]);

  const getPropertyFromStr = (val,index = 0, separator = ".") =>{
    if((val && typeof(val=== "string")) && val.indexOf(".") > 0){
       return val.split(separator)[index];
    }
    return val;
  } 

  const ruleProcessorCustomForCelFormat = (r) => {
    let rule = r;
    switch(r.operator){
      case "existOne":
        rule = `${getPropertyFromStr(r.field)}.exists(v, v in ${transformStrValuesToStringArray(r.value)})`;
        break;
      case "notExistOne":
        rule = `!${getPropertyFromStr(r.field)}.exists(v, v in ${transformStrValuesToStringArray(r.value)})`;
        break;
      case "existOneBy":
        rule = `${getPropertyFromStr(r.field,0)}.exists(v, v.${getPropertyFromStr(r.field,1)} in ${transformStrValuesToStringArray(r.value)})`;
        break;
      case "notexistOneBy":
        rule = `!${getPropertyFromStr(r.field,0)}.exists(v, v.${getPropertyFromStr(r.field,1)} in ${transformStrValuesToStringArray(r.value)})`;
        break;
      default:
        rule = defaultRuleProcessorCEL(r)
    }

    return rule
  }

  const handleOnQueryChange = 
  // useCallback(
    (q) => {
      if (!q) return;
      onChangeQuery(q);
      //change the output format of the query
      const formattedQuery = formatQuery(q, 
        {
          format:formatQueryOutput,
          ...(formatQueryOutput === "cel" ? {valueProcessor:ruleProcessorCustomForCelFormat}:{})
        }
      );
      onChangeFormat(formattedQuery);
      
      setQuery(q);
      changeQuerySegmentScoreValue(
        computeQueryScoreSegment(options, queryValue?.rules)
      );
    }/* ,
    [
      onChangeQuery,
      onChangeFormat,
      queryValue?.rules?.length,
      options?.length,
      formatQueryOutput,
      queryValue,
    ] */
  // );

  return (
    <>
      <ThemeProvider theme={muiTheme}>
        <QueryBuilderMaterial>
          <QueryBuilderDnD dnd={{ ...ReactDnD, ...ReactDndHtml5Backend }}>
            <QueryBuilder
              fields={options}
              query={query}
              onQueryChange={handleOnQueryChange}
              addRuleToNewGroups={addRuleToNewGroups}
              showCloneButtons={showCloneButtons}
              showNotToggle={showNotToggle}
              validator={defaultValidator}
              controlClassnames={{ queryBuilder: "queryBuilder-branches" }}
              // {...rest}
            />
          </QueryBuilderDnD>
        </QueryBuilderMaterial>
      </ThemeProvider>
      {isDisplayingScore && (
        <div className={classes.info_content}>
          <Typography variant="subtitle1" color="inherit">
            {`Total Query Segment Score suggested : ${querySegmentScoreValue}`}
          </Typography>
          <Tooltip
            leaveDelay={4000}
            interactive
            placement="top"
            title={listQueryScoreSegment(options,query?.rules).map(
              (option, i) =>
                option.segment_score > 0 && (
                  <Typography key={`tooltip-segment-${i}`}>
                    {`${option.label}:${option.segment_score}`}
                    <br />
                  </Typography>
                )
            )}
          >
            <HelpIcon />
          </Tooltip>
        </div>
      )}
      {isDisplayingResult && (
        <div className={classes.info_content}>
          <Typography
            variant="subtitle1"
            color="inherit" /* className={classes.flex} */
          >
            {`Result with ${formatQueryOutput} format: `}
            {query && (
              <>
                <br />
                <code>
                  {formatValue}
                </code>
                :
              </>
            )}
          </Typography>
        </div>
      )}
    </>
  );
};
QueryBuilderComponent.propTypes = {
  name: PropTypes.string,
  options: PropTypes.array,
};
export default QueryBuilderComponent;
