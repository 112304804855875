import React from 'react';
import {
    List,
    Datagrid,
    TextField,
    ImageField,
    EditButton,
    ShowButton,
    TextInput,
} from 'react-admin';
import { makeStyles } from '@material-ui/core/styles';
import CloneButton from "../../components/Clone";
import CloneSiteConfig from "../../components/Clone/CloneSiteConfig";

const useStyles = makeStyles({
    logo_content: {
        '& img' : {
            maxWidth: '100px'
        }
    },
});

const siteConfigList = (props) => {
    const classes = useStyles();

    return (
        <List  perPage={25} {... props} >
        <Datagrid >
            <TextField source="site" />
            <TextField source="name" />
            <TextField source="journal" label="Journal code" />
            <ImageField className={classes.logo_content} source="logo_small" title="Logo" />
            <EditButton />
            <ShowButton />
            <CloneButton>
               <CloneSiteConfig />
            </CloneButton>
        </Datagrid>
        </List>
    );
};

export default siteConfigList;
