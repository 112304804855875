import React, { useEffect } from "react";
import Handlebars from "handlebars";

function htmlDecode(options) {
  var doc = new DOMParser().parseFromString(options.fn(this), "text/html");
  return doc.documentElement.textContent;
}

function resize(src, width, height) {
  // If the image is not from the cdn then we don't resize it
  if (!src) {
    return '';
  }

  if (!src.includes('cdn.mos.cms')) {
    return src;
  }

  if (src && width && height) {
    return src.substring(0, src.lastIndexOf('.'))+'-'+width+'-'+height+src.substring(src.lastIndexOf("."));
  } else {
    return src;
  }
}

const useTemplateEngine = (template = "", context = {}, deps = []) => {
  try {
    Handlebars.registerHelper("htmlDecode", htmlDecode);
    Handlebars.registerHelper('defaultValue', function (a, b) {return a ? a : b;});
    Handlebars.registerHelper('resize', resize);

    var templateCompiled = Handlebars.compile(template);

    return templateCompiled(context);
  } catch (error) {
    console.log(error);
  }

  return Handlebars.compile('Error, open the console to see more details')(context);
};

export default useTemplateEngine;
