import React from 'react';
import Button from '@material-ui/core/Button';
import { withStyles } from '@material-ui/core/styles';

const styles = theme => ({
    leftIcon: {
        marginRight: theme.spacing(1),
    },
});

const IconButton = (props) => {
    const { children, classes, icon, ...rest } = props;
    return (
        <Button {...rest} className={classes.button}>
            {icon(classes)}
            {children}
        </Button>
    );
};

export default withStyles(styles)(IconButton);
