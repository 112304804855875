import React, { Component } from 'react';
import { connect } from 'react-redux';
import { showNotification as showNotificationAction } from 'react-admin';
import axios from 'axios/index';
import CircularProgress from '@material-ui/core/CircularProgress';
import CloudDownloadIcon from '@material-ui/icons/CloudDownload';
import IconButton from './IconButton';
import { makeBranchedUrl } from '../restClient';
import Cookies from "js-cookie";
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Dialog from '@material-ui/core/Dialog';
import Button from '@material-ui/core/Button';

class RestoreButton extends Component {
    state = {
        isLoading: false
    };

    handleRestore = () => {
        this.handleClose();
        this.setState({ isLoading: true });
        const { record, resource, showNotification } = this.props;
        const fd = new FormData();

        fd.append('guid', record.guid);
        fd.append('guidToRestore', record.guidToRestore);
        fd.append('doc_type', record.doc_type);

        let requestConfig = {
            headers: {
                'Authorization': localStorage.getItem('token'),
                'Accept': 'application/json',
                'X-Role': localStorage.getItem('role'),
                'X-ExpiresIn': localStorage.getItem('expiresIn'),
                'X-User-Name': localStorage.getItem('user')
            }
        };

        axios
            .post(makeBranchedUrl(`/api/admin/restore/${resource}/${record.guid}/${record.guidToRestore}`), fd, requestConfig)
            .then(response => response.data)
            .then((response) => {
                this.setState({
                    isLoading: false,
                });
                if (response.error) {
                    showNotification(
                        `${'kiosq.restored.error :'}${response.error.exception[0].message}`,
                        'warning',
                    );
                }
                showNotification('kiosq.restored.success');
                setTimeout(window.location.reload(), 1000);
            })
            .catch((thrown) => {
                if (axios.isCancel(thrown)) {
                    console.log('Request canceled', thrown.message);
                } else {
                    console.log(thrown);
                    this.setState({ isLoading: false });
                    showNotification('kiosq.restored.error');
                }
            });
    };

    state = {
        open: false,
    };

    handleClickOpen = () => {
        this.setState({ open: true });
    };

    handleClose = () => {
        this.setState({ open: false });
    };

    render() {
        const { isLoading } = this.state;
        const { record, display } = this.props;
        return record && record.guid && !!display ? (
            <div>
                <IconButton
                    color="primary"
                    icon={classes =>
                        isLoading ?
                            <CircularProgress className={classes.leftIcon} size={20} thickness={2} />
                            : <CloudDownloadIcon className={classes.leftIcon} />
                    }
                    onClick={this.handleClickOpen}
                >
                    Restore
                </IconButton>
                <Dialog
                    open={this.state.open}
                    onClose={this.handleClose}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                >
                    <DialogTitle id="alert-dialog-title">Restore version v{record.version} ?</DialogTitle>
                    <DialogContent>
                        <DialogContentText id="alert-dialog-description">
                            Are you sure that you want to restore version v{record.version} ?
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={this.handleClose} color="primary">
                            No
                        </Button>
                        <Button onClick={this.handleRestore} color="primary" autoFocus>
                            Yes
                        </Button>
                    </DialogActions>
                </Dialog>
            </div>
        ) : null;
    }
}

RestoreButton.defaultProps = {
    display: true
};

export default connect(
    null,
    { showNotification: showNotificationAction },
)(RestoreButton);
