import React from 'react';
import { Create } from 'react-admin';
import EditForm from './EditForm';

const CustomTemplateCreate = props => (
    <Create {...props}>
        <EditForm />
    </Create>
);

export default CustomTemplateCreate;
