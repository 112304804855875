import React from "react";
import FormDataContent from "../../components/FormDataContent";
import ArrayInputContent from "../../components/ArrayInputContent";
import { TextInput, SelectInput } from "react-admin";
import { Field, useFormState } from "react-final-form";
import Layer from "../../components/Layer";
import AceEditorInput from "../../components/AceEditorInput";
import AccordionComponent from "../../components/AccordionComponent";
import useCampaignsInstructions from "./useCampaignsInstructions"
import { useStyles } from "./EditForm";
const InjectionForm = ({ field = "", disabled = false }) => {
  const { InjectCustomJSCSSInstructionsComponent, InjectCustomTemplatesInstructionsComponent, InjectRewritersLinksInstructionsComponent } = useCampaignsInstructions();
  const source = field ? `${field}.` : "";
  const classes = useStyles();

  return (
    <>
      <FormDataContent dataContentTitle={"Custom JS/CSS"}>
        <AccordionComponent classNameAccordion={classes.accordion} title="How to use Custom JS/CSS?">
          <InjectCustomJSCSSInstructionsComponent/>
        </AccordionComponent>
        <AceEditorInput
          name={`${source}custom_css`}
          source={`${source}custom_css`}
          placeholder={`.main{color:inherit}`}
          labl={"Custom CSS"}
          minLines={3}
          mode={"css"}
          disabled={disabled}
        />
        <AceEditorInput
          name={`${source}custom_js`}
          source={`${source}custom_js`}
          placeholder={`console.log('Put your JS Script without <script> tag')`}
          labl={"Custom JS"}
          minLines={3}
          mode={"javascript"}
          disabled={disabled}
        />
      </FormDataContent>
      <FormDataContent dataContentTitle={"Custom Template"}>
      <AccordionComponent classNameAccordion={classes.accordion} title="How to use Custom Templates Injection?">
          <InjectCustomTemplatesInstructionsComponent/>
        </AccordionComponent>
        <ArrayInputContent
          arrayInputSource={`${source}elements_custom_template`}
          label={`Select your Custom Template to inject`}
          // classes={{SimpleFormIterator:simpleFormClass}}
          isDisableAdd={disabled}
        >
          <TextInput
            source={`css_selector`}
            label={`Choose your css selector`}
            helperText="HTML Elements to Target with selector"
            // className={simpleFormInputClass}
            fullWidth
            disabled={disabled}
            required
          />
          <SelectInput
            source={`position`}
            label={`Selector's Position`}
            helperText={`Adjacent position or replace any selector (default replace)`}
            choices={[
              { id: "before", name: "Before your selector" },
              { id: "replace", name: "Replace your selector (default)" },
              { id: "after", name: "After your selector" },
              { id: "prepend", name: "First child of your selector" },
              { id: "append", name: "Last child of your selector" },
            ]}
            defaultValue={"replace"}
            fullWidth
            disabled={disabled}
            required
          />
          <Field
            name={"Custom Template"}
            source={`injected`}
            properties_suffix={`_custom_template_properties`}
            height="100%"
            width="100%"
            fillPlaceholderFields={true}
            component={Layer}
            disabled={disabled}
          />
        </ArrayInputContent>
      </FormDataContent>
      <FormDataContent dataContentTitle={"Links rewriter"}>
      <AccordionComponent classNameAccordion={classes.accordion} title="How to use Links Rewriters Injection?">
          <InjectRewritersLinksInstructionsComponent/>
        </AccordionComponent>
        <ArrayInputContent
          arrayInputSource={`${source}links_rewriter`}
          label={`List Links to rewrite`}
          // classes={{SimpleFormIterator:simpleFormClass}}
          isDisableAdd={disabled}
        >
          <TextInput
            source={`link_replace_source`}
            label={`Link Replace Source`}
            helperText="Absolute Link Url to replace"
            // className={simpleFormInputClass}
            fullWidth
            disabled={disabled}
          />
          <TextInput
            source={`link_replace_destination`}
            label={`Link Replace Destination`}
            helperText="New Absolute Link Url expected"
            // className={simpleFormInputClass}
            fullWidth
            disabled={disabled}
          />
          <TextInput
            source={`link_replace_label`}
            label={`Link Replace Label`}
            helperText="New label link expected"
            // className={simpleFormInputClass}
            fullWidth
            disabled={disabled}
          />
        </ArrayInputContent>
      </FormDataContent>
    </>
  );
};

export default InjectionForm;
