import React from "react";
import { Admin, Resource } from "react-admin";
import authProvider from "../authProvider";
import resources from "../resources";
import restClient from "../restClient";
import LogoutButton from "./LogoutButton";
import { Dashboard } from './Dashboard';

const AdminApp = (props) => {
  const isAuthenticated = props.isAuthenticated;
  const user = props.user;

  const customAuthProvider = authProvider(isAuthenticated, user.roles);
  return (
    <Admin
      dataProvider={restClient}
      authProvider={customAuthProvider}
      logoutButton={LogoutButton}
      dashboard={Dashboard}
    >
      {() => RenderResources({user})}
    </Admin>
  );
};

export const RenderResources = ({user}) => {
  return (
    resources.map((resource) => {
      switch (resource.name) {
        case "settings":
          return (
            user.roles.includes("SuperAdmin") ? (
              <Resource
                name={resource.name}
                list={resource.List}
                edit={resource.Edit}
                show={resource.Show}
                create={resource.Create}
                options={{ label: resource.label }}
                {...resource}
              />
            ) : null
          );
        case "users":
          return (
            user.roles.includes("SuperAdmin") || user.roles.includes("Admin") ? (
              <Resource
                name={resource.name}
                list={resource.List}
                edit={resource.Edit}
                show={resource.Show}
                create={resource.Create}
                options={{ label: resource.label }}
                {...resource}
              />
            ) : null
          );
        default:
          return (
            <Resource
              name={resource.name}
              list={resource.List}
              edit={resource.Edit}
              show={resource.Show}
              create={resource.Create}
              options={{ label: resource.label }}
              {...resource}
            />
          );
      }
    })
  )
}

export default AdminApp;
