import React, { useState } from 'react';
import { Edit, SimpleForm, TextInput, required, SelectInput, useRecordContext } from 'react-admin';
import { formatMs, makeStyles } from '@material-ui/core/styles';
import { useFormState } from 'react-final-form';

const styles = {
    marginRight: {
        marginRight: '30px',
    },
    linkingSectionTitle: {
        fontSize: '16px',
    }
};

const useStyles = makeStyles(styles);

const AccountMapping = (props) => {
    const classes = useStyles();
    const record = useRecordContext(props);

    const validateCdsTextInput = (e, formdata) => {
        if (e && formdata.site_id_for_linking) {
            return undefined;
        }
        if (!e && !formdata.site_id_for_linking) {
            return undefined;
        }
        return 'To link a cds account both inputs must have a value';
    }

    const validateCdsChoiceInput = (e, formdata) => {
        if (e && formdata.cds_account) {
            return undefined;
        }
        if (!e && !formdata.cds_account) {
            return undefined;
        }
        return 'To link a cds account both inputs must have a value';
    }
    return (
        <section>
            <p className={classes.linkingSectionTitle}>Bluebird linking</p>
            <div>
                <TextInput className={classes.marginRight} source="bluebird_subscription"/>
            </div>
            <div>
            <p className={classes.linkingSectionTitle}>Cds linking</p>
                <TextInput
                    className={classes.marginRight}
                    source="cds_account"
                    validate={validateCdsTextInput}
                />
                <SelectInput source="site_id_for_linking"
                    choices={record.cdsProdIdBySiteName}
                    translateChoice={false}
                    validate={validateCdsChoiceInput}
                />
            </div>
        </section>
    );
};

export default AccountMapping;
