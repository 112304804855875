import { fetch } from "./restClient";

const isValidJson = (str) => {
    str = typeof str !== "string"
        ? JSON.stringify(str)
        : str;

    try {
        str = JSON.parse(str);
    } catch (e) {
        return false;
    }

    if (typeof str === "object" && str !== null) {
        return true;
    }

    return false;
}

const useFetch = async (url, options = {}, showNotification) => {
    try {
        var responseFetch = await fetch(url, options);
        if (!isValidJson(responseFetch)) {
            responseFetch = await responseFetch.json();
        }
        responseFetch.status === 200
            ? showNotification(`resources.${options.resourceApi}.success`)
            : showNotification(`resources.${options.resourceApi}.failed`, "warning");
        return responseFetch;
    } catch (error) {
        showNotification(`${error}`, "warning");
    }
};

export { useFetch };
