import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import AceEditorInput from "./AceEditorInput";

const styles = theme => ({
    root: {
        marginTop: '20px',
    },
    paper: {
        ...theme.mixins.gutters(),
        boxSizing: 'border-box',
        paddingTop: theme.spacing(2),
        paddingBottom: theme.spacing(2),
    },
    wrap: {
        wordBreak: 'break-word',
    },
});

const TemplateInput = ({ classes }) => (
    <div className={classes.root}>
        <AceEditorInput  labl="template" name="template" source="template"   />
    </div>
);

export default withStyles(styles)(TemplateInput);
