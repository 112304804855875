import {FormDataConsumer, useRecordContext} from "react-admin";
import React, { useState } from 'react';
import TextEditor from "./TextEditor";

const RichTexteditor = (props) => {
    const record = useRecordContext(props);
    const [field, setField] = useState(
        record && record[props.source] ?
            record[props.source] :
            ''
    );

    if (record) {
        return (
            <FormDataConsumer>
                {({formData, ...rest}) => {
                    formData[props.source] = field;
                    return (
                        <TextEditor editorTitle={props.label} textContent= {field} handleText= {setField} />
                    )
                }}
            </FormDataConsumer>
        );
    } else {
        return null;
    }
};

export default RichTexteditor;