import React from "react";
import { Typography, Box } from "@material-ui/core";

const useCampaignsInstructions = () => {
  const QueryBuilderInstructionsComponent = () => (
      <Typography display="block" variant="body1">
        <Box color={"red"} fontWeight={700}>
          QueryBuilder
        </Box>
        {`Component allowing you to build a query that will match the user context.
        Add a new rule to add a condition to the query => choose the field, operator and value to compare.`}
        <br />
        <br />
        <Box fontWeight={600}>
          How to choose your operator and interact with your values?
        </Box>
        <br />
        <Box fontWeight={600}>
          For JavaScript variables returning a string (ex:UserCountryCode):
        </Box>
        <br />
        {`- "=" : result true matches a specific value (ex: 'US' = 'US')`}
        <br />
        {`- "!=" : result true if result js variable different from the specified value (ex: 'GB' != 'US')`}
        <br />
        {`- "contains" : result true if the value contains the specified string (ex: 'US' contains 'US GB')`}
        <br />
        {`- "doesNotContain" : result true if the value does not contain the specified string (ex: 'FR' doesNotContain 'US')`}
        <br />
        {`- "in" : result true if the value is present in a list of comma-separated values (ex: 'US' in 'US,GB') (Available with text input or multi select input)`}
        <br />
        {`- "notIn" : result true if the value is not present in a list of values (ex: 'FR' notIn 'US,GB')`}
        <br />
        {`- "begin with" : result true if the value is starting with a specific value (ex: 'US' beginWith 'U')`}
        <br />
        {`- "end with" : result true if the value is ending with a specific value (ex: 'US' beginWith 'S')`}
        <br />
        <br />
        <Box fontWeight={600}>
          For JavaScript variables returning an array (ex:ArticleControlTags):
        </Box>
        <br />
        {`- "existOneOf" : result true if at least one string element of the array matches the value (ex: ['van-paywall','other-control-tag'] existOneOf van-paywall)`}
        <br />
        {`- "notExistOneOf" : result true if no element of the array matches the value (ex: ['van-paywall','other-control-tag'] notExistOneOf serversidehawk)`}
        <br />
        <br />
        <Box fontWeight={600}>
          For JavaScript variables returning an array of object:
        </Box>
        <br />
        {`You have to specify the property name in the setting (`}
        <a
          href={`${window.origin}/#/settings`}
        >{`${window.origin}/#/settings`}</a>{" "}
        {`) of the js variable to define which property you want to query in each object`}
        <br />
        {`- "existOneBy (<property_name>)" : result true if at least one object in the array has a property matching the value (ex: [{'name':'user-name'},{'name':'user-name2'}] existOneBy user-name2)`}
        <br />
        {`- "notExistOneBy (<property_name>)" : result true if no object in the array has a property matching the value (ex: [{'name':'user-name'},{'name':'user-name2'}] notExistOneBy user-name3')`}
        <br />
        <br />
      </Typography>
  );

  const InjectCustomJSCSSInstructionsComponent = () => (
    <>
      <Typography display="block" variant="body1">
        <Box color={"red"} fontWeight={700}>
          Custom CSS:
        </Box>
        Enter your CSS script directly without wrap your code with style tag
        {`Example: `}
        <br></br>
          <code>{`.my-custom-css-style-injected {  background-color: red;}`}</code>
      <br></br>
      <br></br>
        <Box color={"green"} fontWeight={700}>
          Custom JS:
        </Box>
        Enter your JS script directly without wrap your code with script tag
        {`Example: `}
      <br></br>
          <code>{`console.log('my custom js script injected')`}</code>
      <br></br>
      <br></br>
      <Typography display="block">
        <Box fontWeight={700}>{`The render in the DOM will be like this:`}</Box>
      </Typography>
      <code>
        {`<div id="kiosq-app-paywall-js">`}
          <Box color={"red"}>
            {`  <style type="text/css" id="kiosq-campaign-custom-css">.my-custom-css-style-injected {background-color: red;}</style>`}
          </Box>
          {`  <div><div class="wrapper-template wrapper-main-template is-visible" data-testid="main-layer-wrapper-main"></div></div>
  <div class="campaign-debug-wrapper"></div>
  <div id="kiosq-modal-portal"></div>`}
          <Box color={"green"}>
            {`<script type="text/javascript" id="kiosq-campaign-custom-js">console.log('my custom js script injected')</script>`}
          </Box>
          {`</div>`}
      </code>
    </Typography>
    </>
  );

  const InjectCustomTemplatesInstructionsComponent = () => (
    <>
      <Typography display="inline" variant="body1">
        {`Enter the `}
        {
          <Box
            display="inline"
            fontWeight={700}
          >{`selector of the element`}</Box>
        }
        {` that you want to target, you can get only one element in the DOM`}
        {`Example: `}
        {
          <Box
            display="inline"
            fontStyle={"italic"}
            fontWeight={700}
          >{`button.kiosq-event-signup`}</Box>
        }
      <br></br>
        {`Select the `}
        <Box display="inline" fontWeight={700}>{`position`}</Box>
        {` where the new custom template has to be positionned relative to the target element.`}
      <br></br><br></br>
        Options:
      <br></br>
          - before: put the custom template before target element<br></br>
          - after: put the custom template after target element<br></br>
          - replace: Replace the target element by the custom template (default)<br></br>
          - prepend: Put the custom template as the first child of the target element<br></br>
          - append: Put the custom template as the last child of the target element<br></br>
      <br></br>
        {`Select the new `}
        <Box display="inline" fontWeight={700}>{`custom template`}</Box>
        {` and set the properties matching the placeholder (e.g: `}{<code>{`{{myplaceholder}}`}</code>}{` ) of the html custom template and set the value for the placeholder.`}
      </Typography>
    </>
  );

  const InjectRewritersLinksInstructionsComponent = () => (
    <>
      <Typography variant="body1">
        {<Box fontWeight={700}>{`Link Replace Source:`}</Box>}
        {`Enter the absolute link that you want to replace everywhere in the document. Only `}{<code>{`{{<a href="link"></a>}}`}</code>}{` tags with the following link will be selected`}
        {`Example: `}
        {
          <Box
            display={"inline-block"}
            fontStyle={"italic"}
          >{`https://www.cyclingnews.com.master.van-qa.futureplc.engineering/author/james-moultrie/?cc=US`}</Box>
        }
      <br></br>
        {<Box fontWeight={700}>{`Link Replace Destination:`}</Box>}
        {`Enter the new absolute link that you want to replace on the original link (a[href])`}
        {`Example: `}
        {
          <Box
            display={"inline-block"}
            fontStyle={"italic"}
          >{`https://www.cyclingnews.com.master.van-qa.futureplc.engineering/author/james-moultrie-new/?cc=US`}</Box>
        }
      <br></br>
        {<Box fontWeight={700}>{`Link Replace Label:`}</Box>}
        {`Enter the new label of the link that you want to apply on the original label.`}
        {`Example: `}
        {
          <Box
            display={"inline-block"}
            fontStyle={"italic"}
          >{`Miss James Moultries`}</Box>
        }
        {`This last change can possibly create some CLS issues. Please be aware that some styling should be included in the critical css to prevent potential CLS problem`}
      <br></br>
        {`When replacing links, Kiosq is setting attributes `}
        <Box
          display={"inline-block"}
          fontWeight={700}
        >{`link-before-rewritten-by-kiosq`}</Box>
        {` with the former link and another attribute `}
        <Box
          display={"inline-block"}
          fontWeight={700}
        >{`link-rewritten-by-kiosq-with`}</Box>
        {` is get the source of the rewrite (Kiosq link)`}
      <br></br>
        {`Example: `}
        <br></br>
        {`<a 
        href="https://www.cyclingnews.com.master.van-qa.futureplc.engineering/author/james-moultrie-new/?cc=US" 
        class="author__name_link" 
        rel="author" 
        data-before-rewrite-localise="https://www.cyclingnews.com.master.van-qa.futureplc.engineering/author/james-moultrie/?cc=US" `}
        {
          <Box
            display="inline"
            fontWeight={700}
          >{`link-before-rewritten-by-kiosq`}</Box>
        }
        {`="https://www.cyclingnews.com.master.van-qa.futureplc.engineering/author/james-moultrie/?cc=US" `}
        {
          <Box
            display="inline"
            fontWeight={700}
          >{`link-rewritten-by-kiosq-with`}</Box>
        }
        {`="https://kiosq.master.van-qa.futureplc.engineering/#/campaigns/kiosq-campaign_f5afd90d">Miss James Moultries</a>`}
      </Typography>
    </>
  );
  return {
    InjectRewritersLinksInstructionsComponent,
    InjectCustomTemplatesInstructionsComponent,
    InjectCustomJSCSSInstructionsComponent,
    QueryBuilderInstructionsComponent,
  };
};

export default useCampaignsInstructions;
