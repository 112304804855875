import React, { useState } from "react";
import {
     Show,
     SimpleShowLayout,
     TextField,
     DateField,
     RichTextField,
     ImageField
} from 'react-admin';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Card from '@material-ui/core/Card';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import CancelIcon from '@material-ui/icons/Cancel';
import { green, red, blue } from '@material-ui/core/colors';
import CardContent from '@material-ui/core/CardContent';
import CdsEntitlements from '../../components/CdsEntitlements';
import Collapse from '@material-ui/core/Collapse';
import TableCell from "@material-ui/core/TableCell";
import TableRow from "@material-ui/core/TableRow";
import {Table, TableBody, TableContainer, TableHead} from "@mui/material";
import Paper from "@material-ui/core/Paper";

const useStyles = makeStyles({
    user_linked_container: {
        fontSize: '18px',
        marginLeft: '60px'
    },
    link_button: {
        marginLeft: '10px',
        backgroundColor: "#487ed2",
        width: '140px',
        height: '35px',
        borderRadius: '5px',
        color: "#fff",
        "&:hover": {
            backgroundColor: "#004794",
        },
    },
    unlink_button: {
        marginLeft: '10px',
        backgroundColor: "#d32f2f",
        width: '140px',
        height: '35px',
        borderRadius: '5px',
        color: "#fff",
        "&:hover": {
            backgroundColor: "#b71c1c",
        },
    },
    card_title: {
        fontSize: '20px',
        marginBottom: '0px'
    },
    card_title_underline: {
        width: '40px',
        height:'6px',
        backgroundColor: blue[400],
        marginBottom: '20px'

    },
    paywall_subcard_content: {
        display: 'flex',
    },
    paywall_value_content: {
        marginRight: '20px',
        textAlign: 'center',
        backgroundColor: '#F8F8F8',
        padding: '15px !important'
    },
    paywall_site_title: {
        fontSize: '15px',
        color: 'rgba(0, 0, 0, 0.54)',
        marginBottom: '0px',
    },
    bluebird_subcard_content: {
        display: 'flex'
    },
    bluebird_subcard: {
        backgroundColor: '#F8F8F8',
        padding:'16px',
        margin: '0px 10px 20px 10px'

    },

    data_title: {
        fontSize: '12px',
        color: 'rgba(0, 0, 0, 0.54)',
    },
    bluebird_data: {
        marginLeft: '15px',
        fontSize: '12px',
        color: 'black'
    },
    bluebird_granted_content: {
        display: 'flex',
        alignItems: 'center'
    },
    data_active: {
        marginLeft: '10px',
        color: green[500]
    },
    data_inactive: {
        marginLeft: '10px',
        color: red[300]
    },
    user_history: {
        border: '1px solid rgba(232,232,232)',
        padding: '30px',
        marginBottom: '30px'
    },
    user_history_details_title: {
        marginTop: '20px'
    },
    user_history_summary_elements: {
        display: 'flex',
        justifyContent: 'space-between'
    },
    user_history_summary_element_title: {
        color: 'rgba(0, 0, 0, 0.54)'
    },
    user_history_summary_element:{
        maxWidth: '500px',
    },
    user_history_expand:{
        marginRight: '0px',
        marginLeft: 'auto',
        border:'1px solid',
        borderColor: blue[400],
        backgroundColor: 'white',
        color: blue[400],
        display: 'block',
        cursor: 'pointer'
    },
    user_metadata_raw_title:{
        color: 'rgba(0, 0, 0, 0.54)',
        fontSize: '1rem',
        fontWeight: '400',
        lineHeight: '1',
        letterSpacing: '0.00938em'
    }
});

const PaywallGrantedShow = ({record})=>{
    const classes = useStyles();
    if (record.user_metadata !== undefined && record.user_metadata.paywall_granted !== undefined) {
        let sites = Object.keys(record.user_metadata.paywall_granted)
        return (
            <div >
                <div>
                    <p className={classes.data_title}>Paywall Granted</p>
                    <div className={classes.paywall_subcard_content}>
                        {sites.map((value, index) => {
                            return (
                                <Card key={index} className={classes.paywall_value_content}>
                                    <p className={classes.data_title}>
                                        Site:
                                        <span className={classes.bluebird_data}>{value}</span>
                                    </p>
                                    <p className={classes.data_title}>
                                        End date:
                                        <span className={classes.bluebird_data}>{record.user_metadata.paywall_granted[value]}</span>
                                    </p>
                                </Card>

                            )
                        })}
                    </div>

                </div>

            </div>

        );
    }else {
        return null;
    }
}
const ProductsGrantedShow = ({record})=>{
    const classes = useStyles();
    if (record.user_metadata !== undefined && record.user_metadata.products_granted !== undefined) {
        let products = Object.entries(record.user_metadata.products_granted)
        return (
            <div >
                <div>
                    <p className={classes.data_title}>Products Granted</p>
                    <div className={classes.paywall_subcard_content}>
                        {products.map((product, productIndex) => {
                            let offers = Object.entries(product[1])
                            return (
                                <Card key={productIndex} className={classes.paywall_value_content}>
                                    <p className={classes.data_title}>
                                        journalCode:
                                        <span className={classes.bluebird_data}>{product[0]}</span>
                                    </p>
                                    {offers.map((offer, offerIndex) => {
                                        return (
                                                <p key={offerIndex} className={classes.data_title}>
                                                    {offer[0]}:
                                                    <span className={classes.bluebird_data}>{record.user_metadata.products_granted[product[0]][offer[0]]}</span>
                                                </p>

                                        )
                                    })}
                                </Card>

                            )
                        })}
                    </div>

                </div>

            </div>

        );
    }else {
        return null;
    }
}
const BluebirdCards = (props) => {
    return (
        <div className={props.classes.bluebird_subcard_content}>
            {
                Object.keys(props.record.bluebird_entitlements).map((site, index)=> {
                    let paywallType = typeof props.record.bluebird_entitlements[site].datas === 'undefined' ? 'paywall-per-product' : 'paywall-per-site';
                    if (paywallType === 'paywall-per-product' ) {
                        return (

                                <BluebirdPerProductCard key={index} record={props.record} classes={props.classes}  site={site} />

                        )
                    }else {
                        return (
                                <BluebirdPerSiteCard key={index} record={props.record} classes={props.classes}  site={site} />

                        )
                    }


                })
            }
        </div>

    )

}

const BluebirdPerSiteCard = (props) => {

    if (!Array.isArray(props.record.bluebird_entitlements[props.site].datas)) {
        let endDate = new Date(props.record.bluebird_entitlements[props.site].datas.entitlements.Paywall.subscriptionEndDate)

        let endYear = endDate.getFullYear()
        let endMonth = endDate.getMonth() + 1
        if (Number(endMonth)< 10) {
            endMonth = '0' + endMonth;
        }
        let endDay = endDate.getDate()
        if (Number(endDay)< 10) {
            endDay = '0' + endDay;
        }
        let stringEndDate = endYear + '-' + endMonth + '-' + endDay

        let startDate = new Date(props.record.bluebird_entitlements[props.site].datas.entitlements.Paywall.subscriptionStartDate)
        let startYear = startDate.getFullYear()
        let startMonth = startDate.getMonth()
        if (Number(startMonth)< 10) {
            startMonth = '0'+ startMonth;
        }
        let startDay = startDate.getDate()
        if (Number(startDay)< 10) {
            startDay = '0' + startDay;
        }
        let stringStartDate = startYear + '-' + startMonth + '-' + startDay
        return (
            <Card className={props.classes.bluebird_subcard}>
                <p className={props.classes.data_title} >
                    Site:
                    <span className={props.classes.bluebird_data}>{props.site}</span>
                </p>
                <p className={props.classes.data_title}>
                Status:
                    <span className={props.record.bluebird_entitlements[props.site].datas.status === 'Active' ? props.classes.data_active : props.classes.data_inactive} >
                        {props.record.bluebird_entitlements[props.site].datas.status}
                    </span>
                </p>

                {props.record.bluebird_entitlements[props.site].datas.status === 'Active' &&
                <div>

                    <div className={props.classes.bluebird_granted_content}>
                        <p className={props.classes.data_title}>Validity:</p>
                        <p>
                            {props.record.bluebird_entitlements[props.site].granted === true ?
                             <CheckCircleIcon style={{color: green[500], marginLeft: '15px'}}/> :
                             props.record.bluebird_entitlements[props.site].granted === "no-subscription-datas" ?
                             <span className={props.classes.bluebird_data}>---</span> :
                             <CancelIcon style={{color: red[300], marginLeft: '15px'}}/>
                            }
                        </p>
                    </div>
                    <div>
                        <p className={props.classes.data_title}>
                            Active:
                            <span className={props.classes.bluebird_data}>
                                {props.record.bluebird_entitlements[props.site].datas.entitlements.Paywall.active ?
                                "True" :
                                "False"
                                }
                            </span>

                        </p>
                        <p className={props.classes.data_title}>
                            Currency code:
                            <span className={props.classes.bluebird_data}>{props.record.bluebird_entitlements[props.site].datas.entitlements.Paywall.currencyCode}</span>

                        </p>
                        <p className={props.classes.data_title}>
                            Edition:
                            <span className={props.classes.bluebird_data}>{props.record.bluebird_entitlements[props.site].datas.entitlements.Paywall.edition}</span>

                        </p>
                        <p className={props.classes.data_title}>
                            Journal code:
                            <span className={props.classes.bluebird_data}>{props.record.bluebird_entitlements[props.site].datas.entitlements.Paywall.journalCode}</span>

                        </p>
                        <p className={props.classes.data_title}>
                            Journal name:
                            <span className={props.classes.bluebird_data}>{props.record.bluebird_entitlements[props.site].datas.entitlements.Paywall.journalName}</span>

                        </p>
                        <p className={props.classes.data_title}>
                            Label:
                            <span className={props.classes.bluebird_data}>{props.record.bluebird_entitlements[props.site].datas.entitlements.Paywall.label}</span>

                        </p>
                        <p className={props.classes.data_title}>
                            Offer name:
                            <span className={props.classes.bluebird_data}>{props.record.bluebird_entitlements[props.site].datas.entitlements.Paywall.offerName}</span>

                        </p>
                        <p className={props.classes.data_title}>
                            Price:
                            <span className={props.classes.bluebird_data}>{props.record.bluebird_entitlements[props.site].datas.entitlements.Paywall.price}</span>

                        </p>
                        <p className={props.classes.data_title}>
                            Promotion code:
                            <span className={props.classes.bluebird_data}>{props.record.bluebird_entitlements[props.site].datas.entitlements.Paywall.promotionCode}</span>

                        </p>
                        <p className={props.classes.data_title}>

                            Subscription end date:
                            <span className={props.classes.bluebird_data}>{stringEndDate}</span>

                        </p>
                        <p className={props.classes.data_title}>

                            Subscription start date:
                            <span className={props.classes.bluebird_data}>{stringStartDate}</span>

                        </p>
                    </div>
                 </div>
                }
            </Card>
          )
    }else {
        return null;
    }
}
const BluebirdPerProductCard = (props) => {
    return (
        Object.keys(props.record.bluebird_entitlements[props.site]).map((product, index)=> {
            if (!Array.isArray(props.record.bluebird_entitlements[props.site][product].datas)) {
                let endDate = new Date(props.record.bluebird_entitlements[props.site][product].datas.entitlements.Elearning.subscriptionEndDate)

                let endYear = endDate.getFullYear()
                let endMonth = endDate.getMonth() + 1
                if (Number(endMonth)< 10) {
                    endMonth = '0' + endMonth;
                }
                let endDay = endDate.getDate()
                if (Number(endDay)< 10) {
                    endDay = '0' + endDay;
                }
                let stringEndDate = endYear + '-' + endMonth + '-' + endDay

                let startDate = new Date(props.record.bluebird_entitlements[props.site][product].datas.entitlements.Elearning.subscriptionStartDate)
                let startYear = startDate.getFullYear()
                let startMonth = startDate.getMonth()
                if (Number(startMonth)< 10) {
                    startMonth = '0'+ startMonth;
                }
                let startDay = startDate.getDate()
                if (Number(startDay)< 10) {
                    startDay = '0' + startDay;
                }
                let stringStartDate = startYear + '-' + startMonth + '-' + startDay
                return (
                    <Card key={index} className={props.classes.bluebird_subcard}>
                        <p className={props.classes.data_title} >
                            Journal:
                            <span className={props.classes.bluebird_data}>{props.site}</span>
                        </p>
                        <p className={props.classes.data_title} >
                            ProductID:
                            <span className={props.classes.bluebird_data}>{product}</span>
                        </p>
                        <p className={props.classes.data_title}>
                        Status:
                            <span className={props.record.bluebird_entitlements[props.site][product].datas.status === 'Active' ? props.classes.data_active : props.classes.data_inactive} >
                                {props.record.bluebird_entitlements[props.site][product].datas.status}
                            </span>
                        </p>

                        {props.record.bluebird_entitlements[props.site][product].datas.status === 'Active' &&
                        <div>

                            <div className={props.classes.bluebird_granted_content}>
                                <p className={props.classes.data_title}>Validity:</p>
                                <p>
                                    {props.record.bluebird_entitlements[props.site][product].granted === true ?
                                     <CheckCircleIcon style={{color: green[500], marginLeft: '15px'}}/> :
                                     props.record.bluebird_entitlements[props.site][product].granted === "no-subscription-datas" ?
                                     <span className={props.classes.bluebird_data}>---</span> :
                                     <CancelIcon style={{color: red[300], marginLeft: '15px'}}/>
                                    }
                                </p>
                            </div>
                            <div>
                                <p className={props.classes.data_title}>
                                    Active:
                                    <span className={props.classes.bluebird_data}>
                                        {props.record.bluebird_entitlements[props.site][product].datas.entitlements.Elearning.active ?
                                        "True" :
                                        "False"
                                        }
                                    </span>

                                </p>
                                <p className={props.classes.data_title}>
                                    Currency code:
                                    <span className={props.classes.bluebird_data}>{props.record.bluebird_entitlements[props.site][product].datas.entitlements.Elearning.currencyCode}</span>

                                </p>
                                <p className={props.classes.data_title}>
                                    Edition:
                                    <span className={props.classes.bluebird_data}>{props.record.bluebird_entitlements[props.site][product].datas.entitlements.Elearning.edition}</span>

                                </p>
                                <p className={props.classes.data_title}>
                                    Journal code:
                                    <span className={props.classes.bluebird_data}>{props.record.bluebird_entitlements[props.site][product].datas.entitlements.Elearning.journalCode}</span>

                                </p>
                                <p className={props.classes.data_title}>
                                    Journal name:
                                    <span className={props.classes.bluebird_data}>{props.record.bluebird_entitlements[props.site][product].datas.entitlements.Elearning.journalName}</span>

                                </p>
                                <p className={props.classes.data_title}>
                                    Label:
                                    <span className={props.classes.bluebird_data}>{props.record.bluebird_entitlements[props.site][product].datas.entitlements.Elearning.label}</span>

                                </p>
                                <p className={props.classes.data_title}>
                                    Offer name:
                                    <span className={props.classes.bluebird_data}>{props.record.bluebird_entitlements[props.site][product].datas.entitlements.Elearning.offerName}</span>

                                </p>
                                <p className={props.classes.data_title}>
                                    Price:
                                    <span className={props.classes.bluebird_data}>{props.record.bluebird_entitlements[props.site][product].datas.entitlements.Elearning.price}</span>

                                </p>
                                <p className={props.classes.data_title}>
                                    Promotion code:
                                    <span className={props.classes.bluebird_data}>{props.record.bluebird_entitlements[props.site][product].datas.entitlements.Elearning.promotionCode}</span>

                                </p>
                                <p className={props.classes.data_title}>

                                    Subscription end date:
                                    <span className={props.classes.bluebird_data}>{stringEndDate}</span>

                                </p>
                                <p className={props.classes.data_title}>

                                    Subscription start date:
                                    <span className={props.classes.bluebird_data}>{stringStartDate}</span>

                                </p>
                            </div>
                         </div>
                        }
                    </Card>
                  )
            }else {
                return null;
            }
        })
    );

}

const Aside = ({ record }) => {
    const classes = useStyles();
    if (record && record.bluebird_entitlements !== undefined) {
        return (
            <div>
                <p className={classes.card_title}>Bluebird details</p>
                <div className={classes.card_title_underline}></div>
                <BluebirdCards record={record} classes={classes} />
            </div>
        )
    }else {
        return null;
    }
};
const UserHistory = ({ record }) => {
    const classes = useStyles();
    const [historyExpanded, setHistoryExpanded] = useState(false);
    const handleExpandClick = () => {
    setHistoryExpanded(!historyExpanded);
    };

    if (record && record.userHistory !== undefined) {
        return (
            <div>
                <p className={classes.card_title}>User History</p>
                <div className={classes.card_title_underline}></div>
                {record.userHistory.length > 0 ?
                <div>
                   <div>
                        <div className={classes.user_history}>
                            <h3 className={classes.user_history_summary_title}>Summary</h3>
                            <div className={classes.user_history_summary_elements}>
                                <div className={classes.user_history_summary_element}>
                                    <div className={classes.user_history_summary_element_title}>Date</div>
                                    <div className={classes.user_history_summary_element_value}>{record.userHistory[0].date}</div>
                                </div>
                                <div className={classes.user_history_summary_element}>
                                    <div className={classes.user_history_summary_element_title}>type</div>
                                    <div className={classes.user_history_summary_element_value}>{record.userHistory[0].type_value}</div>
                                </div>
                                <div className={classes.user_history_summary_element}>
                                    <div className={classes.user_history_summary_element_title}>Description</div>
                                    <div className={classes.user_history_summary_element_value}>{record.userHistory[0].description_value}</div>
                                </div>
                                
                            </div>
                            <h3 className={classes.user_history_details_title}>Details</h3>
                            <pre>
                                <code>
                                    {JSON.stringify(record.userHistory[0],null,4)}
                                </code>
                            </pre>
                            <button className={classes.user_history_expand} onClick={handleExpandClick}>Click to {!historyExpanded ?'expand' : 'contract'}</button>
                        </div>
                   
                    </div>
                    <Card>
                        <Collapse in={historyExpanded} timeout="1000" unmountOnExit>
                            <CardContent>
                            {
                                record.userHistory.map((historyEvent, index) => {
                                    if (index > 0) {
                                        return (
                                            <div key={index} className={classes.user_history_card}>
                                                <div className={classes.user_history}>
                                                    <h3 className={classes.user_history_summary_title}>Summary</h3>
                                                    <div className={classes.user_history_summary_elements}>
                                                        <div className={classes.user_history_summary_element}>
                                                            <div className={classes.user_history_summary_element_title}>Date</div>
                                                            <div className={classes.user_history_summary_element_value}>{record.userHistory[index].date}</div>
                                                        </div>
                                                        <div className={classes.user_history_summary_element}>
                                                            <div className={classes.user_history_summary_element_title}>type</div>
                                                            <div className={classes.user_history_summary_element_value}>{record.userHistory[index].type_value}</div>
                                                        </div>
                                                        <div className={classes.user_history_summary_element}>
                                                            <div className={classes.user_history_summary_element_title}>Description</div>
                                                            <div className={classes.user_history_summary_element_value}>{record.userHistory[index].description_value}</div>
                                                        </div>
                                                        
                                                    </div>
                                                    <h3 className={classes.user_history_details_title}>Details</h3>
                                                    <pre>
                                                        <code>
                                                            {JSON.stringify(record.userHistory[index],null,4)}
                                                        </code>
                                                    </pre>
                                                </div>
                                            </div>
                                        )
                                    }else{
                                        return null
                                    }
                                    
                                })
                            }
                            </CardContent>
                        </Collapse>
                    </Card>      
                </div> :
                <p>There are no logs yet.</p>
                }          
            </div>
        )
    }else {
        return null;
    }
};
const UserMetadaRaw = ({ record }) => {
    const classes = useStyles();
    if (record && record.user_metadata !== undefined) {
        return (
            <div>
                <p className={classes.user_metadata_raw_title}>User Metadata raw</p>
                <pre>
                    <code>
                        {JSON.stringify(record.user_metadata,null,4)}
                    </code>
                </pre>
                
            </div>
        )
    }else {
        return null;
    }
};

const UserLinked = ({ record }) => {
    const classes = useStyles();

    const [loading, setLoading] = useState(false);

    const handleUnlink = async (provider, userId) => {
        const confirmed = window.confirm("Are you sure you want to unlink this account?");
        if (!confirmed) return;

        setLoading(true);

        try {
            const response = await fetch(`/api/admin/user-unlink/${record.user_id}/${provider}/${userId}`, {
                method: "GET",
            });

            if (!response.ok) {
                throw new Error("Failed to unlink account.");
            }

            window.alert("The account have been unlinked, the page will be reloaded");
            window.location.reload();
        } catch (error) {
            console.error("Error unlinking account:", error);
            alert("Failed to unlink account.");
        } finally {
            setLoading(false);
        }
    };

    if (record && record.identities !== undefined && record.identities.length >= 2) {
        return (
            <div>
                <p className={classes.card_title}>User Linked</p>
                <div className={classes.card_title_underline}></div>
                <div className={classes.user_linked_container}>
                    <TableContainer component={Paper}>
                        <Table className={classes.table}>
                            <TableHead>
                                <TableRow>
                                    <TableCell className={classes.header}>Email</TableCell>
                                    <TableCell className={classes.header}>User ID</TableCell>
                                    <TableCell className={classes.header}>Connection</TableCell>
                                    <TableCell className={classes.header}>Action</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {
                                    record.identities.map((userLinked) => (
                                        record.user_id !== userLinked.provider + '|' +userLinked.user_id && (
                                            <TableRow key={userLinked.user_id}>
                                                <TableCell>{record.email}</TableCell>
                                                <TableCell>{userLinked.provider}|{userLinked.user_id}</TableCell>
                                                <TableCell>{userLinked.connection}</TableCell>
                                                <TableCell>
                                                    <button
                                                        key={userLinked.user_id}
                                                        className={classes.unlink_button}
                                                        onClick={() => handleUnlink(userLinked.provider, userLinked.user_id)}
                                                        disabled={loading}
                                                    >
                                                        {loading ? "Unlinking..." : "Unlink"}
                                                    </button>
                                                </TableCell>
                                            </TableRow>
                                        )
                                ))}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </div>
            </div>
        )
    }

    return null;
};

const UserUnlinked = ({ record }) => {
    const classes = useStyles();
    const [loading, setLoading] = useState(false);

    const handleLink = async (provider, userId) => {
        const confirmed = window.confirm("Are you sure you want to link this account?");
        if (!confirmed) return;

        setLoading(true);

        try {
            const response = await fetch(`/api/admin/user-link/${record.user_id}/${provider}/${userId}`, {
                method: "GET",
            });

            if (!response.ok) {
                throw new Error("Failed to link account.");
            }

            window.alert("The account have been linked, the page will be reloaded");
            window.location.reload();
        } catch (error) {
            console.error("Error linking account:", error);
            alert("Failed to link account.");
        } finally {
            setLoading(false);
        }
    };

    if (record && record.accountUnlinked !== undefined && record.accountUnlinked.length >= 1  ) {
        return (
            <div>
                <p className={classes.card_title}>User with the same email</p>
                <div className={classes.card_title_underline}></div>
                <div className={classes.user_linked_container}>
                    <TableContainer component={Paper}>
                        <Table className={classes.table}>
                            <TableHead>
                                <TableRow>
                                    <TableCell className={classes.header}>Email</TableCell>
                                    <TableCell className={classes.header}>User ID</TableCell>
                                    <TableCell className={classes.header}>Created at</TableCell>
                                    <TableCell className={classes.header}>provider</TableCell>
                                    <TableCell className={classes.header}>Connection</TableCell>
                                    <TableCell className={classes.header}>Action</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {
                                    record.accountUnlinked.map((user) => (
                                        record.user_id !== user.provider + '|' +user.user_id && (
                                            <TableRow key={user.user_id}>
                                                <TableCell>{user.email}</TableCell>
                                                <TableCell>{user.user_id}</TableCell>
                                                <TableCell>{user.created_at}</TableCell>
                                                <TableCell>{user.provider}</TableCell>
                                                <TableCell>{user.connection}</TableCell>
                                                <TableCell>
                                                    <button
                                                        key={user.user_id}
                                                        className={classes.link_button}
                                                        onClick={() => handleLink(user.provider, user.user_id)}
                                                        disabled={loading}
                                                    >
                                                        {loading ? "linking..." : "link"}
                                                    </button>
                                                </TableCell>
                                            </TableRow>
                                        )
                                    ))}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </div>
            </div>
        )
    }else {
        return null;
    }
};

const UserShow = (props) => {
    const classes = useStyles();
    return (
        <Show title= "User details"  {...props}>
            <SimpleShowLayout>
                <p className={classes.card_title}>Auth0 details</p>
                <div className={classes.card_title_underline}></div>
                <ImageField source="picture" />
                <PaywallGrantedShow  source="user_metadata.paywall_granted" />
                <ProductsGrantedShow  source="user_metadata.products_granted" />
                <TextField source="id" />
                <TextField source="name" />
                <TextField source="email" />
                <UserMetadaRaw />
                <UserLinked />
                <UserUnlinked />
                <UserHistory />
                <Aside />
                <CdsEntitlements />
            </SimpleShowLayout>
        </Show>
    )

};

export default UserShow;
