import React, {Component, useState} from 'react';
import { connect } from 'react-redux';
import {
    ImageInput, ImageField, showNotification as showNotificationAction,
} from 'react-admin';
import CircularProgress from '@material-ui/core/CircularProgress';
import { fetch } from '../restClient';

const InputFile = (props) => {

    const [isLoading, setisLoading] = useState(false);
    const [disabled, setdisabled] = useState(false);

    const handleChange = (file) => {
        if (!file) {
            return;
        }

        setisLoading(true)
        const { showNotification, input } = props;
        const fd = new FormData();
        const fr = new FileReader();

        fr.readAsArrayBuffer(file)
        fr.onload = function() {
            const blob = new Blob([fr.result], {type: file.type})

            fd.append('file', blob);
            fetch('/api/uploads', {
                method: 'post',
                body: fd,
            })
            .then((response) => {
                setisLoading(false)
                showNotification(props.label+' added');
                input.onChange(
                    {
                        src: response.json.url,
                        preview: response.json.url,
                        mimeType: response.json.mimeType,
                        fileContent: response.json.fileContent,
                    }
                );
            })
            .catch((error) => {
                setisLoading(false)
                showNotification(error.message)
            });;
        }
    };

    return (
        <div>
            {!!disabled ? (
                <ImageField source="src" title="title"/>
            ) : (
                <ImageInput source={props.source} onChange={handleChange} label={props.label} >
                    <ImageField source="src" title="title"/>
                </ImageInput>
            )}
            {isLoading && <CircularProgress size={20} thickness={2} />}
        </div>
    )

}

InputFile.defaultProps = { disabled: false };

export default connect(
    null,
    {
        showNotification: showNotificationAction,
    },
)(InputFile);
