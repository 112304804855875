import List from './List';
import Edit from './Edit';
import Show from './Show';
import Create from './Create';

const name = 'campaigns';
const label = 'Campaigns';

export {
    List,
    Edit,
    Show,
    Create,
    name,
    label
};
