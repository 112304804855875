import React from 'react';
import CircularProgress from '@material-ui/core/CircularProgress';

const LoadingPage = () => {

    const styles = {
        loadingContent: {
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            width: '100vw',
            height: '100vh'
        },
        loadingText: {
            fontSize: '20px'
        },
        progress: {
            margin: '50px',
            color: 'red'
        }
    }
    return (
        <div style={styles.loadingContent}>
            <p style={styles.loadingText}>Login, please wait...</p>

            <div>
                <CircularProgress style={styles.progress} />
            </div>
        </div>
    );
};

export default LoadingPage
