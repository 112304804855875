import Icon from '@material-ui/icons/SettingsApplications';
import List from './List';
//import Show from './Show';
//import Edit from './Edit';
import Create from './Create';

const name = 'settings';
const label = 'Settings';

export {
    //Show,
    Create,
    Icon, List, label, name
};

