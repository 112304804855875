import React from 'react';
import {
  Edit,
  TabbedForm,
  FormTab,
  TextInput,
  SaveButton,
  Toolbar,
  useNotify,
  useRefresh,
  useRedirect,
  ListButton,
  TopToolbar,
  ShowButton,
} from 'react-admin';
import { makeStyles } from '@material-ui/core/styles';

import PaywallEdit from '../../components/PaywallEdit'
import ProductsGrantedEdit from '../../components/ProductsGrantedEdit'
import GrantedInputsContent from '../../components/GrantedInputsContent'
import PasswordsInputs from '../../components/PasswordsInputs'
import AccountMapping from '../../components/AccountMapping'

const useStyles = makeStyles({
    edit_zone: {
        width: '50%',
    },
    edit_zone_content: {
        display: 'flex',
    }
});

const PaywallGrantedEditContent = (props) =>{
    const classes = useStyles();
    return (
        <div className={classes.edit_zone_content}>
                <div className={classes.edit_zone}  >
                    <PaywallEdit userMetadataTarget="paywall_granted" {... props} />
                    <GrantedInputsContent paywallType="classic" userMetadataTarget="paywall_granted" />
                </div>
                <div className={classes.edit_zone}>
                    <ProductsGrantedEdit {...props} />
                    <GrantedInputsContent paywallType="products" />
                </div>
        </div>
    )
}
const PaywallTrialEditContent = (props) =>{
    const classes = useStyles();
    return (
        <div className={classes.edit_zone_content}>
                <div className={classes.edit_zone}  >
                    <PaywallEdit userMetadataTarget="paywall_trial" {... props} />
                    <GrantedInputsContent paywallType="classic" userMetadataTarget="paywall_trial" />
                </div>
        </div>
    )
}

const UserEdit = (props) => {
    const notify = useNotify();
    const refresh = useRefresh();
    const redirect = useRedirect();
    const classes = useStyles();

    const onSuccess = ({ data }) => {
        if (typeof data.mapping_response !== 'undefined' && typeof data.mapping_response.message !== 'undefined') {
            notify(data.mapping_response.message);
        } else {
            notify(`Changes to user "${data.name}" saved`);
        }
        refresh();
    };
    const onFailure = (error) => {
        notify(`Could not edit user: ${error.message}`)
        refresh();
    };

    const toolbarStyles = makeStyles({
        toolbar: {
            display: 'flex',
            justifyContent: 'space-between',
        },
    });
    const PostEditActions = (props) => {
        return (
            <TopToolbar>
                <ListButton />
                <ShowButton to={`${props.basePath}/${props.id}/show`}/>
            </TopToolbar>
        )
    };

    const CustomToolbar = (props) => (
    <Toolbar {...props} classes={toolbarStyles()}>
        <SaveButton />
    </Toolbar>
);
    return (
        <Edit onSuccess={onSuccess} onFailure={onFailure} mutationMode="pessimistic" {...props} actions={<PostEditActions {...props} />} >
            <TabbedForm
                submitOnEnter={false}
                redirect="edit"
                toolbar={<CustomToolbar />}
            >
                <FormTab label="Paywall grant">
                    <TextInput disabled source="email" />
                    <PaywallGrantedEditContent />
                </FormTab>
                <FormTab label="Paywall trial">
                    <TextInput disabled source="email" />
                    <PaywallTrialEditContent />
                </FormTab>
                <FormTab label="Change password">
                    <TextInput disabled source="email" />
                    <PasswordsInputs />
                </FormTab>
                <FormTab label="Account linking">
                    <TextInput disabled source="email" />
                    <AccountMapping TabbedForm />
                </FormTab>
            </TabbedForm>
        </Edit>
    );
};

export default UserEdit;
