import React from 'react';
import Button from "@material-ui/core/Button";
import {useInput} from "react-admin";
import {makeStyles} from "@material-ui/core/styles";

const sampleFormContent = (type) => {
    if (typeof type === 'undefined') {
        return ;
    }

    if (type.includes('layer')) {
        return ({
            "logo": "https://vanilla.futurecdn.net/guitarworld/media/img/GW_logo.svg",
            "textBeforeButton": "Thank you for reading 5 articles this month* Join now for unlimited access",
            "buttonText": "Join now",
            "textAfterButton": "*Read 5 free articles per month without a subscription"
        });
    }

  return (
      {
          offers_name: "Subscribe now",
          offers_items: [
              {
                  "icon": {
                      "src": "https://cdn.mos.cms.futurecdn.net/flexiimages/hcfe2ir4mp1687955342.svgz",
                      "preview": "https://cdn.mos.cms.futurecdn.net/flexiimages/hcfe2ir4mp1687955342.svgz",
                      "mimeType": "image/svgz",
                      "fileContent": ""
                  },
                  "highlight_label": "-25%",
                  "title": "Premium",
                  "description": "<ul><li>unlimited duration<</li><li>mobile access</li><li>App premium</li></ul>",
                  "price": 10,
                  "currency": "e",
                  "recurrence": "monthly",
                  "url": "url.com",
                  "cta_label": "Subscribe"
              },
              {
                  "highlight_label": "-50%",
                  "title": "Premium ++",
                  "icon": {
                      "src": "https://cdn.mos.cms.futurecdn.net/flexiimages/hcfe2ir4mp1687955342.svgz",
                      "preview": "https://cdn.mos.cms.futurecdn.net/flexiimages/hcfe2ir4mp1687955342.svgz",
                      "mimeType": "image/svgz",
                      "fileContent": ""
                  },
                  "description": "<ul><li>unlimited duration<</li><li>mobile access</li><li>App premium</li></ul>",
                  "price": 100,
                  "currency": "E",
                  "recurrence": "yearly",
                  "url": "url2.com",
                  "cta_label": "Subscribe"
              }
          ]
      });
};

const  SampleButton = ({ formData, dispatch, ...props }) => {
    const useStyles = makeStyles({
        sampleButton: {
            marginTop: "15px"
        }
    });
    const classes = useStyles();

    const {input} = useInput({source: props.source});
    const sampleOffer = () => {
        input.onChange(JSON.stringify(sampleFormContent(props.type), null, 2));
    };

    return (
        <Button  className={classes.sampleButton}  variant="outlined" onClick={sampleOffer}>Sample</Button>
    );
};

export default SampleButton;