import React from "react";
import {
  ArrayInput,
  SimpleFormIterator,
} from "react-admin";
import { ConfigForm } from "../entities/SiteConfig/CampaignConfigs";

const ListAceInput = () => {

  return (
    <ArrayInput
      source={`js_variables_global`}
      label={`JS variables global`}
    >
      <SimpleFormIterator>
        <ConfigForm />
      </SimpleFormIterator>
    </ArrayInput>
  );
};
export default ListAceInput;
