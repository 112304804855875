import React from "react";
import {
  Edit,
  useNotify,
  useRefresh,
  useRedirect,
  FormTab,
  useRecordContext,
  useResourceContext,
} from "react-admin";
import ChangelogFields from "../../components/ChangeLogFields";
import EditForm from "./EditForm";

const CampaignsTitle = ({ record }) => {
  return <span>Edit {record ? `"${record.name}"` : ""}</span>;
};
const ChangeLogTabContent = (props) => {
  const record = useRecordContext(props);
  const resource = useResourceContext(props);
  if (record) {
    return (
      <ChangelogFields guid={record.guid} record={record} resource={resource} />
    );
  } else {
    return null;
  }
};

const CampaignsEdit = (props) => {
  const notify = useNotify();
  const refresh = useRefresh();
  const redirect = useRedirect();

  const onSuccess = ({ data }) => {
    notify(`Changes to Campaigns "${data.name}" saved`);
    redirect("/campaigns");
    refresh();
  };
  const onFailure = (error) => {
    notify(`Could not edit Campaigns: ${error.message}`);
    redirect("/campaigns");
    refresh();
  };

  return (
    <Edit
      title={<CampaignsTitle />}
      onSuccess={onSuccess}
      onFailure={onFailure}
      mutationMode="pessimistic"
      {...props}
    >
      <EditForm redirect="edit" origin={"edit"}>
        <FormTab label="Changelogs">
          <ChangeLogTabContent />
        </FormTab>
      </EditForm>
    </Edit>
  );
};

export default CampaignsEdit;
