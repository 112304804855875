import { makeStyles } from "@material-ui/core/styles";
import React, { useEffect, useState } from "react";
import {
  DeleteButton,
  FormTab,
  SaveButton,
  TabbedForm,
  Toolbar,
  useAuthProvider,
} from "react-admin";
import { Field } from "react-final-form";
import FormDataContent from "../../components/FormDataContent";
import LayersManager from "../../components/LayersManager";
import OffersForm from "../../components/OffersForm";
import QueryBuilderComponent from "../../components/QueryBuilder";
import ScrollableTabsButtonAuto from "../../components/ScrollableTabsButtonAuto";
import GeneralInfosForm from "../SiteConfig/GeneralInfosForm";
import ProvidersLinks from "../SiteConfig/ProvidersLinks";
import Tracking from "../SiteConfig/Tracking";
import GeneralInfosCampaign from "./GeneralInfosCampaign";
import useCustomQueryOptions from "./useCustomQueryOptions";

const CustomToolbar = (props) => {
  const toolbarStyles = makeStyles({
    toolbar: {
      display: "flex",
      justifyContent: "space-between",
    },
  });

  return (
    <Toolbar {...props} classes={toolbarStyles()}>
      <SaveButton />
      <DeleteButton />
    </Toolbar>
  );
};

export const useStyles = makeStyles({
  long_input: {
    width: "65%",
    marginRight: "10%",
  },
  group_column: {
    display: "flex",
    justifyContent: "flex-start",
    flexDirection: "column",
  },
  group_row: {
    display: "flex",
    justifyContent: "flex-start",
    flexDirection: "row",
  },
  text_editor_content: {
    width: "65%",
  },
  input_marge: {
    marginRight: "2%",
    maxWidth: "50%",
  },
  regwall_input: {
    marginLeft: "5%",
  },
  button: {
    height: "40px",
    marginTop: "10px",
    maxWidth: "200px",
  },
});

const validateCampaigns = (values) => {
  const errors = {};
  /* if (values.landing_products) {
      try {
        const json = JSON.parse(values.landing_products);
      } catch (e) {
        errors.landing_products =
          "The format is not valid, please check the example provided";
      }
    } */
  return errors;
};

const EditForm = ({
  validateSiteConfig,
  redirect = "edit",
  children,
  source,
  origin = "create",
  record,
  ...rest
}) => {
  const classes = useStyles();
  const [campaignsQueryOptions, setCampaignsQueryOptions] = useState([]);
  const [siteConfigOverridedID, setSiteConfigOverridedID] = useState(
    record.site_config_overrided || ""
  );

  const authProvider = useAuthProvider();
  const [permission, setPermissions] = useState();

  const campaignsQueryOptionsFetched = useCustomQueryOptions(
    siteConfigOverridedID
  );

  useEffect(() => {
    authProvider
      .getPermissions()
      .then((permissions) => setPermissions(permissions));
  }, []);

  useEffect(() => {
    if (campaignsQueryOptionsFetched.length > 0) {
      setCampaignsQueryOptions(campaignsQueryOptionsFetched);
    }
  }, [campaignsQueryOptionsFetched.length]);

  return (
    <TabbedForm
      submitOnEnter={false}
      redirect={redirect}
      toolbar={<CustomToolbar />}
      validate={validateSiteConfig}
      record={record}
      {...rest}
    >
      <FormTab label="Campaigns configs">
        <FormDataContent dataContentTitle="General infos">
          <GeneralInfosCampaign
            origin={origin}
            setSiteConfigOverridedID={setSiteConfigOverridedID}
            permissions={permission}
          />
        </FormDataContent>
        <FormDataContent dataContentTitle="Query Builder">
          <div className={classes.group_column}>
            <QueryBuilderComponent
              name="campaign_query_builder"
              options={campaignsQueryOptions}
            />
          </div>
        </FormDataContent>
        <FormDataContent dataContentTitle="Override site config">
          <ScrollableTabsButtonAuto>
            <GeneralInfosForm
              labelTab="General Infos"
              field={"overrides"}
              permissions={permission}
            />
            <ProvidersLinks
              labelTab="Providers and links"
              field={"overrides"}
            />
            <LayersManager labelTab="Layers Manager" field={"overrides"} />
            <Tracking labelTab="Tracking" field={"overrides"} />
            <Field
              labelTab="Offers"
              name={"offers"}
              field={"overrides"}
              component={OffersForm}
            />
          </ScrollableTabsButtonAuto>
        </FormDataContent>
      </FormTab>
      {children}
    </TabbedForm>
  );
};

EditForm.propTypes = {};

export default EditForm;
