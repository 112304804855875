import React, { useState, useEffect, useCallback } from "react";
import { useGetList } from "react-admin";
import PropTypes from "prop-types";

const useCampaignsOf = (siteGuid = "", campaignGuid = "") => {
  var [listCampaigns, setListCampaigns] = useState([]);
  const filterListWithoutCurrentCampaign = (currentCampaignGuid) => {
    //no guid on create
    if (!currentCampaignGuid) {
      return listCampaigns.map((campaign) => {
        campaign.priority;
      });
    }
    //guid on edit
    return listCampaigns
      .filter((campaign) => campaign.guid !== currentCampaignGuid)
      .map((campaign) => {
        campaign.priority;
      });
  };

  // const isNotUniquePriority = (value) =>  (filterListWithoutCurrentCampaign(campaignGuid).includes(value) ? "Priority must be unique" : undefined);
  const isNotUniquePriority = useCallback(
    (value) => {
      return filterListWithoutCurrentCampaign(campaignGuid).includes(value)
        ? "Priority must be unique"
        : undefined;
    },
    // [campaignGuid]
  );

  const { data, total } = useGetList(
    "campaigns",
    { page: 1, perPage: 1000 },
    { field: "last_modified", order: "DESC" },
    { site_config_overrided: siteGuid }
  );

  useEffect(() => {
    setListCampaigns(Object.values(data));
  }, [listCampaigns.length, data]);

  return { listCampaigns, isNotUniquePriority };
};

useCampaignsOf.propTypes = {
  key: PropTypes.string.isRequired,
};

export default useCampaignsOf;
