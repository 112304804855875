import React, { PureComponent } from 'react';
import CircularProgress from '@material-ui/core/CircularProgress';
import fetch from 'unfetch';
import ReactDiffViewer from 'react-diff-viewer-continued';
import Cookies from "js-cookie";

class Diff extends PureComponent {

    constructor(props) {
        super(props);
        this.state = {
            past: '',
            current: '',
            loadedOld: false,
            loadedNew: false
        };
    }

    componentDidMount() {
        let headers = new Headers({
            Authorization: localStorage.getItem('token'),
            Accept: 'application/json',
            'X-Role': localStorage.getItem('role'),
            'X-ExpiresIn': localStorage.getItem('expiresIn'),
            'X-User-Name': localStorage.getItem('user')
        });

        let fetchParams = { method: 'GET',
            headers: headers,
            mode: 'cors',
            cache: 'default' };

        fetch('/api/admin/' + this.props.resource + '_archived/' + this.props.item[3], fetchParams)
            .then(function(response){
                return response.json();
            })
            .then(content => JSON.stringify(content, null, 2))
            .then(past => this.setState({ past: past, loadedOld: true}));

        fetch('/api/admin/' + this.props.resource + '_archived/' + this.props.item[4], fetchParams)
            .then(function(response){
                return response.json();
            })
            .then(content => JSON.stringify(content, null, 2))
            .then(current => this.setState({ current: current, loadedNew: true }));
    }

    render() {
        if (this.state.loadedOld === false || this.state.loadedNew === false) {
            return <CircularProgress size={40} thickness={2} />;
        }

        return (
            <ReactDiffViewer oldValue={this.state.past} newValue={this.state.current} splitView={true} />

        );
    }
}

export default Diff;
