import React from 'react';
import Tooltip from '@material-ui/core/Tooltip';
import InfoIcon from '@material-ui/icons/Info';

const CustomDateField = ({ record = {}, source, resource, toolTipSource, toolTipCreateBy }) =>
    <span>{formatDate(record, source)}
        {source && source === 'last_modified' && resource === 'feeds' &&
            <Tooltip
                title={<span>
                    <p style={{margin: '0'}}>Created at: {formatDate(record, toolTipSource)}</p>
                    <p style={{margin: '0'}}>Created by: {(record[toolTipCreateBy] ? record[toolTipCreateBy] : 'unknow')}</p>
                </span> }
                arrow
            >
                <InfoIcon style={{color: 'grey', width:'15px', verticalAlign:'middle', margin:'0 3px'}}></InfoIcon>
            </Tooltip>
        }
    </span>;

CustomDateField.defaultProps = { label: 'Created at' };

function formatDate(record, source) {
    const timestamp = record[source] && record[source]['$date'] ? record[source]['$date']['$numberLong'] : null;
    if (!timestamp) {
        return '';
    }
    const date = new Date(parseInt(timestamp, 10));
    return date.toLocaleDateString('en-GB');
}

export default CustomDateField;
